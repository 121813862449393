import React, { Component, useState, useEffect , useCallback, useMemo} from "react";
import {Table, Alert, Modal, Button, Container, Row, Col, Form, Card} from 'react-bootstrap'
import { Link, useNavigate } from "react-router-dom";
import { divIcon, latLng } from 'leaflet';
import L from 'leaflet';
import { MapContainer, TileLayer,ImageOverlay, SVGOverlay, useMap, Marker, Popup, useMapEvents, LayersControl, LayerGroup, useMapEvent, Rectangle} from 'react-leaflet'
import { useEventHandlers } from '@react-leaflet/core'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Control from 'react-leaflet-custom-control'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import {MdApartment,MdOutlineMeetingRoom, MdWindow} from "react-icons/md";
import {TbBrandWindows} from "react-icons/tb";
import {RxDropdownMenu, RxDotsHorizontal, RxExit} from "react-icons/rx";
import {FaMapMarkerAlt, FaTicketAlt} from 'react-icons/fa'
import { RiNodeTree } from "react-icons/ri";
import { VscListTree } from "react-icons/vsc";
import { TbBorderCorners } from "react-icons/tb";
import { db, auth } from "./firebase";
import { collection, doc, setDoc, getDoc, getDocs, query, where, onSnapshot, serverTimestamp, addDoc, Timestamp } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead'; 

function MapModal({position, show, handleClose, users, floor, val}){
    
    const [validated, setValidated] = useState(val);
    const [name, setName] = useState('')
    const [note, setNote] = useState('')
    const [prio, setPrio] = useState('')
    const [userMain, setUserMain] = useState('')
    const [date, setDate] = useState('')

    const createTicket = (e) => {
        e.preventDefault()
        setValidated(true);
        console.log(position.lat)
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
        } else {
        console.log(auth.currentUser.uid)
        try {var newData = {}
            if(userMain){
                newData = {
                    name: name,
                    note: note,
                    floorId: floor.id,
                    buildingId: floor.buildingId,
                    status: 'created',
                    prio: prio,
                    position: {lat: position.lat, lng: position.lng},
                    date: date,
                    responsible: userMain[0].id,
                    createdUid: auth.currentUser.uid,
                    visible: [auth.currentUser.uid, userMain[0].id],
                    notifyMe: [auth.currentUser.uid, userMain[0].id],
                    rules: { [auth.currentUser.uid]: "owner" },
                    created: serverTimestamp()
                }
            }
            else {
                var newData = {
                    name: name,
                    note: note,
                    floorId: floor.id,
                    buildingId: floor.buildingId,
                    status: 'created',
                    prio: prio,
                    position: {lat: position.lat, lng: position.lng},
                    date: date,
                    responsible: auth.currentUser.uid,
                    createdUid: auth.currentUser.uid,
                    visible: [auth.currentUser.uid],
                    notifyMe: [auth.currentUser.uid],
                    rules: { [auth.currentUser.uid]: "owner" },
                    created: serverTimestamp()
                }
            }
            addDoc(collection(db, "ticket"), newData)
            // setManagerId(docRef.id);
            handleClose();
            setName('')
            
          

        setValidated(false);
        handleClose()
        setName('')
        setNote('')
        setPrio('')
        setUserMain('')
        setDate('')
            } catch (e) {
            console.error("Error adding document: ", e);
        }
        }
        
    }

    return(
        <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Ticket erstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
          {users.length>0?
            <Form validated={validated} noValidate onSubmit={createTicket}>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Betreff</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }} 
                />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPrio">
              <Form.Label>Priorität</Form.Label>
              <Form.Select aria-label="Default select example"
              required
              onChange={(e)=>setPrio(e.target.value)}
              title='Select Prio'
              >
                    <option></option>
                    <option value="1">Niedrig</option>
                    <option value="2">Mittel</option>
                    <option value="3">Hoch</option>
                    <option value="4">Kritisch</option>
                </Form.Select>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridNote">
              <Form.Label>Information</Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Enter details"
                rows={4}
                // accept="image/png,image/jpeg"
                value={note}
                onChange={(e) => {
                  setNote(e.target.value);
                }} 
                />
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridUser">
                <Form.Label>Durchzuführen von</Form.Label>
                <Typeahead
                    // inputProps={{ required: true }}
                    id='user'
                    labelKey="displayName"
                    // multiple
                    placeholder="Search user"
                    // defaultSelected={[]}
                    // opl
                    onChange={(selected) => {
                        setUserMain(selected)
                        // Handle selections...
                    }}
                    options={users}
                />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridDate">
              <Form.Label>Durchzuführen bis</Form.Label>
              <Form.Control
                required
                type="datetime-local"
                min={new Date().toISOString().slice(0, -8)}
                placeholder="Enter date"
                // accept="image/png,image/jpeg"
                onChange={(e) => {
                  setDate(e.target.value);
                  console.log(e.target.value)
                }} 
                />
            </Form.Group>
          </Row>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
        :
        <h4>Bitte fügen sie zunächst einen Kontakt zum verwaltetenden Manager an.</h4>
        }
            </Modal.Body>
        </Modal>
    )
}


export default function Map({users, floors, buildings, managers, tickets}){
    let { id, type, idType } = useParams();
    // console.log(tickets)
    let navigate = useNavigate();
    const [addMarkerItem, setAddMarkerItem] = useState(false)
    const [markersItem, setMarkersItem] = useState([])
    const [addMarkerTicket, setAddMarkerTicket] = useState(false)
    const [markersTicket, setMarkersTicket] = useState(false)
    const [showTicket, setShowTicket] = useState(false)
    const [show, setShow] = useState(false)
    const [ticketButton, setTicketButton] = useState('')
    const [itemButton, setItemButton] = useState('')
    const [position, setPosition] = useState(false)
    const [floor, setFloor] = useState(false)
    const [floorID, setFloorId] = useState(id)
    const [manager, setManager] = useState(false)
    const [iconTicket, setIconTicket] = useState(false)
    const [focusId, setFocusId] = useState(false)
    const [contacts, setContacts] = useState('')
    const [planUrl, setPlanUrl] = useState(false)

    const handleCloseTicket = () => {
        setShowTicket(false)
    }

    const getImage =  () => {
        const indexddb =  window.indexedDB.open("ImageData");
        indexddb.onsuccess = async function (e) {
            console.log("success...");
            // const db12= e.target.result;
            const request = await e.target.result.transaction('friends')
                   .objectStore('friends')
                   .getAll();

            request.onsuccess = function(){
                console.log(request.result)
                if(request.result.find(el => el.name == floor.planUrl)){
                    const blob = new Blob( [ request.result.find(el => el.name == floor.planUrl).array ], {type: 'image/svg+xml'} );
                    const url = URL.createObjectURL( blob );
                    setPlanUrl(url)
                    console.log(request.result.find(el => el.name == floor.planUrl))
                    console.log(url)
                }
                else {
                    console.log('planFehlt')
                    setPlanUrl(floor.planUrl)
                }
            }

        };
        
    }

    // console.log(id)
    useEffect(()=>{
        console.log(floorID)
        if(floors && !floor && floorID){
            getMapData()
            
        }
        if(!planUrl && floor){
            getImage()
        }
    },[floors, managers, buildings, floorID])
    useEffect(()=>{
        // console.log(tickets)
        // if(!markersTicket){
            getMarkerTickets()
            if(!planUrl && floor){
                getImage()
            }
        // }
    }, [tickets, floor])
    
    const getMarkerTickets = () => {
        const arrayTickets = []
        if(tickets){
            try{tickets.map((ticket)=>{
                if (ticket.floorId == floor.id){
                    arrayTickets.push(ticket)
                }
            })

            // console.log(arrayTickets)
            setMarkersTicket(arrayTickets)
            }
            catch {}
        }
        else {
            setMarkersTicket([])
        }
    }
    
    const getMapData = () =>{ 
        const userContactArray = []
        // console.log(floors)
        const thisFloor = floors.find((floor)=> floor.id === floorID)
        setFloor(thisFloor)
        
        const thisManager = managers.find((manager)=> manager.id === thisFloor.managerId)
        // console.log(thisManager)
        setManager(thisManager)
        if(thisManager.contacts){
            thisManager.contacts.forEach(contact => {
                const user = users.find((user)=> user.id === contact)
                if (user){
                    userContactArray.push(users.find((user)=> user.id === contact))
                }
            });
        }
        setContacts(userContactArray)
        
        console.log(type)
        if (idType){
            console.log(idType)
        } else {
            console.log('noItem')
        }


        delete L.Icon.Default.prototype._getIconUrl;
        L.Icon.Default.mergeOptions({
            iconRetinaUrl: '',
            iconUrl: require('./TicketCreate.jpg'),
            shadowUrl: '',
            iconSize:     [50, 30],
            iconAnchor:   [11.4, 17],
            popupAnchor:  [0, -9.5],
        });
        setIconTicket(new L.Icon({
            // options: {
            iconRetinaUrl: '',
            iconUrl: require('./roomklein.png'),
            shadowUrl: '',
            iconSize:     [20, 25],
            iconAnchor:   [11.4, 17],
            popupAnchor:  [0, -9.5],
            // }
        }))
    // }
}
    function MyComponent(state) {
        

        const map = useMapEvents({
            click: (e) => {
                if(addMarkerItem){
                    setAddMarkerItem(false)
                    console.log('addMarker')
                    setPosition(e.latlng)
                    if(markersItem){
                        setMarkersItem([...markersItem, {id:'abcd', position: e.latlng}])
                    }else{
                        setMarkersItem([{id:'abcd', position: e.latlng}])
                    }
                    setItemButton('')
                }
                else if(addMarkerTicket){
                    setAddMarkerTicket(false)
                    console.log('addMarker')
                    console.log(e.latlng)
                    setPosition(e.latlng)
                    if(markersTicket){
                        setMarkersTicket([...markersTicket, {id:'abcd', position: e.latlng}])
                        
                    }else{
                        setMarkersTicket([{id:'abcd', position: e.latlng}])
                    }
                    setShowTicket(true)
                    setTicketButton('')
                }
                console.log(e)
            } 
        })
    }

    if(floor){
    return(
        // <Container>
        //     <Row>
        //         <Col lg={'12'}>
                    <MapContainer
                        center={{ lat: 61.505, lng: 275 }}
                        zoom={2.3}
                        scrollWheelZoom={true}
                        keyboard={false}
                    >
                        <MapModal position={position} val={false} floor={floor} users={contacts} show={showTicket} handleClose={handleCloseTicket}/>
                        <MyComponent/>
                        {markersItem.length > 0?
                            markersItem.map((marker, idx)=>
                                <Marker key={`markerItem-${idx}`} id={marker.id} position={marker.position}/>
                            )
                            : null
                        }
                        {markersTicket.length > 0?
                            markersTicket.map((marker, idx)=>
                                // <Link to='/ticket'>
                                    <Marker icon={iconTicket} key={`markerTicket-${idx}`} id={marker.id} position={marker.position} 
                                    eventHandlers={{
                                        click: (e) => {
                                            setFocusId(e.target.options.id)
                                            navigate("/tickets/none/none/none/"+e.target.options.id)
                                        },
                                    }}
                                    />
                                // </Link>
                            )
                            : null
                        }            
                        <ImageOverlay
                            // url={this.props.mapData.planUrl}
                            url={planUrl?planUrl:floor.planUrl}
                            bounds={[[10,150], [10000,400]]}
                        />
                        <Control prepend position='topright'>
                            <OverlayTrigger
                                className='mb-3'
                                key='goBack'
                                placement='left'
                                overlay={
                                    <Tooltip id={`tooltip-'goBack'`}>
                                    Go <strong>back</strong>
                                    </Tooltip>
                                }
                                >
                                <Button className={'mb-1 btn-sm btnBgNone '+ticketButton} variant={'light'} onClick={() => navigate(-1)}> 
                                {/* <Button className='mb-1 btn-sm' variant={this.state.buttonStyle1}>  */}
                                    {/* <MdApartment size={22}/> */}
                                    <TbBorderCorners size={22}/>
                                </Button>
                            </OverlayTrigger>
                            <br/>
                            <OverlayTrigger
                                className='mb-3'
                                key='addItem'
                                placement='left'
                                overlay={
                                    <Tooltip id={`tooltip-'addItem'`}>
                                        Add <strong>Item</strong>
                                    </Tooltip>
                                }
                                >
                                <Button className={'mb-1 btn-sm btnBgNone '+itemButton} variant={'light'} onClick={()=>{setAddMarkerItem(true); setItemButton('btnActive')}}> 
                                {/* <Button className='mb-1 btn-sm' variant={this.state.buttonStyle1}>  */}
                                    {/* <MdApartment size={22}/> */}
                                    <MdWindow size={22}/>
                                </Button>
                            </OverlayTrigger>
                            <br/>
                            <OverlayTrigger
                                className='mb-3'
                                key='addTicket'
                                placement='left'
                                overlay={
                                    <Tooltip id={`tooltip-'addTicket'`}>
                                    Add <strong>Ticket</strong>
                                    </Tooltip>
                                }
                                >
                                <Button className={'mb-1 btn-sm btnBgNone '+ticketButton} variant={'light'} onClick={()=>{setAddMarkerTicket(true); setTicketButton('btnActive')}}> 
                                {/* <Button className='mb-1 btn-sm' variant={this.state.buttonStyle1}>  */}
                                    {/* <MdApartment size={22}/> */}
                                    <FaTicketAlt size={22}/>
                                </Button>
                            </OverlayTrigger>
                        </Control>
                    </MapContainer>
        //         </Col>
        //     </Row>
        // </Container>
    )
    }
    else {
        return (
            <Container>
                <Row>
                    {
                        floors?
                        floors.map((floor)=>
                            floor.planUrl?
                                <Col lg={'6'} className='mt-3'>
                                    <Card as={Link} to={'/map/'+floor.id} onClick={()=>{setFloorId(floor.id)}}>
                                        <MapContainer
                                            center={{ lat: 61.505, lng: 275 }}
                                            zoom={2.3}
                                            scrollWheelZoom={false}
                                            keyboard={false}
                                            className="leaflet-containerInner"
                                        >
                                            <ImageOverlay
                                                url={floor.planUrl}
                                                bounds={[[10,150], [10000,400]]}
                                            />
                                        </MapContainer>
                                    </Card>
                                </Col>
                                :
                                null
                                // <Col lg={'6'}>
                                //     <h4 className="mt-5">Kein Plan</h4>        
                                // </Col>
                        )
                        :
                        <h4 className="mt-5">Keine Etagen verfügbar</h4>
                    }
                </Row>
            </Container>
        )
    }
}