import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { collection, addDoc, serverTimestamp, updateDoc, getDoc, doc, query, where, getDocs, arrayUnion } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import uuid from 'react-uuid';

export function ModalCreateUser({ ticket , addUser, closeAddUser }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
//   const [plan, setPlan] = useState('');
  const [note, setNote] = useState('');
  const [error, setError] = useState('');

  // console.log(managerId, 'and ', buildingId)
  async function formVal(e) {
    e.preventDefault();
    console.log(e);
    if(email && name){
        const auth = getAuth();
        const user = auth.currentUser;
        // const storage = getStorage();

        // console.log(user.uid);
        const q = query(collection(db, "user"), where("email", "==", email));

        const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //   // doc.data() is never undefined for query doc snapshots
        //   console.log(doc.id, " => ", doc.data());
        // });
        // if(querySnapshot.exists()){
        if(querySnapshot.docs.length >0){
            const docRef = doc(db, "ticket", ticket.id)
            setError('test')
            if(!ticket.visible.includes(querySnapshot.docs[0].id)){
                updateDoc(docRef, {
                    visible: arrayUnion(querySnapshot.docs[0].id),
                })
                setError('User existiert bereits und wurde zum Ticket eingeladen.')
                // setError('test')
            }
            else {
                setError('User ist bereit Teilnehmer.')
            }
            // console.log(querySnapshot.docs[0].id)
        }
        else {
            const docRef = await addDoc(collection(db, "user"), {
                displayName: name,
                email: email,
                created: serverTimestamp(),
            })
            // addDoc(collection(db, "mail"), {
            //     to: email,
            //     message: {
            //       subject: "Einladung zum Ticket "+ticket.name,
            //       html: ticket.note+'<br/><br/> Wenn sie kein Konto bei uns haben, melden Sie sich hier an: <a href="https://hexakt-92a98.web.app/regist">Anmelden</a>',
            //     },
            // })
            // admin.firestore().collection('mail').add({
            //     to: data.email,
            //     message: {
            //       subject: "Einladung zum Ticket "+document.name,
            //       html: document.note,
            //     },
            //   })
            updateDoc(doc(db, "ticket", ticket.id), {
                visible: arrayUnion(docRef.id),
            })
            closeAddUser()
            
        }
    }
    else{
        setError('Felder Ausfüllen.')
    }
    // }
    // else{
    //     console.log('error')
    // }
    

    // addDoc(collection(db, "user"), {
    // name: name,
    // email: email,
    // created: serverTimestamp(),
    // })
    
  }
  return (
    <Modal show={addUser} onHide={closeAddUser} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Benutzer einladen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPlan">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                value={email}
                // accept="image/png,image/jpeg"
                // accept="image/svg+xml"
                onChange={(e) => {
                  setEmail(e.target.value);
                }} />
            </Form.Group>
          </Row>
          {
            error?
            <>
            {error}<br/>
            </>
            :
            null
          }
          <Button onClick={formVal} variant="primary" type="submit">
            Einladen
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
