import React from 'react';
import { useState, useEffect } from 'react';
import Dexie from 'dexie';
import { useLiveQuery } from "dexie-react-hooks";

export default function SafeNewImages({userData, floors, buildings}){

    const [data, setData] = useState(false)
    const [data2, setData2] = useState(false)
    const [database, setDatabase] = useState(false)
    
    function arrayBufferToBlob(buffer, type) {
        return new Blob([buffer], { type: type });
    }
    function blobToArrayBuffer(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.addEventListener('loadend', () => {
            resolve(reader.result);
            });
            reader.addEventListener('error', reject);
            reader.readAsArrayBuffer(blob);
        });
    }
    useEffect(()=>{
        if (!data && userData && floors){
            getDatabase()
        }
    })
    useEffect(()=>{
        if(!data2 && buildings){
            getDatabase2()
        }
    }, [buildings])


    const getDatabase2 = async () => {
        
        const dbDexi = new Dexie('IfcData')
        dbDexi.version(1).stores({
        friends: '++id, name, age', // Primary key and indexed props
        });
        // const name = 'test'
        // const age= 10
        // const id = await dbDexi.friends.add({name, age});

        // console.log('dexitest')
        
        setData(true)
        const indexddb =  window.indexedDB.open("IfcData");
        
        indexddb.onsuccess = async function (e) {
            // console.log("success...");
            // console.log(e.target.result.version);
            // const db12= e.target.result;

            if(e.target.result.version == 1){
                e.target.result.close()
                if (buildings.length > 0){
                    buildings.forEach(element => {
                        if(element.ifcUrl){
                            fetch(element.ifcUrl)
                            .then(res => res.blob()) 
                            .then(async (blob) => {
                                const array = await blobToArrayBuffer(blob)
                                // console.log(array)
                                const name = element.ifcUrl
                                const resp = await dbDexi.friends.add({name, array});
                                // console.log(resp)
                            })
                        }
                        else{
                            console.log('noIfcUrl')
                        }
                    });
                }
                // floor
            }
            else {
                const request = await e.target.result.transaction('friends')
                .objectStore('friends')
                .getAll();
                

                request.onsuccess = function(){
                    e.target.result.close()
                    if (buildings.length > 0){
                        buildings.forEach(element => {
                            if(element.ifcUrl){
                                if(!request.result.find(el => el.name == element.ifcUrl)){
                                    fetch(element.ifcUrl)
                                    .then(res => res.blob()) 
                                    .then(async (blob) => {
                                        const array = await blobToArrayBuffer(blob)
                                        // console.log(array)
                                        const name = element.ifcUrl
                                        const resp = await dbDexi.friends.add({name, array});
                                        // console.log(resp)
                                    })
        
                                }
                            }
                            else{
                                console.log('noIfcUrl')
                            }
                        });
                    }
                }
            }
        };
        

    }
    const getDatabase = async () => {
        
        const dbDexi = new Dexie('ImageData')
        dbDexi.version(1).stores({
        friends: '++id, name, age', // Primary key and indexed props
        });
        // const name = 'test'
        // const age= 10
        // const id = await dbDexi.friends.add({name, age});

        // console.log('dexitest')
        
        setData(true)
        const indexddb =  window.indexedDB.open("ImageData");
        
        indexddb.onsuccess = async function (e) {
            // console.log("success...");
            // console.log(e.target.result.version);
            // const db12= e.target.result;

            if(e.target.result.version == 1){
                e.target.result.close()
                fetch(userData.photoURL)
                .then(res => res.blob()) 
                .then(async (blob) => {
                    const array = await blobToArrayBuffer(blob)
                    // console.log(array)
                    const name = userData.photoURL
                    const resp = await dbDexi.friends.add({name, array});
                    // console.log(resp)
                })
                if (floors.length > 0){
                    floors.forEach(element => {
                        fetch(element.planUrl)
                        .then(res => res.blob()) 
                        .then(async (blob) => {
                            const array = await blobToArrayBuffer(blob)
                            // console.log(array)
                            const name = element.planUrl
                            const resp = await dbDexi.friends.add({name, array});
                            // console.log(resp)
                        })
                    });
                }
                // floor
            }
            else {
                const request = await e.target.result.transaction('friends')
                .objectStore('friends')
                .getAll();
                

                request.onsuccess = function(){
                    e.target.result.close()
                    // console.log(request.result)
                    if(!request.result.find(el => el.name == userData.photoURL)){
                        fetch(userData.photoURL)
                        .then(res => res.blob()) 
                        .then(async (blob) => {
                            const array = await blobToArrayBuffer(blob)
                            // console.log(array)
                            const name = userData.photoURL
                            const resp = await dbDexi.friends.add({name, array});
                            // console.log(resp)
                        })
                    }
                    if (floors.length > 0){
                        // console.log('floorForEach')
                        floors.forEach(element => {
                            if(!request.result.find(el => el.name == element.planUrl)){
                                fetch(element.planUrl)
                                .then(res => res.blob()) 
                                .then(async (blob) => {
                                    const array = await blobToArrayBuffer(blob)
                                    // console.log(array)
                                    const name = element.planUrl
                                    const resp = await dbDexi.friends.add({name, array});
                                    // console.log(resp)
                                })
    
                            }
                        });
                    }
                }
            }
        };
        

    }

    // request.addEventListener('error', (event) => {
    //     console.log('Request error:', request.error);
    // });
    
    
}