import React from "react";
import { useState, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import { NavCube } from '../NavCube/NavCube'
import { MdVisibility, MdVisibilityOff, MdContentCut, MdDeleteForever } from "react-icons/md";
import { db } from "../firebase";
import { addDoc, serverTimestamp, doc, where, getDoc, setDoc, updateDoc, query, onSnapshot, collection, arrayUnion, arrayRemove, increment } from "firebase/firestore";
import {TiDelete} from 'react-icons/ti'
import { BsFillTicketFill, BsFillHandIndexThumbFill } from "react-icons/bs";
// import {
//   AmbientLight,
//   AxesHelper,
//   DirectionalLight,
//   GridHelper,
//   PerspectiveCamera,
//   Scene,
//   WebGLRenderer,
// } from "three";
import {
  BoxGeometry,
  MeshBasicMaterial,
  MeshNormalMaterial,
  CylinderGeometry,
  LineBasicMaterial,
  SphereGeometry,
  Color,
  Vector2,
  DepthTexture,
  WebGLRenderTarget, Material, BufferGeometry, BufferAttribute, Mesh,
  Raycaster,
  Vector3,
  Box3,
  Line,
  ObjectLoader,
  // Mesh,
} from 'three';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js';
// import IfcBundle from "../tools/IfcBundle";

// import {IfcViewerAPI} from 'web-ifc-viewer'

import { OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Badge} from "react-bootstrap";

export default function GetIfc({ buildings }){
  let { buildingId } = useParams();
  const ref = useRef()
  const ref2 = useRef()
  const [buildingUrl, setBuildingUrl] = useState(false)
  const [id, setId] = useState(false)
  const [showItem, setShowItem] = useState(false)
  const [ifcGet, setIfcGet] = useState(false)
  const [input, setInput] = useState(false)
  const [viewer2, setViewer2] = useState(false)
  const [vector01, setVector01] = useState(false)
  const [vector02, setVector02] = useState(false)
  const [viewer, setViewer] = useState(false)
  const [castRay, setCastRay] = useState(false)
  const [pickedPlan, setPickedPlan] = useState(false)
  const [model, setModel] = useState(false)
  const [mainSubset, setMainSubset] = useState(false)
  const [properties, setProperties] = useState(false)
  const [floors, setFloors] = useState([])
  const [floorsDetails, setFloorsDetails] = useState([])
  const [rooms, setRooms] = useState([])
  const [units, setUnits] = useState([])
  const [unitsDetails, setUnitsDetails] = useState(false)
  const [raycaster, setRaycaster] = useState(false)
  const [mouse, setMouse] = useState(false)
  const [bounds, setBounds] = useState(false)
  const [item, setItem] = useState([])
  const [markers, setMarkers] = useState([])
  const [height, setHeight] = useState(400)
  const [markers2, setMarkers2] = useState([])
  const [addMarker, setAddMarker] = useState(false)
  const [activeUnit, setActiveUnit] = useState(false)
  const [activeSubset, setActiveSubset] = useState('full-model-subset')

  useEffect(()=>
  {
    if(input && ref.current){
      GetIfc()    
    }

  },[input])
  useEffect(()=>
  {
    if(buildingUrl && ref.current){
      GetIfc()
    }

  },[buildingUrl])
  
  useEffect(()=>
  {
    if(buildingId && buildings && !ifcGet){
      // console.log(buildings)
      // console.log(buildingId)
      setIfcGet(true)
      GetIfcMain(buildings.find(el=>el.id == buildingId))
      console.log('buildingId') 
    }

  },[buildingId, buildings])

  const GetIfcMain = async (building) => {
    console.log(building)
    
      const indexddb =  window.indexedDB.open("IfcData");
      indexddb.onsuccess = async function (e) {
          console.log("success...");
          // const db12= e.target.result;
          const request = await e.target.result.transaction('friends')
                 .objectStore('friends')
                 .getAll();

          request.onsuccess = function(){
              console.log(request.result)
              if(request.result.find(el => el.name == building.ifcUrl)){
                  const blob = new Blob( [ request.result.find(el => el.name == building.ifcUrl).array ], {type: 'image/svg+xml'} );
                  const url = URL.createObjectURL( blob );
                  setBuildingUrl(url)
                  console.log(request.result.find(el => el.name == building.ifcUrl))
                  console.log(url)
              }
              else {
                  console.log('planFehlt')
                  setBuildingUrl(building.ifcUrl)
              }
          }

      };

      const q = query(collection(db, "mesh"));
        
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
            const announcementArray2 = []
            querySnapshot.forEach((doc) => {
                announcementArray2.push({...doc.data(), id: doc.id})
            });
            if (announcementArray2.length > 0){
                // cons
                setMarkers(announcementArray2)
                console.log(announcementArray2)
            }
        })
      
  
  }
  const GetIfc = async () => {
    import ('web-ifc-viewer').then(async({ IfcViewerAPI })=>{
      
      
      const container = ref.current
      
      const viewer = new IfcViewerAPI({
        container,
        backgroundColor: new Color(0xffffff),
      });
      // viewer.shadowDropper.darkness = 1.5
      // viewer.IFC.loader.ifcManager.applyWebIfcConfig({
      //   USE_FAST_BOOLS: true,
      //   COORDINATE_TO_ORIGIN: true
      // });
      
      // viewer.context.renderer.postProduction.active = true;
      setViewer2(viewer)
      viewer.axes.setAxes();
      await viewer.IFC.setWasmPath('../../')
      if(buildingUrl){
        console.log(buildingUrl)
        // ifcURL = URL.createObjectURL(buildingUrl);
        console.log(viewer.IFC)
        // viewer.IFC.loader.ifcManager.applyWebIfcConfig({
        //   COORDINATE_TO_ORIGIN: true,
        //   USE_FAST_BOOLS: false
        // });
        const model = await viewer.IFC.loadIfcUrl(buildingUrl, true).then((viewer3)=>{
          console.log(viewer3)
          console.log(viewer)
          viewer.container = container;
          const raycaster = new Raycaster();
          setRaycaster(raycaster)
          const mouse = new Vector2();
          setMouse(mouse)
          const bounds = ref.current.getBoundingClientRect();
          setBounds(bounds)
          
          // GetFloors({viewer: viewer, model: viewer.IFC.context.items.ifcModels[0]})
          console.log(viewer.IFC.context.items.ifcModels[0].modelID)
          if(markers){
            var cubes = []
            markers.map((el, idx)=>{
              const cube = new ObjectLoader().parse( el )
              cube.position.set(el.position[0], el.position[1], el.position[2])
              // cube.lookAt(new Vector3(el.positionN[0], el.positionN[1], el.positionN[2]))
              // cube.rotateX(Math.PI / 2)
              // cube.position.addScaledVector(new Vector3(el.positionN[0], el.positionN[1], el.positionN[2]), 0.03)
              viewer.context.scene.add(cube)
              console.log(cube)
              cubes.push(cube)
            })
            setMarkers2(cubes)
          }
          return viewer3
        }).then((viewer3)=>{
          console.log(viewer3)
          const allIDs = getAllIds(viewer3);
      
          const subset = getWholeSubset(viewer, viewer3, allIDs);
          setMainSubset(allIDs)
          console.log(subset)
          replaceOriginalModelBySubset(viewer, viewer3, subset);
          
          GetFloors({viewer: viewer, model: viewer.IFC.context.items.ifcModels[0]})
          if ('ontouchstart' in window) {
            // The device supports touch events
          } else {
            const navCube = new NavCube(viewer);
            navCube.onPick(viewer.IFC.context.items.ifcModels[0]);
            console.log('ready')
          }
          
        });
        setModel(viewer.IFC.context.items.ifcModels[0])
          
        viewer.clipper.active = true;
        
        
      }
      else {
        const ifcURL = URL.createObjectURL(input);
        const model = await viewer.IFC.loadIfcUrl(ifcURL, false);
        const allIDs = getAllIds(model);
      
        const subset = getWholeSubset(viewer, model, allIDs);
        setMainSubset(allIDs)
        console.log(subset)
        replaceOriginalModelBySubset(viewer, model, subset);
        viewer.container = container;
        const navCube = new NavCube(viewer);
        navCube.onPick(viewer.IFC.context.items.ifcModels[0]);
        GetFloors({viewer: viewer, model: viewer.IFC.context.items.ifcModels[0]})
        setModel(viewer.IFC.context.items.ifcModels[0])
        console.log(viewer.IFC.context.items.ifcModels[0].modelID)
        viewer.clipper.active = true;
        const raycaster = new Raycaster();
        setRaycaster(raycaster)
        const mouse = new Vector2();
        setMouse(mouse)
        const bounds = ref.current.getBoundingClientRect();
        setBounds(bounds)
      }
      
      
      

      // viewer.shadowDropper.renderShadow(viewer.IFC.context.items.ifcModels[0].modelID)
      
      // window.ondblclick = () => viewer.IFC.selector.pickIfcItem(true);
      
    



      
    })
  }
  window.onclick = async (event) => {
    if(viewer2){
      if(viewer2.clipper.planes.length > 0){
        if(viewer2.clipper.pickPlane()){
          setPickedPlan(viewer2.clipper.pickPlane())
          console.log(viewer2.clipper)
        }
        else{
          setPickedPlan(false)
        }
        // console.log(viewer.clipper.planes)
      }
      if(addMarker){
        addMarkerF(event)
        setAddMarker(false)
      }
      if(markers2.length>0){
        
        
        const x1 = event.clientX - bounds.left;
        const x2 = bounds.right - bounds.left;
        mouse.x = (x1 / x2) * 2 - 1;
      
        const y1 = event.clientY - bounds.top;
        const y2 = bounds.bottom - bounds.top;
        mouse.y = -(y1 / y2) * 2 + 1;
      
        // Places it on the camera pointing to the mouse
        raycaster.setFromCamera(mouse, viewer2.context.ifcCamera.perspectiveCamera);
        // console.log(model)
        // const object = raycaster.intersectObjects(model);
        const object2 = raycaster.intersectObjects(markers2);
        // console.log(markers)
        if(object2.length > 0){
          console.log(object2[0].object.uuid)
        }
        
      }
      if(showItem){
        const {modelID, id} = await viewer2.IFC.selector.pickIfcItem(true);
        setActiveUnit(false)
        const subset = viewer2.IFC.loader.ifcManager.createSubset({
          ids: [id],
          modelID,
          removePrevious: true,
          id
        });
        
        const props = await viewer2.IFC.getProperties(modelID, id, true, true);
        console.log(props);
        
        setItem(props)
        setShowItem(false)
        // console.log(viewer.context.castRayIfc());
        
        if(id){
          setId(id)
          setCastRay(viewer2.clipper.context.castRayIfc())
        } 
      }
     

    }
  }
  
  
  // window.ondblclick = async () => {
  //   if(viewer2){
  //     const {modelID, id} = await viewer2.IFC.selector.pickIfcItem(true);
  //     setActiveUnit(false)
  //     const subset = viewer2.IFC.loader.ifcManager.createSubset({
  //       ids: [id],
  //       modelID,
  //       removePrevious: true,
  //       id
  //     });
      
  //     const props = await viewer2.IFC.getProperties(modelID, id, true, true);
  //     console.log(props);
      
  //     setItem(props)
  
  //     // console.log(viewer.context.castRayIfc());
      
  //     if(id){
  //       setId(id)
  //       setCastRay(viewer2.clipper.context.castRayIfc())
  //     } 
  //   }
  // }

  

  const hideItem2 = () => {
    console.log('test')
    console.log(viewer2)
  }
  async function hideClickedItem() {
    console.log('testHide')
    
    if (!id || !viewer2) return;
    console.log(viewer2)
    console.log(id)
    const manager = viewer2.IFC.loader.ifcManager;
    console.log(manager)
    await viewer2.IFC.loader.ifcManager.removeFromSubset(
      0,
      [id],
      activeSubset,
    )
    viewer2.IFC.selector.unpickIfcItems()
    setId(false)
  }
  function getAllIds(ifcModel) {
    return Array.from(
      new Set(ifcModel.geometry.attributes.expressID.array),
    );
  }
  function getWholeSubset(viewer, ifcModel, allIDs, name) {
    if(name){
      setActiveSubset(name)
      console.log(name)
      return viewer.IFC.loader.ifcManager.createSubset({
        modelID: ifcModel.modelID,
        ids: allIDs,
        applyBVH: true,
        scene: ifcModel.parent,
        removePrevious: true,
        customID: name,
      });
    }
    else {
      setActiveSubset('full-model-subset')
      
      return viewer.IFC.loader.ifcManager.createSubset({
        modelID: ifcModel.modelID,
        ids: allIDs,
        applyBVH: true,
        scene: ifcModel.parent,
        removePrevious: true,
        customID: 'full-model-subset',
      });
    }
  }
  function replaceOriginalModelBySubset(viewer, ifcModel, subset) {
    const items = viewer.context.items;
  
    items.pickableIfcModels = items.pickableIfcModels.filter(model => model !== ifcModel);
    items.ifcModels = items.ifcModels.filter(model => model !== ifcModel);
    ifcModel.removeFromParent();
  
    items.ifcModels.push(subset);
    items.pickableIfcModels.push(subset);
  }
  const GetFloors = async ({viewer, model}) => {
    console.log(viewer)
    const ifcProject = await viewer.IFC.getSpatialStructure(model.modelID);
    if(ifcProject.children[0].children[0].type == "IFCBUILDING"){
      // console.log(ifcProject.children[0].children[0].children)
      const floors = []
      ifcProject.children[0].children[0].children.map(async(el, idx)=>{
        const props = await viewer.IFC.getProperties(model.modelID, el.expressID, true, true);
          floors.push(props)
      })
      console.log(floors)
      setFloors(ifcProject.children[0].children[0].children)
      setFloorsDetails(floors)
      // console.log(ifcProject.children[0].children[0].children)
      const rooms = []
      const units = []
      ifcProject.children[0].children[0].children.map(async (floor, idx)=>{
        floor.children.filter(el => el.type == 'IFCSPACE').map( async (room, idx) => {
          const props = await viewer.IFC.getProperties(model.modelID, room.expressID, true, true);
          const thisUnit = props.psets.find(el=>el.Name.value == 'AC_Pset_Raumstempel_DIN_25').HasProperties.find(el=>el.Name.value == 'Einheitenname').NominalValue.value
          rooms.push({...props, floorId: floor.expressID, unit: thisUnit})
          if(!units.includes(thisUnit)){
            units.push(thisUnit)
          }
          
          // console.log(props.psets.find(el=>el.Name.value == 'AC_Pset_Raumstempel_DIN_25').HasProperties.find(el=>el.Name.value == 'Einheitenname').NominalValue.value)
        })
      })
      
      setUnits(units)
      setRooms(rooms)
    }
  }

  const createPlane = async() => {
    if(castRay){
      console.log(castRay)  
      viewer2.clipper.createPlaneFromIntersection(castRay)
    }
    else{
      console.log('error')
    }
    console.log(viewer2.clipper)
  }
  const deletePlane = async() => {
    console.log('delete')

      
      const index = viewer2.clipper.planes.indexOf(pickedPlan);
      
      pickedPlan.removeFromScene();
      viewer2.clipper.planes.splice(index, 1);
      viewer2.clipper.context.removeClippingPlane(pickedPlan.plane);
      viewer2.clipper.updateMaterials();
      viewer2.clipper.context.renderer.postProduction.update();
    setPickedPlan(false)
  }
  const showFloor = (floor)=>{
    var idsArray = [floor.expressID]
    floor.children.map((el, idx)=>{
      idsArray.push(el.expressID)
      if(el.children > 0){
        el.children.map((el2, idx2)=>{
          idsArray.push(el2.expressID)
        })
      }
    })
    console.log(idsArray)
    const subset = getWholeSubset(viewer2, model, idsArray, 'floor'+floor.expressID);
    replaceOriginalModelBySubset(viewer2, model, subset);
    setModel(viewer2.IFC.context.items.ifcModels[0])
  }
  const showAll = ()=>{
    
    const subset = getWholeSubset(viewer2, model, mainSubset, 'full-model-subset');
    replaceOriginalModelBySubset(viewer2, model, subset);
    setModel(viewer2.IFC.context.items.ifcModels[0])
  }
  const showUnit = async (unit)=>{
    const unitRooms = rooms.filter(el => el.unit == unit)
    const ids = []
    const area = []
    const floors = []
    unitRooms.map((el, idx)=>{
      area.push(el.psets.find(el=>el.Name.value == "Qto_SpaceBaseQuantities").Quantities.find(el=>el.Name.value == "GrossFloorArea").AreaValue.value)
      ids.push(el.expressID)
      if(!floors.includes(el.floorId)){
        floors.push(el.floorId)
      }
    })
    console.log(floors)
    setUnitsDetails({area: area.reduce((partialSum, a) => partialSum + a, 0), rooms: unitRooms.length, floors: floors})
    await viewer2.IFC.selector.pickIfcItemsByID(0, ids, true)
    console.log('testShowUnit')
  }
  const showAllUnits = async ()=> {
    
    await viewer2.IFC.selector.unpickIfcItems();
  }
  const getJson = () => {
    console.log('getJson')
  }
  const unselectItem = async () => {
    await viewer2.IFC.selector.unpickIfcItems();
    setId(false)
  }
  const showRoomsTest = () => {
    console.log(rooms)
    console.log(units)
  }

  window.onkeydown = (event) => {
    if (event.code === "KeyP") {
      console.log(viewer2.clipper.context.castRayIfc())
      viewer2.clipper.createPlane();
    } else if (event.code === "KeyO") {
      viewer2.clipper.deletePlane();
    } else if (event.code === "KeyQ") {
      // showRoomsTest()
      console.log(markers)
      // const volume = calculateVolume(viewer.context.castRayIfc().object.geometry)
      // console.log(volume)
      
    }
    else if (event.code === "KeyD") {
      if(!vector01){
        setVector01(viewer.context.castRayIfc().point)
        
      }
      else {
        setVector02(viewer2.context.castRayIfc().point)
        const distance = distanceVector(vector01, vector02)
        console.log(distance)
        
        setVector01(false)
      }
      console.log(viewer2.context.castRayIfc())
    }
    else if (event.code === "KeyH") {
      console.log('testH')
    }
  };
  function distanceVector( v1, v2 )
  {
      var dx = v1.x - v2.x;
      var dy = v1.y - v2.y;
      var dz = v1.z - v2.z;
  
      return Math.sqrt( dx * dx + dy * dy + dz * dz );
  }
  const addMarkerF = (event) => {
    const mouse = new Vector2();
    const raycaster = new Raycaster();
    const bounds = ref.current.getBoundingClientRect()
    const x1 = event.clientX - bounds.left;
    const x2 = bounds.right - bounds.left;
    mouse.x = (x1 / x2) * 2 - 1;
  
    const y1 = event.clientY - bounds.top;
    const y2 = bounds.bottom - bounds.top;
    mouse.y = -(y1 / y2) * 2 + 1;
    console.log(bounds)
    raycaster.setFromCamera(mouse, viewer2.context.ifcCamera.perspectiveCamera);
    console.log(raycaster)
    console.log(model)
    console.log([viewer2.context.items.ifcModels[0]])
    const object = viewer2.context.castRayIfc()
    console.log(object)
    const material = new MeshBasicMaterial({
      color: 0x0d6efd,
      wireframe: true,
      wireframeLinewidth: 4,
   })
    
    const coneGeometry = new SphereGeometry( 0.15, 9, 9 )
    const n = new Vector3()
    // const face = Face
    
    // face = new THREE.Face3(2,1,3);
    // var face = new THREE.Face;
    n.copy((object.face).normal)
    n.transformDirection(object.object.matrixWorld)
    // console.log(n.getX())
    // const cube = new THREE.Mesh(boxGeometry, material)

    const cube = new Mesh(coneGeometry, material)
    console.log(object.object.matrixWorld)
    cube.lookAt(n)
    cube.rotateX(Math.PI / 2)
    cube.position.copy(object.point)
    cube.position.addScaledVector(n, 0.03)
    // var array = markers
    // array.push(cube)
    
    setMarkers([...markers, cube])
    viewer2.context.scene.add(cube)
    var meshObject = cube.toJSON()
    meshObject['position'] = [cube.position.x, cube.position.y, cube.position.z]
    // meshObject['positionN'] = [n.x, n.y, n.z]
    // meshObject['positionNtransform'] = object.object.matrixWorld
    // console.log()
    // meshObject['lookAt'] = 
    // var meshObjectPosition = cube.position.toJSON()
    // meshObject['buildingId'] = buildingId
    addDoc(collection(db, "mesh"), meshObject)
    console.log(cube)
    console.log(meshObject)
    // console.log(meshObjectPosition)
  }

  return (
      <div>
          {/* <input ref={ref2} id='file-input' type='file'
            onChange={(e)=>{
              setInput(e.target.files[0])
            }}
          />
          {properties?
          <Button id="getJson"
            onclick={()=>
              getJson()
            }          
          >test123</Button>
          :null
} */}
        
          <div ref={ref} id='viewer-container'>
          {id?
            <>
              <Button style={{padding: 5, position: 'absolute', top: '5px', left: '20px'}}
              onClick={()=>{
                hideClickedItem()
              }}
              >
                <MdVisibilityOff style={{margin: 0}}/>
              </Button>
              <Button style={{padding: 5, position: 'absolute', top: '5px', left: '50px'}}
                onClick={()=>{
                  createPlane()
                }}
              >
                <MdContentCut style={{margin: 0}}/>
              </Button>
              <Button
               style={{padding: 5, position: 'absolute', top: '5px', left: '80px'}}
               onClick={()=>{
                unselectItem()
              }}
              >
                <TiDelete style={{margin: 0}}/>
              </Button>
              {
                item.psets.filter(v => v.type == '1883228015').length > 0?
                <div style={{padding: 5, position: 'absolute', top: '45px', left: '20px', maxHeight: '300px', overflow: 'scroll'}}>
                {item.psets.filter(v => v.type == '1883228015').map((element, idx) =>
                  <Card
                    className="mb-1"
                    style={{backgroundColor: 'rgba(255,255,255,0.5)'}}
                  >
                    <Card.Body>
                      {element.Name.value}<br/>
                      <p style={{fontSize: '12px'}}>
                      {
                        element.Quantities.map(qty =>
                          <>
                          {
                            qty.HasQuantities?
                            qty.Name.value
                            :
                            null
                          }
                          {qty.Name.value} : {qty.type == 2044713172?qty.AreaValue.value.toFixed(4)+' m2':null}{qty.type == 931644368?qty.LengthValue.value.toFixed(4)+' m':null}{qty.type == 2405470396?qty.VolumeValue.value.toFixed(4)+' m3':null}
                                              
                          <br/>
                          </>
                        )
                        
                      }
                      </p>
                    </Card.Body>
                  </Card>    
                  )}
                  </div>
                  : null
              }
              
            </>
            :
            null

          }             
          {
            pickedPlan?
              <Button style={{padding: 5, position: 'absolute', top: '5px', left: '110px'}}
                onClick={()=>{
                  deletePlane()
                }}
                >
                  <MdDeleteForever style={{margin: 0}}/>
              </Button>
              :
              null
          }
          {!id?
            floors.length > 0?
              floors.map((el, idx)=>
                <Button size='ssm' className={activeSubset=='floor'+el.expressID?'btn-primary': 'btn-light'} id={'floor'+el.expressID} style={{padding: 5, position: 'absolute', top: idx*29+5+'px', left: '20px'}}
                  onClick={
                    (e)=>
                    {
                      if(e.target.id === activeSubset ){

                        console.log(mainSubset)
                        showAll()
                      }
                      else {
                        showFloor(el)
                      }
                      // e.target.classList.
                      
                    }
                  }
                  
                >
                  {floorsDetails[idx].Name.value}
                </Button>
              )
            :null
            :null
          }
          {!id && activeSubset?
            rooms.length > 0?
              rooms.filter(el => 'floor'+el.floorId == activeSubset).map((el, idx)=>
                <Button size='sm' className={activeSubset=='floor'+el.expressID?'btn-secondary': null} id={'floor'+el.expressID} style={{padding: 5, position: 'absolute', top: idx*35+5+'px', left: '85px'}}
                  onClick={
                    (e)=>
                    {
                      if(e.target.id === activeSubset ){

                        console.log(mainSubset)
                        showAll()
                      }
                      else {
                        showFloor(el)
                        
                      }
                      // e.target.classList.
                      
                    }
                  }
                  
                >
                  {el.Name.value}
                </Button>
              )
            :null
            :null
          }
          {!id && activeSubset == 'full-model-subset'?
            units.length > 0?
            units.map((el, idx)=>
                <Button size='sm' className={activeUnit==el?'btn-secondary': null} id={el} style={{padding: 5, position: 'absolute', top: idx*35+5+'px', left: '85px'}}
                  onClick={
                    (e)=>
                    {
                      if(e.target.id === activeUnit ){

                        console.log(mainSubset)
                        showAllUnits()
                        setActiveUnit(false)
                      }
                      else {
                        showUnit(el)
                        setActiveUnit(el)
                      }
                      // e.target.classList.
                      
                    }
                  }
                  
                >
                  {el}
                </Button>
              )
            :null
            :null
          }
          
          <Button size="sm" style={{padding: 5, position: 'absolute', top: '130px', right: '5px'}}
            className={showItem?'':'btn-light'}
            onClick={()=>{
                if(showItem){
                  setShowItem(false)
                }
                else{
                  setTimeout(function() {
                    setShowItem(true)
                  }, 200)
                }
              }
            }
          >
            {/* Pick Item */}
            <BsFillHandIndexThumbFill/>
          </Button>
          <Button size="sm" style={{padding: 5, position: 'absolute', top: '165px', right: '5px'}}
            className={addMarker?'':'btn-light'}
            onClick={()=>{
                if(addMarker){
                  setAddMarker(false)
                }
                else{
                  setTimeout(function() {
                    setAddMarker(true)
                  }, 200)
                }
              }
            }
          >
            <BsFillTicketFill/>
          </Button>
           
          {
            activeUnit?
            <Card style={{padding: 5, position: 'absolute', top: '165px', right: '5px'}}>
              <Card.Body>
                {/* {rooms.filter(el=>el.unit == activeUnit).reduce((n, {Amount}) => n + Amount, 0)}   */}
                
                {unitsDetails?
                <div>
                  Fläche: {unitsDetails.area.toFixed(2)} m2<br/>
                  Räume: {unitsDetails.rooms}<br/>
                  Etage: {unitsDetails.floors>1?floorsDetails.find(el=>el.expressID==unitsDetails.floors[0]).Name.value+unitsDetails.floors.slice(1).map((el,idx)=>', '+floorsDetails.find(el2=>el2.expressID==el).Name.value):floorsDetails.find(el=>el.expressID==unitsDetails.floors[0]).Name.value}
                </div>
                :
                  null
                }
              </Card.Body>
            </Card>
            :
            null
          }
          </div>

      
      </div>
  )

}