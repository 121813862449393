import { useState, useEffect } from 'react';
import { auth } from "../firebase";
import {  signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Button, Row, Container, Card, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";


import 'react-toastify/dist/ReactToastify.css';




const Login = ({setUser}) => {
    // console.log(window.localStorage.getItem('emailForSignIn'))
    const [email, setEmail] = useState();
    const [password, setPassword] = useState('')
    const [passwordVisible, setPasswordVisible] = useState("password")
    const [loginError, setLoginError] = useState(false)

    const onLogin = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            setUser(user)
            console.log(user);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setLoginError(errorCode)
            console.log(errorCode, errorMessage)
        });
    }
    const handleVis = () => {
        if(passwordVisible == "password"){
            setPasswordVisible('text')
        }
        else {
            setPasswordVisible('password')
        }
    }
     

    return (
        <div>
      <Container>
        <Row className=" mt-3 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            {/* <div className="border border-3 border-primary"></div> */}
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase ">Hexakt</h2>
                  <p className=" mb-5">Please enter your login and password!</p>
                  <div className="mb-3">
                    <Form onSubmit={onLogin}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email Adresse
                        </Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Passwort</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control type={passwordVisible} placeholder="Enter Passwort" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                            <Button variant="outline-secondary" id="button-addon2" onClick={handleVis}>
                                {passwordVisible == 'password'?
                                <MdVisibility/>
                                :
                                <MdVisibilityOff/>
                                }
                            </Button>
                        </InputGroup>
                      </Form.Group>
                      {loginError?
                        <p style={{color: 'red'}}>
                          Ihre Anmeldedaten sind nicht korrekt. 
                        </p>
                      :
                      null
                      }
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <p className="small">
                            <Link to={'/passwordReset'}>
                                Forgot password?
                            </Link>
                          
                        </p>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit">
                          Login
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        Noch keinen Account?{" "}
                        {/* <a href="{''}" className="text-primary fw-bold"> */}
                        <Link className='text-primary fw-bold' to={'/regist'}>
                            Registrieren
                        </Link>
                        
                      </p>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    )
}
export default Login