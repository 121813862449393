import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, doc, addDoc, serverTimestamp, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db, auth } from "../firebase";

import Chat from "../tools/Chat"
// import { Typeahead } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import { TbUserPlus, TbUserX, TbBrandMessenger } from "react-icons/tb";
import { OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Badge } from "react-bootstrap";


export function Manager({users, manager, tickets, buildings}){
    
    const [singleSelections, setSingleSelections] = useState([]);
    const [notContacts, setNotContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [usersDefault, setUsersDefault] = useState([]);
    const [contactsDefault, setContactsDefault] = useState([]);
    const [contacts, setContacts] = useState(manager.contacts);
    const [inputText, setInputText] = useState("");
    const [showChat, setShowChat] = useState(false)
    const [chatId, setChatId] = useState(false)
    const handleCloseChat = () => {
        setShowChat(false)
        setChatId(false)
    }
    const handleShowChat = (id) => {
        console.log(id)
        setChatId(id)
        setShowChat(true)
    }


    const addNewContact = (id) => {
        const docRef = doc(db, "management", manager.id)
        updateDoc(docRef, {
            contacts: arrayUnion(id)
        })
        console.log(id)
    }
    const deleteNewContact = (id) => {
        const docRef = doc(db, "management", manager.id)
        updateDoc(docRef, {
            contacts: arrayRemove(id)
        })
        console.log(id)
    }
    const searchContacts = (e) => {
        console.log('searchContacts')
        console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredContacts = contactsDefault.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.displayName.toLowerCase().includes(lowerCase)
            }
        })
        setFilteredContacts(filteredContacts)

    }
    const searchUsers = (e) => {
        console.log('searchContacts')
        console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredUsers = usersDefault.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.displayName.toLowerCase().includes(lowerCase)
            }
        })
        setFilteredUsers(filteredUsers)

    }

    const setContactsFunc = () => {
        const notContactsArray = []
        const contactsArray = []
        users.forEach(user => {
            if(manager.contacts && manager.contacts.find(e => e == user.id) !== undefined){
                contactsArray.push(user)
            }
            else {
                notContactsArray.push(user)
            }
            
        }); 
        setUsersDefault(notContactsArray) 
        setFilteredUsers(notContactsArray)
        setContactsDefault(contactsArray)    
        setFilteredContacts(contactsArray)    
    }

    useEffect(()=>{
        setContactsFunc()
    }, [manager])
    // console.log(buildings.map(building=>building.id))
    const calendarStrings = {
        lastDay : '[Gestern ] HH:MM',
        sameDay : '[Heute ] HH:mm',
        nextDay : '[Morgen um] LT',
        lastWeek : '[Letzten] dddd [um] HH:MM',
        nextWeek : 'dddd [um] LT',
        sameElse : 'L [um] LT'
      };

    return(
        <>
        <Form>
            <Row>
                <Col lg={'6'} className={'mb-3'}>
                    {
                        
                    manager.name?
                        <>
                            Name
                            <h5>{manager.name}</h5>
                        </>
                        :
                        null
                    }
                </Col>
                <Col lg={'6'} className={'mb-3'}>
                    Key<br/>{manager.id}
                </Col>
                <Col lg={'6'} className={'mb-3'}>
                    <Row>
                        <Col lg={'5'}>Erstellt am:</Col>
                        <Col lg={'7'}><Moment locale="de" calendar={calendarStrings}>{manager.created.toDate()}</Moment></Col>
                        {
                            manager.userId && users?
                            <>
                                <Col lg={'5'}>Erstellt von:</Col>
                                <Col lg={'7'}>{users.find((el) => el.id == manager.userId)?users.find((el) => el.id == manager.userId).displayName:auth.currentUser.displayName}</Col>
                            </>
                            :
                            null
                        }
                        {
                            buildings?
                            <>
                                <Col lg={'5'}>Tickets:</Col>
                                <Col lg={'7'}><Link to={"tickets/"+manager.id}><Badge>{tickets.filter(ticket=>buildings.map(building=>building.id).includes(ticket.buildingId)).length}</Badge></Link></Col>
                            </>
                            :
                            null
                        }
                        
                        {/* <Col lg={'5'}>Erstellt von:</Col>
                        <Col lg={'7'}>{users.filter(el => {return el.id === manager.userId}).displayName}</Col> */}
                    </Row>
                </Col>
                <Col lg={'6'} className={'mb-3'}>
                    
                    <Tabs
                    defaultActiveKey="contacts"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                    justify
                    >
                    <Tab eventKey="contacts" title="Kontakte">
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
                    
                        {manager.contacts?
                            <>
                                {/* <Form.Label>Kontakte</Form.Label> */}
                                <Form.Control type="text" onChange={searchContacts} placeholder="Suchen..."></Form.Control>
                                <ListGroup className="mt-3">
                                    <div className="listMaxStyle">
                                    {
                                        filteredContacts.map((contact)=>
                                            <ListGroup.Item className="borderListMax" >
                                                <Row>
                                                    <Col xs={8}>
                                                        <Link className={'linkStyleNone'} to={'/profil/'+contact.id}>
                                                            {contact.displayName}
                                                        </Link>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Button size="sm" variant="light" id={contact.id} onClick={()=>{handleShowChat(contact)}}>
                                                            <TbBrandMessenger/>
                                                            </Button>
                                                            {' '}
                                                        <Button size="sm" variant="danger" id={contact.id} onClick={()=>{deleteNewContact(contact.id)}}>
                                                            <TbUserX/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )
                                    }
                                    </div>
                                </ListGroup>
                            </>
                            :
                            <Form.Text className="text-muted">
                                Bisher sind keine Kontakt angelegt
                            </Form.Text>
                        }
                    {/* </Form.Group> */}
                    </Tab>
                    <Tab eventKey="users" title="User">
                        <>
                            {/* <Form.Label>Kontakte</Form.Label> */}
                            <Form.Control type="text" onChange={searchUsers} placeholder="Suchen..."></Form.Control>
                            <ListGroup className="mt-3">
                                <div className="listMaxStyle">
                                {
                                    filteredUsers.map((contact)=>
                                        <>
                                        <ListGroup.Item className="borderListMax" ><Row><Col xs={9}><Link className={'linkStyleNone'} to={'/profil/'+contact.id}>{contact.displayName}</Link></Col><Col xs={3}><Button size="sm" variant="light" id={contact.id} onClick={()=>{addNewContact(contact.id)}}><TbUserPlus/></Button></Col></Row></ListGroup.Item>
                                        
                                        </>
                                    )
                                }
                                </div>
                            </ListGroup>
                        </>
                    </Tab>
                    </Tabs>
                        
                </Col>
            </Row>
        </Form>
        <Chat show={showChat} chatPartner={chatId} handleClose={handleCloseChat}/>
        </>
    )
}