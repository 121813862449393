import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { collection, addDoc, serverTimestamp, updateDoc, getDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";
import uuid from 'react-uuid';

export function ModalCreateFloor({ show, handleClose, managerId, buildingId}) {
  const [name, setName] = useState('');
  const [address, setAddress] = useState(false);
  const [plan, setPlan] = useState('');
  const [planUrl, setPlanUrl] = useState(false);

  // console.log(managerId, 'and ', buildingId)
  async function formVal(e) {
    e.preventDefault();
    console.log(e);
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();

    // console.log(user.uid);
    if (name && plan) {
      // try {
      //   addDoc(collection(db, "floor"), {
      //     name: name,
      //     managerId: managerId,
      //     buildingId: buildingId,
      //     rules: { [user.uid]: "owner" },
      //     created: serverTimestamp()
      //   })
      //   // setManagerId(docRef.id);
      //   handleClose();
      //   setName('')
        
      // } catch (e) {
      //   console.error("Error adding document: ", e);
      // }
      try {
        const image = ref(storage, 'floor/'+uuid())
        uploadBytes(image, plan)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setPlanUrl(url);
              addDoc(collection(db, "floor"), {
                name: name,
                rules: { [user.uid]: "owner" },
                created: serverTimestamp(),
                buildingId: buildingId,
                managerId: managerId,
                planUrl: url
              })
              handleClose();
              setName('')
        
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
        
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Etage erstellen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }} />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridPlan">
              <Form.Label>Plan</Form.Label>
              <Form.Control
                type="file"
                placeholder="Enter Plan"
                // accept="image/png,image/jpeg"
                accept="image/svg+xml"
                onChange={(e) => {
                  setPlan(e.target.files[0]);
                }} />
            </Form.Group>
          </Row>
          <Button onClick={formVal} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
