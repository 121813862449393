// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { enableIndexedDbPersistence, initializeFirestore, CACHE_SIZE_UNLIMITED } from "firebase/firestore"; 
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJugFIRYochEROLFmPMAlmLQourIFPBbU",
  authDomain: "hexakt-92a98.firebaseapp.com",
  projectId: "hexakt-92a98",
  storageBucket: "hexakt-92a98.appspot.com",
  messagingSenderId: "246621194468",
  appId: "1:246621194468:web:5480bf5fed052c0ee144f1",
  measurementId: "G-QN7GGY17B7"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const firestoreDb = initializeFirestore(app, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED
});
export const analytics = getAnalytics(app);
export const db = getFirestore(app);

export const messaging = getMessaging(app);
export const functions = getFunctions(app, "europe-west3");
// connectFunctionsEmulator(functions, "localhost", 5001);

enableIndexedDbPersistence(db)
    .catch((err) => {
        if (err.code == 'failed-precondition') {
          // console.log('Multiple tabs open')
            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code == 'unimplemented') {
            // console.log('current browser')
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });
