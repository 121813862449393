import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebase";
import { getAuth } from "firebase/auth";

export function ModalCreateManager({ show, handleClose}) {
  const [name, setName] = useState('');
  const [address, setAddress] = useState(false);


  async function formVal(e) {
    e.preventDefault();
    console.log(e);
    const auth = getAuth();
    const user = auth.currentUser;

    // console.log(user.uid);
    if (name) {
      try {
        addDoc(collection(db, "management"), {
          name: name,
          rules: { [user.uid]: "owner" },
          created: serverTimestamp(),
          userId: user.uid
        })
        // setManagerId(docRef.id);
        handleClose();
        setName('')
        
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Verwaltung erstellen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3 mt-3">
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }} />
            </Form.Group>
          </Row>
          <Button onClick={formVal} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
