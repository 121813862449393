import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, doc, addDoc, serverTimestamp, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../firebase";

import Chat from "../tools/Chat"

// import { Typeahead } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import { TbUserPlus, TbUserX, TbBrandMessenger } from "react-icons/tb";
import { OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Badge} from "react-bootstrap";


export function Building({users, building, floors, tickets }){
    
    const [singleSelections, setSingleSelections] = useState([]);
    const [notContacts, setNotContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [usersDefault, setUsersDefault] = useState([]);
    const [contactsDefault, setContactsDefault] = useState([]);
    const [contacts, setContacts] = useState(building.contacts);
    const [inputText, setInputText] = useState("");
    const [showChat, setShowChat] = useState(false)
    const [chatId, setChatId] = useState(false)
    const handleCloseChat = () => {
        setShowChat(false)
        setChatId(false)
    }
    const handleShowChat = (id) => {
        console.log(id)
        setChatId(id)
        setShowChat(true)
    }


    const addNewContact = (id) => {
        const docRef = doc(db, "building", building.id)
        updateDoc(docRef, {
            contacts: arrayUnion(id)
        })
        console.log(id)
    }
    const deleteNewContact = (id) => {
        const docRef = doc(db, "building", building.id)
        updateDoc(docRef, {
            contacts: arrayRemove(id)
        })
        console.log(id)
    }
    const searchContacts = (e) => {
        console.log('searchContacts')
        console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredContacts = contactsDefault.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.displayName.toLowerCase().includes(lowerCase)
            }
        })
        setFilteredContacts(filteredContacts)

    }
    const searchUsers = (e) => {
        console.log('searchContacts')
        console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredUsers = usersDefault.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.displayName.toLowerCase().includes(lowerCase)
            }
        })
        setFilteredUsers(filteredUsers)

    }

    const setContactsFunc = () => {
        const notContactsArray = []
        const contactsArray = []
        users.forEach(user => {
            if(building.contacts && building.contacts.find(e => e == user.id) !== undefined){
                contactsArray.push(user)
            }
            else {
                notContactsArray.push(user)
            }
            
        }); 
        setUsersDefault(notContactsArray) 
        setFilteredUsers(notContactsArray)
        setContactsDefault(contactsArray)    
        setFilteredContacts(contactsArray)    
    }

    useEffect(()=>{
        setContactsFunc()
    }, [building])

    const calendarStrings = {
        lastDay : '[Gestern ] HH:MM',
        sameDay : '[Heute ] HH:mm',
        nextDay : '[Morgen um] LT',
        lastWeek : '[Letzten] dddd [um] HH:MM',
        nextWeek : 'dddd [um] LT',
        sameElse : 'L [um] LT'
      };

    return(
        <>
        <Form>
            <Row>
                <Col lg={'6'} className={'mb-3'}>
                    {
                        
                        building.name?
                        <>
                            Name
                            <h5>{building.name}</h5>
                        </>
                        :
                        <>
                            Key
                            <h5>{building.id}</h5>
                        </>
                    }
                </Col>
                <Col lg={'2'} className={'mb-3'}>
                    Tickets<br/><Link to={"tickets/"+building.managerId+'/'+building.id}><Badge>{tickets.filter(ticket=>ticket.buildingId==building.id).length}</Badge></Link>
                </Col>
                <Col lg={'4'} className={'mb-3'}>
                    Gebäude<br/><Link to={"GetIfc/"+building.id}><Badge>Modell</Badge></Link>
                </Col>
                {/* <Col lg={'4'} className={'mb-3'}>
                    <Row>
                        <Col lg={'12'}>Erstellt am:</Col>
                        <Col lg={'12'}><Moment locale="de" calendar={calendarStrings}>{building.created.toDate()}</Moment></Col>
                        <Col lg={'12'}>Adresse:</Col>
                        <Col lg={'12'}><a href={'https://www.google.com/maps/dir/?api=1&destination=QVB&destination_place_id='+building.addressId+'&travelmode=car'}>{building.address}</a></Col>
                    </Row>
                </Col> */}
                <Col lg={'12'} className={'mb-3'}>
                {building.addressId?
                    <iframe
                        width="100%"
                        height="350"
                        style={{borderRadius:'10px'}}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        // src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&query_place_id='+building.addressId}
                        src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&q=place_id:"+building.addressId}
                    >
                    </iframe>
                    :null
                    }
                    {/* <Tabs
                    defaultActiveKey="contacts"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="contacts" title="Kontakte">
                        {building.contacts?
                            <>
                                <Form.Label>Kontakte</Form.Label>
                                <Form.Control type="text" onChange={searchContacts} placeholder="Suchen..."></Form.Control>
                                <ListGroup className="mt-3">
                                    <div className="listMaxStyle">
                                    {
                                        filteredContacts.map((contact)=>
                                            <ListGroup.Item className="borderListMax" >
                                                <Row>
                                                    <Col xs={8}>
                                                        <Link className={'linkStyleNone'} to={'/profil/'+contact.id}>
                                                            {contact.displayName}
                                                        </Link>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <Button size="sm" variant="light" id={contact.id} onClick={()=>{handleShowChat(contact)}}>
                                                            <TbBrandMessenger/>
                                                            </Button>
                                                            {' '}
                                                        <Button size="sm" variant="danger" id={contact.id} onClick={()=>{deleteNewContact(contact.id)}}>
                                                            <TbUserX/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )
                                    }
                                    </div>
                                </ListGroup>
                            </>
                            :
                            <Form.Text className="text-muted">
                                Bisher sind keine Kontakt angelegt
                            </Form.Text>
                        }
                    </Tab>
                    <Tab eventKey="users" title="User">
                        <>
                            <Form.Label>Kontakte</Form.Label>
                            <Form.Control type="text" onChange={searchUsers} placeholder="Suchen..."></Form.Control>
                            <ListGroup className="mt-3">
                                <div className="listMaxStyle">
                                {
                                    filteredUsers.map((contact)=>
                                        <>
                                        <ListGroup.Item className="borderListMax" ><Row><Col xs={9}><Link className={'linkStyleNone'} to={'/profil/'+contact.id}>{contact.displayName}</Link></Col><Col xs={3}><Button size="sm" variant="light" id={contact.id} onClick={()=>{addNewContact(contact.id)}}><TbUserPlus/></Button></Col></Row></ListGroup.Item>
                                        
                                        </>
                                    )
                                }
                                </div>
                            </ListGroup>
                        </>
                    </Tab>
                    </Tabs> */}
                        
                </Col>
            </Row>
        </Form>
        <Chat show={showChat} chatPartner={chatId} handleClose={handleCloseChat}/>
        </>
    )
}