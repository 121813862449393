import React from "react";
import { useState, useEffect, useRef } from 'react';
import { messaging, auth, db  } from "../firebase";
import {increment, doc, addDoc, getDoc, updateDoc, query, onSnapshot, collection, getDocs, where, and, or, serverTimestamp, orderBy} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Moment from 'react-moment';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
  MDBBtn,
//   MDBScrollbar,
} from "mdb-react-ui-kit";
import '../chat.css'
import { FaPaperPlane, FaPaperclip, FaFileAlt } from 'react-icons/fa';
import uuid from 'react-uuid';

import { OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Modal } from "react-bootstrap";

export default function Chat({show, handleClose, chatPartner}){

  const scrollRef = useRef(null)
  const messageEl = useRef(null);
  const messagesEndRef = useRef(null)

  const [chatId, setChatId] = useState(false)
  const [messages, setMessages] = useState(false)


  const [inputMessage, setinputMessage] = useState('')
  const [newMessage, setNewMessage] = useState([])
  const [fileUploadState, setFileUploadState] = useState([])
  // const [scrolled, setScrolled] = useState(null)
  const inputReference = React.createRef()

  const calendarStrings = {
    lastDay : '[Gestern ] HH:MM',
    sameDay : '[Heute ] HH:mm',
    nextDay : '[Morgen um] LT',
    lastWeek : '[Letzten] dddd [um] HH:MM',
    nextWeek : 'dddd [um] LT',
    sameElse : 'L [um] LT'
  };
  useEffect(() => {  
    setTimeout(()=>{
      scrollToBottom()
    }, 380)
  },[chatPartner]);
  useEffect(() => {  
    setTimeout(()=>{
      scrollToBottom()
    }, 380)
  },[messages]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  }
  // console.log(chatPartner)

  async function getData(){
    const q = query(collection(db, "chat"), or(where("users", "==", [auth.currentUser.uid, chatPartner.id]), where("users", "==", [chatPartner.id, auth.currentUser.uid])));
    // , where("capital", "==", true)
  
    const querySnapshot = await getDocs(q);
    // console.log(querySnapshot.exists())
    var chatIdNew = ''
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      chatIdNew = doc.id
    });
    if(chatIdNew){
      setChatId(chatIdNew)
    }
    else {
      const test = await addDoc(collection(db, "chat"), {
        
        users: [auth.currentUser.uid, chatPartner.id],
        created: serverTimestamp()
      })
      setChatId(test.id)
    }
  }
  async function getMessages(){
    const q = query(collection(db, "chat/"+chatId+"/messages"), orderBy("created", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({...doc.data(), id: doc.id});
        // if(doc.data().userId == chatPartner.id){
        //   messages.push({text: doc.data().body, id: doc.id, position: "left", type: 'text'});
        // }
        // else {
        //   messages.push({text: doc.data().body, id: doc.id, position: "right", type: 'text'});
        // }
        
      });
      setMessages(messages.reverse())
      // console.log("Current cities in CA: ", messages.join(", "));
    });
    // setTimeout(()=>{
    //   scrollToBottom()
    // }, 180)
  }

  useEffect(()=>{
    if(chatPartner){
      getData()
      resetCounter()
    }  
  }, [chatPartner])
  useEffect(()=>{
    if(chatId){
      getMessages()
    }  
  }, [chatId])

  function showTime({id}) {
    
    var target = document.getElementById('moment'+this.id)
    if (target.classList.contains('messageWrapper')){
      target.classList.remove('messageWrapper')
    }
    else {
      target.classList.add('messageWrapper')
    }
    

  }
  async function sendMessage(){
    if(fileUploadState.length > 0){
      const storage = getStorage();
      const name = inputReference.current.files[0].name;
      try {
        const image = ref(storage, 'messages/'+uuid())
        uploadBytes(image, inputReference.current.files[0])
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then(async (url) => {
              const docRef = await addDoc(collection(db, 'chat/'+chatId+'/messages'), {
                body: inputMessage,
                userId: auth.currentUser.uid,
                created: serverTimestamp(),
                url: url,   
                urlName: name,
              });
              
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
        
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      setFileUploadState([])
      inputReference.current.value = ''
    }
    else {

      const docRef = await addDoc(collection(db, 'chat/'+chatId+'/messages'), {
        body: inputMessage,
        userId: auth.currentUser.uid,
        created: serverTimestamp(),      
      });
    }
    scrollToBottom()
    setinputMessage('')
  }
  const fileUploadAction = () => inputReference.current.click();
  const fileUploadInputChange = (e) => {setFileUploadState(e.target.value)};

  const resetCounter = async () => {
    const docRef = doc(db, "user/"+auth.currentUser.uid+'/messages', 'chat_'+chatPartner.id)
    const docSnap = await getDoc(docRef)
    console.log('test')
    if(docSnap.exists()){
      const oldCountValue = docSnap.data().count
      updateDoc(docRef, {
          count: 0,
      })
      updateDoc(doc(db, "user", auth.currentUser.uid), {messageCount: increment(-oldCountValue)})
    }
  }

    return(
      <Modal show={show}  onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Chat mit {chatPartner.displayName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MDBContainer fluid className="" style={{ backgroundColor: "#fff" }}>
            <div className="ChatBox" ref={messageEl}>
              <MDBCardBody>
                {messages?
                    messages.map((element, idx)=>
                    element.userId != auth.currentUser.uid?
                    <div className="d-flex flex-row justify-content-start">
                    <div>
                      {
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] != 'pdf' ?
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3 mouseFinger"
                        style={{ backgroundColor: "#f5f6f7", width: "90%"}}
                        onClick={showTime.bind({id: idx})}
                      >
                        <img src={element.url}
                        style={{ width: "100%", height: "100%" }}
                        />
                      </p>
                      :null
                      }
                      { 
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] == 'pdf'?
                      <p
                      className="small p-2 ms-3 mb-1 rounded-3 mouseFinger"
                      style={{ backgroundColor: "#f5f6f7", width: "90%"}}
                    >
                      
                      <a className="btn btn-secondary" target='_blank' href={element.url} download={element.urlName}>{element.urlName}</a>
                    </p>
                      :null
                      }

                      <div
                        className="small p-2 ms-3 mb-1 rounded-3 mouseFinger"
                        style={{backgroundColor: '#f5f6f7', width: "90%"}}
                        onClick={showTime.bind({id: idx})}
                      >
                      {/* {
                        activeContact?
                          <div style={{color: activeContact.color}} className="divWrappMessage">{activeContact.displayName}</div>
                          :
                          <div style={{color: ''}} className="divWrappMessage">{element.userId}</div>
                    
                      } */}
                        {element.body}
                        
                      </div>
                      <p id={'moment'+idx} className="mb-7 small ms-3 mb-1 rounded-3 text-muted messageWrapper">
                        <Moment calendar={calendarStrings}>{element.created.toDate()}</Moment>
                      </p>
                    </div>
                  </div>
                  :                
                  <div className="d-flex flex-row justify-content-end pt-1"
                    >
                    <div>
                      {
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] != 'pdf'?
                        <p
                          className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary mouseFinger"
                          onClick={showTime.bind({id: idx})}
                        >
                          <img src={element.url}
                          style={{ width: "100%", height: "100%" }}
                          />
                        </p>
                      :null
                      }
                      { 
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] == 'pdf'?
                      <p
                      className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary mouseFinger"
                    >
                      
                      <a className="btn btn-secondary" target='_blank' href={element.url} download={element.urlName}>{element.urlName}</a>
                    </p>
                      :null
                      }
                      <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary mouseFinger"
                      onClick={showTime.bind({id: idx})}
                      
                      >
                      {element.body}
                      </p>
                      
                      <p id={'moment'+idx} className="small me-3 mb-1 rounded-3 text-muted d-flex justify-content-end messageWrapper">
                      {element.created?
                      <Moment calendar={calendarStrings}>{element.created.toDate()}</Moment>
                      :null
                      }
                      </p>
                    </div>
                  </div>
                    )
                  :null
                }
                <div ref={messagesEndRef} />
              </MDBCardBody>
              </div>
            <hr/>
            <MDBCardFooter className="text-muted d-flex justify-content-start align-items-center">
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Type message"
                value={inputMessage}
                onChange={(e) => {
                  const value = e.target.value
                  setinputMessage(value)
                }}
                onKeyDown={(e)=>{
                  
                  if(e.key === 'Enter'){
                    sendMessage()
                    e.preventDefault();
                  }
                }}
              ></input>
              <a className="ms-1 text-muted" href="#!">
                
              </a>
              <a className="ms-3 text-muted" onClick={fileUploadAction}>
                
                {
                fileUploadState.length > 0?
                <FaFileAlt/>:<FaPaperclip/>
                
                }
              </a>
              <input type="file" hidden ref={inputReference} onChange={fileUploadInputChange} accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
              <a className="ms-3" onClick={sendMessage}>
                <FaPaperPlane />
              </a>
            </MDBCardFooter>
            </MDBContainer>
        </Modal.Body>
      </Modal>
    )
}