import React, { useState, useEffect, Component } from "react";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {Link} from 'react-router-dom';
import { messaging, auth, db, functions } from "../firebase";
import { doc, getDoc, setDoc, updateDoc, query, onSnapshot, collection, arrayUnion, arrayRemove, increment } from "firebase/firestore";

function ToastAlert({onMessage, messaging, selectedType, user}) {
    const [position, setPosition] = useState('top-end');
    const [showA, setShowA] = useState(false);
    const [name, setName] = useState(true);
    const [text, setText] = useState(true);
    const [url, setUrl] = useState(false);
    const toggleShowA = () => setShowA(!showA);

    

    onMessage(messaging, (payload) => {
        if(window.location.pathname.split('/')[5] == payload.data.click_action.split('/')[5] && window.location.pathname.split('/')[1] == payload.data.click_action.split('/')[1] && (window.location.pathname.split('/')[6] =='chat' || selectedType == 'chat')){
            resetCounter({ticket: window.location.pathname.split('/')[5]})
        }
        else {
        console.log("Message received. ", payload);
        setName(payload.notification.title)
        setText(payload.notification.body)
        setUrl(payload.data.click_action)
        setShowA(true)
        }
    });

    const resetCounter = async({ticket}) => {
        const docRef = doc(db, "user/"+user.id+'/messages', 'ticket_'+ticket)
        const docSnap = await getDoc(docRef)
        console.log('test')
        if(docSnap.exists()){
            const oldCountValue = docSnap.data().count
            updateDoc(docRef, {
                count: 0,
            })
            updateDoc(doc(db, "user", user.id), {messageCount: increment(-oldCountValue)})
        }
    }


    return (
        
        <ToastContainer position={position} className="m-3">
            <Link to={url} className="btn-link" onClick={toggleShowA}>
        {/* <Toast>
            <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">Bootstrap</strong>
            <small className="text-muted">just now</small>
            </Toast.Header>
            <Toast.Body>See? Just like this.</Toast.Body>
        </Toast> */}
        <Toast autohide='true' show={showA} onClose={toggleShowA}>
            <Toast.Header>
            {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
            <strong className="me-auto">{name}</strong>
            {/* <small className="text-muted">2 seconds ago</small> */}
            </Toast.Header>
            <Toast.Body>{text}</Toast.Body>
        </Toast>
        </Link>
        </ToastContainer>
    );
}

export default ToastAlert;