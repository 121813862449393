import React from 'react';
import { useState, useEffect } from 'react';
import { messaging, auth, db, functions } from "../firebase";
import { doc, getDocs, where,  getDoc, setDoc, updateDoc, query, onSnapshot, collection, arrayUnion, arrayRemove, increment, documentId } from "firebase/firestore";
import { Chart } from "react-google-charts";

export function Gantt({selectedTicket}){

  const [data2, setData2] = useState(false)
  
  const getBefore = async ({beforeIds}) => {
    
    console.log(beforeIds)
    const querySnapshot = await getDocs(query(collection(db, "ticket"), where(documentId(), "in", beforeIds)));
    const array = []
    var array2 = []
    querySnapshot.forEach((doc) => {
      array.push({...doc.data(), id: doc.id})
      var thisAfter = null
      var startDate = new Date(new Date(doc.data().date).setDate(new Date(doc.data().date).getDate()-1))
      if (doc.data().dateStart){
        startDate = new Date(doc.data().dateStart)
      }
      console.log(doc.data().before)
      if(doc.data().before){
        thisAfter = String(doc.data().before).replace('[', '').replace(']', '')
      }
      array2.push([
        doc.id,
        doc.data().name,
        doc.data().status,
        
        startDate,
        // doc.data().date,
        new Date(doc.data().date),
        // 5 * 15 * 100000,
        null,
        100,
        thisAfter,
        // null,
      ])
    });
    return [array, array2]
    
  }
  const checkNew = ({before, arrayMain}) => {
    const checkNew = []
    before.forEach((ticket)=>{
      
      if(!arrayMain.includes(ticket)){
        checkNew.push(ticket)
      }
    })
    return checkNew
  }
  const testDatas = async ({before}) => {
    var array = []
    
    await before.forEach((ticket)=>{ 
      console.log('testTicketArray')
      console.log(ticket)

      if(ticket.before){
        console.log('concat')
        ticket.before.forEach((ticket)=>{
          array.push(ticket)
          
        })
        // array.concat(ticket.before)
        console.log(array)
      }
    })
    
    return array
  }

  const getData = async () => {
    var ticketIds = selectedTicket.before
    var dataArray = [selectedTicket]
    var thisAfter = null
    var startDate = new Date(new Date(selectedTicket.date).setDate(new Date(selectedTicket.date).getDate()-1))
    if(selectedTicket.dateStart){
      startDate = new Date(selectedTicket.dateStart)
    }
    if(selectedTicket.before){
      thisAfter = String(selectedTicket.before).replace('[', '').replace(']', '')
    }
    var dataArray2 = [[
      selectedTicket.id,
      selectedTicket.name,
      selectedTicket.status,
      // new Date(selectedTicket.date)-(24*60*60*1000) * 5,
      // new Date(selectedTicket.date),
      startDate,
      new Date(selectedTicket.date),
      // 5 * 15 * 100000,
      null,
      100,
      thisAfter,
    ]]
    var before = ''
    var newArray = ''
    var test = 0
    for (let index = 0; index != 1;) {
      // const element = array[index];
      before = await getBefore({beforeIds: ticketIds})
      console.log(before)
      dataArray = dataArray.concat(before[0])
      dataArray2 = dataArray2.concat(before[1])
      newArray = await testDatas({before: before[0]})
      // console.log(array)
      if(newArray.length >0){
        console.log(newArray)
        ticketIds = newArray
      }
      else {
        setData2(dataArray2)
        console.log(dataArray)
        console.log(dataArray2)
        index = 1
      }  
    }
    // var before = []
    // const arrayMain = []
    // if(!data){
    //   before = await getBefore({beforeIds: selectedTicket.before})
    //   // console.log(selectedTicket.before.lenght)
    // }
    // else{
    //   before = await getBefore()
    // }
    // const result = checkNew({before: before, arrayMain: arrayMain}) 
    // console.log(result)
  }

  useEffect(()=>{
    getData()
    
  },[])

  
    if(data2){
      const columns = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
      ];
      
      const rows = [
        // [
        //   "toTrain",
        //   "Walk to trainh",
        //   "walk",
        //   null,
        //   null,
        //   5 * 60 * 1000,
        //   100,
        //   null,
        // ],
        ["music", "Listen to music", "music", null, null, 70 * 60 * 1000, 100, null],
        // ["music2", "Listen to music2", "music", null, null, 70 * 60 * 1000, 100, null],
        [
          "wait",
          "Wait for train",
          "music",
          null,
          null,
          10 * 60 * 1000,
          100,
          null,
        ],
        ["train", "Train ride", "music", null, null, 45 * 60 * 10000, 75, "wait"],
        ["train2", "Train ride2", "music", null, null, 45 * 30 * 1000, 50, "wait"],
        ["toWork", "Walk to work", "music", null, null, 10 * 60 * 1000, 0, "train, train2"],
        ["toWork2", "Walk to work", "music", null, null, 10 * 60 * 1000, 0, "train, train2"],
        ["toWork3", "Walk to work", "music", null, null, 10 * 60 * 1000, 0, "train, train2"],
        ["toWork4", "Walk to work", "music", null, null, 10 * 60 * 1000, 0, "train, train2"],
        ["toWork5", "Walk to work", "music", null, null, 10 * 60 * 1000, 0, "train, train2"],
        ["toWork6", "Walk to work", "music", null, null, 10 * 60 * 1000, 0, "train, train2"],
        ["work", "Sit down at desk", "music", null, null, 2 * 60 * 1000, 0, "toWork"],
      ];
      
      const data123 = [columns, ...data2];
      
      const options123 = {
        
        // height: (data123.length + 1) * 42,
        height: '500px',
        // width: 100,
        overflow: 'scroll',
        backgroundColor:{
            fill: '#f8f9fa'
        },
        // chart: {
        //     areaOpacity: 0.2
        //    },
        gantt: {
            percentEnabled: false,
            trackHeight: 35,
            defaultStartDateMillis: new Date(2015, 3, 28),
            criticalPathStyle: {
                stroke: '#e64a19',
                strokeWidth: 1.5,
            },
            arrow: {
                angle: 70,
                width: 1,
                color: 'black',
                radius: 5,
                spaceAfter: 2
            },
            innerGridTrack: {fill: 'white'},
            innerGridDarkTrack: {fill: '#f8f9fa'}
  
        },
      };
      return(
        // <div>
        //   {data?
        //   data.map((id, idx)=>
        //     <p>{id.name}</p>
        //   )
        //   :
        //   null
        //   }
        // </div>
        <Chart
            className='chart_div_gantt'
            chartType="Gantt"
            width="100%"
            // height="100vh"
            height={(data123.length + 1) * 35}
            data={data123}
            options={options123}
            legendToggle
            chartLanguage={'de'}
        />
    )
    }
}