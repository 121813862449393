import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import { auth } from "../firebase";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';




const PasswordReset = ({setUser}) => {

    const [email, setEmail] = useState('');
    const [reset, setReset] = useState(false);
    // const [password, setPassword] = useState('');

    const OnLogin = (e) => {
        e.preventDefault();
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
        .then(() => {
            setReset(true)
            // Password reset email sent!
            // ..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });
    }
     
    if(!reset){
        return (
            <div>
                <Container>
                    <Row className=" mt-3 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        {/* <div className="border border-3 border-primary"></div> */}
                        <Card className="shadow">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                            <h2 className="fw-bold mb-2 text-uppercase ">Hexakt</h2>
                            <p className=" mb-5">Please enter your email!</p>
                            <div className="mb-3">
                                <Form onSubmit={OnLogin}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="text-center">
                                    Email address
                                    </Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                                </Form.Group>

                                <div className="d-grid">
                                    <Button variant="primary" type="submit">
                                    Reset Password
                                    </Button>
                                </div>
                                </Form>
                                {/* <div className="mt-3">
                                <p className="mb-0  text-center">
                                    Don't have an account?{" "}
                                    <a href="{''}" className="text-primary fw-bold">
                                    Sign Up
                                    </a>
                                </p>
                                </div> */}
                            </div>
                            </div>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }
    else {
        return(
            <div>
                <Container>
                    <Row className=" mt-3 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        {/* <div className="border border-3 border-primary"></div> */}
                        <Card className="shadow">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                            <h2 className="fw-bold mb-2 text-uppercase ">Hexakt</h2>
                            <p className=" mb-5">Check your Emails</p>
                            <p className="small">
                                <Link to={'/'}>
                                    Login
                                </Link>
                            
                            </p>
                            </div>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
export default PasswordReset