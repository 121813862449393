import { useState} from 'react';
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword} from 'firebase/auth';
import { doc, setDoc, serverTimestamp, getDoc, updateDoc } from "firebase/firestore"; 
import { Col, Button, Row, Container, Card, Form, InputGroup } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";


import 'react-toastify/dist/ReactToastify.css';




const Regist = ({setUser}) => {
    var randomColor = require('randomcolor');
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [inUse, setInUse] = useState(false);
    const [weakPas, setWeakPas] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState("password")
    // let { id, displayName, emailSendTO } = useParams();
    // if(emailSendTO){
    //   setEmail(emailSendTO)
    // }
    const onLogin = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
        } else {
          await createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
              const user = userCredential.user.auth;
              setUser(user)
              const docRef = doc(db, "user", user.currentUser.uid);
              const docSnap = await getDoc(docRef);

              if (docSnap.exists()) {
                const userProfil = await updateDoc(docRef, {
                  displayName: name,
                  email: email,
                  created: serverTimestamp(),
                  color: randomColor(),
                  firstName: '',
                  lastName: '',
                  emailNotification: true,
                  emailChat: true,
                  emailMerge: true,
                  pushNotification: true,
                  pushChat: true,
                  pushMerge: true,
                });
              } else {
                
              
              const userProfil = await setDoc(docRef, {
                displayName: name,
                email: email,
                created: serverTimestamp(),
                color: randomColor(),
                firstName: '',
                lastName: '',
                emailNotification: true,
                emailChat: true,
                emailMerge: true,
                pushNotification: true,
                pushChat: true,
                pushMerge: true,
              });
              console.log(userProfil)
            }

          })
          .catch((error) => {
              console.log(error.code)
              if(error.code === 'auth/email-already-in-use'){
                setInUse(true)
              }
              else {
                setInUse(false)
              }
              if(error.code === 'auth/weak-password'){
                setWeakPas(true)
              } else {
                setWeakPas(false)
              }
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode, errorMessage);
          });
        }
        setValidated(true);
    }
    const handleVis = () => {
      if(passwordVisible === "password"){
          setPasswordVisible('text')
      }
      else {
          setPasswordVisible('password')
      }
    }
    return (
        <div>
          <Container>
            <Row className=" mt-3 d-flex justify-content-center align-items-center">
              <Col md={8} lg={6} xs={12}>
                <Card className="shadow">
                  <Card.Body>
                    <div className="mb-3 mt-md-4">
                      <h2 className="fw-bold mb-2 text-uppercase ">Hexakt</h2>
                      <p className=" mb-5">Please enter your login and password!</p>
                      <div className="mb-3">
                        <Form validated={validated} noValidate onSubmit={onLogin}>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">
                              Email address
                            </Form.Label>
                            {/* {emailSendTO?
                            <Form.Control required disabled type="email" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                            : */}
                            <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>  
                            {/* } */}
                            
                            {
                              inUse?

                              <Form.Control.Feedback style={{color: 'red'}}>
                                Nutzen Sie eine andere Email-Adresse, oder melden sie sich an.
                              </Form.Control.Feedback>
                              :
                              null
                            }
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="text-center">
                              Benutzername
                            </Form.Label>
                            <Form.Control required type="text" placeholder="Enter Name" value={name} onChange={(e)=>{setName(e.target.value)}}/>
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control required type={passwordVisible} placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                              <Button variant="outline-secondary" id="basic-addon1" onClick={handleVis}>
                                  {passwordVisible === 'password'?
                                    <MdVisibility/>
                                    :
                                    <MdVisibilityOff/>
                                  }
                              </Button>
                              {
                              weakPas?
                                <Form.Control.Feedback style={{color: 'red'}}>
                                  Nutzen Sie eine stärkeres Passwort.
                                </Form.Control.Feedback>
                                :
                                null
                              }
                            </InputGroup>
                            

                              
                              
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                          </Form.Group>
                          
                          <div className="d-grid">
                            <Button variant="primary" type="submit">
                              Registrieren
                            </Button>
                          </div>
                        </Form>
                        <div className="mt-3">
                      <p className="mb-0  text-center">
                        Bereits einen Account?{" "}
                        <Link className='text-primary fw-bold' to={'/'}>
                            Anmelden
                        </Link>
                        
                      </p>
                    </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    )
}
export default Regist