import React from 'react';
import { useState, useEffect } from 'react';
import { messaging, auth, db, functions } from "../firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { httpsCallable } from "firebase/functions";
import { serverTimestamp, doc, where, getDoc, setDoc, updateDoc, query, onSnapshot, collection, arrayUnion, arrayRemove, increment } from "firebase/firestore";
import Moment from 'react-moment';
import { useParams, Link, useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Badge, Placeholder } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { divIcon, latLng } from 'leaflet';
import L from 'leaflet';
import Ticket from './Ticket';
import Control from 'react-leaflet-custom-control'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Accordion from 'react-bootstrap/Accordion';
import ChatTicket from '../tools/ChatTicket';
import { Gantt } from '../tools/Gantt';
import { ModalCreateUser } from '../modals/ModalCreateUser';
import { MapContainer, TileLayer,ImageOverlay, SVGOverlay, useMap, Marker, Popup, useMapEvents, LayersControl, LayerGroup, useMapEvent, Rectangle} from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MdArrowBackIosNew, MdKeyboardBackspace, MdNotifications, MdNotificationsOff} from "react-icons/md";
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { notification } from 'antd';
import { TbBorderCorners, TbMap2, TbPlugConnected, TbListDetails, TbMessages, TbSettings, TbTruck } from "react-icons/tb";
import { AiOutlineUserAdd } from "react-icons/ai";
import { Document, Page, pdfjs } from 'react-pdf';
import PricingAnnouncement from '../tools/pricingAnnouncement';
import AnnouncementDetails from '../tools/AnnouncementDetails';

// import Stripe from 'stripe';

export default function Tickets({tickets, floors, skills, users, user, notifications, selectedType, setSelectedType}){
    const [visTickets, setVisTickets] = useState(tickets)
    const [selectedTicket, setSelectedTicket] = useState(false)
    
    const [addUser, setAddUser] = useState(false)
    const showAddUser = () => {setAddUser(true)}
    const closeAddUser = () => {setAddUser(false)}
    const [pricingA, setPricingA] = useState(false)
    const showPricingA = () => {setPricingA(true)}
    const closePricingA = () => {setPricingA(false)}
    const [details, setDetails] = useState(false)
    const showDetails = () => {setDetails(true)}
    const closeDetails = () => {setDetails(false)}


    const [floor, setFloor] = useState(false)
    const [note, setNote] = useState(false)
    const [visible, setVisible] = useState(false)
    const [responsible, setResponsible] = useState(false)
    const [date, setDate] = useState(false)
    const [dateStart, setDateStart] = useState(false)
    const [before, setBefore] = useState(false)
    const [after, setAfter] = useState(false)
    const [selectedType2, setSelectedType2] = useState(selectedType)
    const [prio, setPrio] = useState(false)
    const [mapCenter, setMapCenter] = useState([[10,150], [10000,400]])
    const [status, setStatus] = useState(false)
    const [ticketSkills, setTicketSkills] = useState(false)
    const [announcement, setAnnouncement] = useState(false)
    const [activeAnnouncement, setActiveAnnouncement] = useState(false)
    const [announcementArray, setAnnouncementArray] = useState(false)
    const [planUrl, setPlanUrl] = useState(false)
    const [building, setBuilding] = useState(false)
    const [defaultVisibleUsers, setDefaultVisibleUsers] = useState(false)
    let { managerId, buildingId, floorId, id, type } = useParams();
    let navigate = useNavigate();

    useEffect(()=>{
        // setVisTickets(tickets)
        if(tickets && users && floors){
            ticketFilter()
            // console.log('test2')
        }
        // ticketFilter()
        if(selectedTicket){
            setSelectedTicket(tickets.find(el => el.id == selectedTicket.id))
            setAnnouncement(tickets.find(el => el.id == selectedTicket.id).announcement)
        }
    },[tickets])
    useEffect(()=>{
        if(floors && selectedTicket){
            getImage()
        }
    },[floors, selectedTicket])
    // useEffect(()=>{
    //     if(floors && !planUrl){
            
    //         console.log(floors)
    //     }
    // },[floors])
    // useEffect(()=>{
    //     if(tickets && users && floors && !selectedTicket){
    //         ticketFilter()
    //         console.log('test1')
    //     }
        
    // },[tickets, users, floors])

    const getImage =  () => {
        
        const indexddb =  window.indexedDB.open("ImageData");
        indexddb.onsuccess = async function (e) {
            // console.log("success...");
            // const db12= e.target.result;
            const request = await e.target.result.transaction('friends')
                   .objectStore('friends')
                   .getAll();

            request.onsuccess = function(){
                
                const floorThis = floors.find(element=> element.id == selectedTicket.floorId)
                // console.log(floorThis)
                if(floorThis && request.result.find(el => el.name == floorThis.planUrl)){
                    const blob = new Blob( [ request.result.find(el => el.name == floorThis.planUrl).array ], {type: 'image/svg+xml'} );
                    const url = URL.createObjectURL( blob );
                    setPlanUrl(url)
                    // console.log(request.result.find(el => el.name == floorThis.planUrl))
                    // console.log(url)
                }
                else {
                    console.log('planFehlt')
                    setPlanUrl(floor.planUrl)
                }
            }

        };
        
    }

    const resetCounter = async ({ticket}) => {
        const docRef = doc(db, "user/"+user.uid+'/messages', 'ticket_'+ticket)
        const docSnap = await getDoc(docRef)
        // console.log('test')
        if(docSnap.exists()){
            const oldCountValue = docSnap.data().count
            updateDoc(docRef, {
                count: 0,
            })
            updateDoc(doc(db, "user", user.uid), {messageCount: increment(-oldCountValue)})
        }
    }
    const resetCounter2 = async ({ticket}) => {
        const docRef = doc(db, "user/"+user.uid+'/messages', 'tUpdate_'+ticket)
        const docSnap = await getDoc(docRef)
        // console.log('test')
        if(docSnap.exists()){
            const oldCountValue = docSnap.data().count
            updateDoc(docRef, {
                count: 0,
            })
            updateDoc(doc(db, "user", user.uid), {messageCount: increment(-oldCountValue)})
        }
    }
    const getAnnouncementArray = async () => {
        const q = query(collection(db, "announcement"), where("ticketId", "==", selectedTicket.id));
        
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
            const announcementArray2 = []
            querySnapshot.forEach((doc) => {
                announcementArray2.push({...doc.data(), id: doc.id})
            });
            if (announcementArray2.length > 0){
                // cons
                setAnnouncementArray(announcementArray2)
                console.log(announcementArray2)
            }
        })
    }
    useEffect(()=>{
        if(announcement && !announcementArray){
            getAnnouncementArray()
        }
    }, [announcement])

    const ticketFilter = () => {
        if(id){
            const ticket = tickets.find(ticket=>ticket.id==id)
            console.log(floors)
            setSelectedTicket(ticket)
            setAnnouncement(ticket.announcement)
            resetCounter2({ticket: id})
            if(type && !selectedTicket){
                setSelectedType2(type)
                
                if(type=='chat'){
                    resetCounter({ticket: id})
                }
            }
            var array = []
            ticket.visible.forEach(user=>{
                var visU = users.find(u => u.id == user)
                if(visU && visU.id != ticket.responsible){
                    array.push(visU)
                }
                
            })
            console.log(array)
            setDefaultVisibleUsers(array)

        }
        if(floorId && floorId != 'none'){
            setVisTickets(tickets.filter(ticket=>ticket.floorId==floorId))
        }
        else if(buildingId && buildingId != 'none'){
            setVisTickets(tickets.filter(ticket=>ticket.buildingId==buildingId))
        }
        else {
            setVisTickets(tickets)
        }
        // console.log('testfilter')
    }

    // const createStripCheckout = httpsCallable(functions, 'createStripCheckout2');
    // const stripe = window.Stripe('pk_test_51MzbGJI93xQXIeQzplDYa29WmDKu6bFjeBJ4bSsigcVFVKvVC4s32gUVXtx09YRuXYMSsjto79Kme0E1i2kIVIjR00uQOlSrfL');
    // const stripTest = ({amount, price}) => {
    //     console.log(amount)
    //     createStripCheckout({amount_pice: price, quantity: amount}).then(response => {
    //         const sessionId = response.data.id
    //         // console.log(response.data)
    //         stripe.redirectToCheckout({sessionId: sessionId})
    //     })
    // }
    

    const colorArray = ['none', 'secondary', 'info', 'warning', 'danger']
    const titelArray = ['none', 'Niedrig', 'Mittel', 'Hoch', 'Kritisch']
    const statusArray = [{id: 'created', name: 'Erstellt', color: 'primary'}, {id: 'In Progress', name: 'In Bearbeitung', color: 'warning'}, {id: 'finished', name: 'Fertig', color: 'success'}]
    const calendarStrings = {
        lastDay : '[Gestern ] HH:mm',
        sameDay : '[Heute ] HH:mm',
        nextDay : '[Morgen um] HH:mm',
        lastWeek : '[Letzten] dddd [um] HH:mm',
        nextWeek : 'dddd [um] HH:mm',
        sameElse : 'DD.MM.YY [um] HH:mm'
      };
    
    const searchTicket = (e) => {
        // console.log('searchContacts')
        // console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredContacts = tickets.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.name.toLowerCase().includes(lowerCase)
            }
        })
        setVisTickets(filteredContacts)

    }

    const selectTicket = (thisTicket) => {
        setDefaultVisibleUsers(false)
        setSelectedTicket(thisTicket)
        setAnnouncement(thisTicket.announcement)
        // console.log(thisTicket)
        // console.log(floors)
        
        var array = []
        thisTicket.visible.forEach(user=>{
            var visU = users.find(u => u.id == user)
            if(visU && visU.id != thisTicket.responsible){
                array.push(visU)
            }
            
        })
        console.log(array)
        setDefaultVisibleUsers(array)
        // const floor = floors.find((floor)=>{floor.id === thisTicket.floorId})
        // setFloor(floor)
    }
    
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: '',
        iconUrl: require('../roomklein.png'),
        shadowUrl: '',
        iconSize:     [20, 25],
        iconAnchor:   [11.4, 17],
        popupAnchor:  [0, -9.5],
    });

    const notifyMe = async () => {
        // console.log(user)
        await updateDoc(doc(db, "ticket", selectedTicket.id), {
            notifyMe: arrayUnion(user.uid)
        })
    }
    const notNotifyMe = async () => {
        await updateDoc(doc(db, "ticket", selectedTicket.id), {
            notifyMe: arrayRemove(user.uid)
        })
    }
    const safeSettings = async () => {
        var newData = {}
        if(responsible){
            newData['responsible'] = responsible[0].id
        }
        if(visible){
            var array = []
            await visible.forEach(element => {
                array.push(element.id)
            });
            array.push(selectedTicket.createdUid)
            if(responsible && !array.includes(responsible[0].id) && selectedTicket.responsible !=selectedTicket.createdUid){
                array.push(responsible[0].id)
            }
            else if (!responsible && !array.includes(selectedTicket.responsible && selectedTicket.responsible != selectedTicket.createdUid)){
                array.push(selectedTicket.responsible)
            }

            newData['visible'] = array
        }
        if(date){
            newData['date'] = date
        }
        if(dateStart){
            newData['dateStart'] = dateStart
        }
        if(note){
            newData['note'] = note
        }
        if(status){
            newData['status'] = statusArray.find(el => el.name==status).id
        }
        if(prio){
            newData['prio'] = titelArray.indexOf(prio)
        }
        if(before){
            // console.log(before.map(a => a.id))
            newData['before'] = before.map(a => a.id)
        }
        if(after){
            // console.log(before.map(a => a.id))
            newData['after'] = after.map(a => a.id)
        }
        if(ticketSkills){
            newData['skills'] = ticketSkills.map(a => a.id)
        }
        if(selectedTicket.announcement != announcement){
            setDetails(true)
            newData['announcement'] = announcement
            newData['jobId'] = false
        }
        await updateDoc(doc(db, "ticket", selectedTicket.id), newData)

        console.log(newData)
        // console.log(responsible[0].id)
        // await updateDoc(doc(db, "ticket", selectedTicket.id), {
            
        // })
    }
    const RecenterAutomatically = ({position}) => {
        const map = useMap();
         useEffect(() => {
           map.flyTo(position, 3)
        //    setView(position);
         }, [position]);
         return null;
       }
    
    const ticketJobPrice = (announcementId) => {
        console.log('test')
        console.log(announcementId)
        if(announcementArray.length >0){
            setActiveAnnouncement(announcementArray.find((el) => el.id == announcementId))
        }
        showPricingA()
        
    }
    const acceptJob = (announcementId) => {
        
        console.log(announcementId)
        const achtiveAnn = announcementArray.find((el) => el.id == announcementId)
        // const date = serverTimestamp()
        updateDoc(doc(db, 'announcement', announcementId), {
            accepted: true,
            acceptedDate: serverTimestamp(),
        })
        if(!selectedTicket.visible.find((el)=> el.id == achtiveAnn.createdUid)){
            updateDoc(doc(db, 'ticket', selectedTicket.id), {
                visible: arrayUnion(achtiveAnn.createdUid),
                notifyMe: arrayUnion(achtiveAnn.createdUid),
                responsible: achtiveAnn.createdUid,
                jobId: announcementId,
            })
        }
        else if(selectedTicket.responsible != achtiveAnn.createdUid){
            updateDoc(doc(db, 'ticket', selectedTicket.id), {
                // visible: arrayUnion(achtiveAnn.createdUid),
                // notifyMe: arrayUnion(achtiveAnn.createdUid),
                responsible: achtiveAnn.createdUid,
                jobId: announcementId,
            })
        }
        else {
            updateDoc(doc(db, 'ticket', selectedTicket.id), {
                // visible: arrayUnion(achtiveAnn.createdUid),
                // notifyMe: arrayUnion(achtiveAnn.createdUid),
                // responsible: achtiveAnn.createdUid,
                jobId: announcementId,
            })
        }
        
    }
    // if(tickets){

    // const pdfLoad = () => {
    //     const [numPages, setNumPages] = useState(null);
    //     const [pageNumber, setPageNumber] = useState(1);



    // }

        return (
            <Container>
                <Row>
                    <Col lg={'4'} md={{ order: 2 }} xs={{ order: 2 }}>
                        {
                            tickets?
                            <Card className='mt-3'>
                                <ListGroup defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}>
                                    
                                        <ListGroup.Item key='search' style={{height: '50px', padding: 0}}>
                                            {/* <Row>     */}
                                                <Col lg={6}>
                                                    <Form.Control onChange={searchTicket} placeholder='Ticket suchen' style={{border: '0px'}} />
                                                </Col>
                                                <Col lg={6}>
                                                    {/* <Form.Control onChange={searchTicket} placeholder='Ticket suchen' style={{border: '0px'}} /> */}
                                                    {/* <p>Filter</p> */}
                                                    
                                                </Col>
                                            {/* </Row> */}
                                        </ListGroup.Item>
                                    
                                    
                                    {
                                        visTickets?
                                        visTickets.map((ticket, idx)=>
                                        <ListGroup.Item  variant={notifications?notifications.find(notification => notification.id=='ticket_'+ticket.id) && notifications.find(notification => notification.id=='ticket_'+ticket.id).count > 0?'primary':null:null} as={Link} to={'/tickets/none/none/none/'+ticket.id} key={'#ticket_id='+idx} action onClick={()=>{setMapCenter([[10,150], [10000,400]]); selectTicket(ticket); setSelectedType2('map'); setPlanUrl(false); resetCounter2({ticket: ticket.id})}}>
                                            <Row>
                                                <Col lg={9}>
                                                    {ticket.name}
                                                    {notifications?
                                                    notifications.find(notification => notification.id=='ticket_'+ticket.id) && notifications.find(notification => notification.id=='ticket_'+ticket.id).count > 0?
                                                        <Badge pill>{notifications.find(notification => notification.id=='ticket_'+ticket.id).count}</Badge>
                                                        :
                                                        null
                                                        :null
                                                    }
                                                    <p style={{fontSize: '12px', marginBottom: 0}}>Bis <Moment locale="de" calendar={calendarStrings}>{ticket.date}</Moment> zu erledigen</p>
                                                </Col>
                                                <Col lg={3}>
                                                    <Badge pill bg={colorArray[ticket.prio]}>
                                                        {titelArray[ticket.prio]}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        )   
                                     :null   
                                    }
                                </ListGroup>
                            </Card>
                            :
                            <h4 className="mt-5">Bisher sind keine Tickets verfügbar</h4>
                        }
                        
                    </Col>
                    <Col lg={'8'} md={{ order: 2 }} xs={{ order: 1 }}>
                        {
                            selectedTicket?
                                    <Card className='mt-3' style={{minHeight: '80vh'}} bg="light">
                                        <Card.Body>
                                        <Row>
                                            <Col lg={'5'}>
                                                <button className='btn d-lg-none' style={{paddingLeft:0, paddingTop: 0}}><MdKeyboardBackspace size={24} onClick={()=>{setSelectedTicket(false)}}/></button>
                                                <h4>
                                                    {selectedTicket.name}{' '}
                                                    {
                                                        selectedTicket.notifyMe && selectedTicket.notifyMe.includes(user.uid)?
                                                        <button onClick={notNotifyMe} style={{padding: 0, color:'#0d6efd'}} className='btn'><MdNotifications  size={24}/></button>
                                                        :
                                                        <button onClick={notifyMe} style={{padding: 0}} className='btn'><MdNotificationsOff  size={24}/></button>
                                                    }
                                                </h4>
                                                <Form.Group key={'formGridDisplayName'} className="mb-2 mt-3" controlId="formGridDisplayName">
                                                    <Form.Label style={{fontSize: 14}}>Status:</Form.Label>{' '}
                                                    <Badge pill variant={statusArray.find(el=>el.id==selectedTicket.status).color}>{statusArray.find(el=>el.id==selectedTicket.status).name}</Badge>{' '}
                                                    <Form.Label style={{fontSize: 14}}>Priorität:</Form.Label>{' '}
                                                    <Badge pill bg={colorArray[selectedTicket.prio]}>{titelArray[selectedTicket.prio]}</Badge>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName2'} className="mb-2" controlId="formGridDisplayName2">
                                                    <Form.Label style={{fontSize: 14}}>Erstellt von:</Form.Label><br/>
                                                    <Form.Label>{selectedTicket.createdUid==user.uid?user.displayName:users.find(element=>element.id==selectedTicket.createdUid).displayName}</Form.Label><br/>
                                                    <Form.Label style={{fontSize: 14}}><Moment locale="de" calendar={calendarStrings}>{selectedTicket.created.toDate()}</Moment></Form.Label>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName3'} className="mb-2" controlId="formGridDisplayName3">
                                                    <Form.Label style={{fontSize: 14}}>Notiz:</Form.Label><br/>
                                                    <Form.Label>{selectedTicket.note}</Form.Label>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName4'} className="mb-2" controlId="formGridDisplayName4">
                                                    <Form.Label style={{fontSize: 14}}>Fertigstellung {selectedTicket.dateStart?'von - ':null}bis:</Form.Label><br/>
                                                    <Form.Label>
                                                    {selectedTicket.dateStart?<Moment locale="de" calendar={calendarStrings}>{selectedTicket.dateStart}</Moment>:null}
                                                        {selectedTicket.dateStart?' - ':null}
                                                        <Moment locale="de" calendar={calendarStrings}>{selectedTicket.date}</Moment>
                                                    </Form.Label>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName5'} className="mb-2" controlId="formGridDisplayName5">
                                                    <Form.Label style={{fontSize: 14}}>Fertigstellung von:</Form.Label><br/>
                                                    <Form.Label>{selectedTicket.responsible==user.uid?user.displayName:users.find(element=>element.id==selectedTicket.responsible).displayName}</Form.Label>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName6'} className="mb-2" controlId="formGridDisplayName6">
                                                    <Form.Label style={{fontSize: 14}}>Teilnehmer:</Form.Label><br/>
                                                    <Form.Label>
                                                        {
                                                            selectedTicket.visible.map((userVis, idx) => 
                                                            
                                                                <><Badge key={idx} pill bg={userVis==user.uid?'secondary':'primary'}>{userVis==user.uid?user.displayName:users.find(element=>element.id==userVis).displayName}</Badge>{' '}</>
                                                            
                                                            )
                                                        }
                                                        
                                                    </Form.Label>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={'7'}>
                                            <Tabs
                                                defaultActiveKey={selectedType2}
                                                id="justify-tab-example"
                                                className="mb-3"
                                                // unmountOnExit={true}
                                                activeKey={selectedType2}
                                                justify
                                                onSelect={async (e) => {
                                                    // setMapCenter()
                                                    setSelectedType2(e)
                                                    if(e==='chat'){
                                                        resetCounter({ticket: selectedTicket.id}) 
                                                    }
                                                    if(e==='details'){
                                                        // const docRef = ;
                                                        const docSnap = await getDoc(doc(db, "building", selectedTicket.buildingId));
                                                        
                                                        if (docSnap.exists()) {
                                                        setBuilding({...docSnap.data(), id: docSnap.id})
                                                        }
                                                    }
                                                }}
                                                >
                                                <Tab eventKey="map" title={<TbMap2 size={'22'}/>} href='#'>
                                                {
                                                planUrl?
                                                    <MapContainer
                                                        center={{ lat: 61.505, lng: 275 }}
                                                        zoom={2.3}
                                                        scrollWheelZoom={false}
                                                        keyboard={false}
                                                        className="leaflet-containerInner"
                                                    >
                                                        <ImageOverlay
                                                            url={planUrl?planUrl:floors.find(element=> element.id == selectedTicket.floorId).planUrl}
                                                            bounds={mapCenter}
                                                        />
                                                        <Marker position={selectedTicket.position} />
                                                        
                                                        <Control prepend position='topright'>
                                                            <OverlayTrigger
                                                                className='mb-3'
                                                                key='goBack'
                                                                placement='left'
                                                                overlay={
                                                                    <Tooltip id={`tooltip-'goBack'`}>
                                                                    Show <strong>Floor</strong>
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <Button className={'mb-1 btn-sm btnBgNone '} variant={'light'} onClick={() => navigate('/map/'+selectedTicket.floorId)}> 
                                                                    <TbBorderCorners size={22}/>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <RecenterAutomatically position={selectedTicket.position} />
                                                        </Control>
                                                    </MapContainer>
                                                    :
                                                    floors.find(element=> element.id == selectedTicket.floorId)?
                                                    <MapContainer
                                                        center={{ lat: 61.505, lng: 275 }}
                                                        zoom={2.3}
                                                        scrollWheelZoom={false}
                                                        keyboard={false}
                                                        className="leaflet-containerInner"
                                                    >
                                                        <ImageOverlay
                                                            url={floors.find(element=> element.id == selectedTicket.floorId).planUrl}
                                                            bounds={mapCenter}
                                                        />
                                                        <Marker position={selectedTicket.position} />
                                                        
                                                        <Control prepend position='topright'>
                                                            <OverlayTrigger
                                                                className='mb-3'
                                                                key='goBack'
                                                                placement='left'
                                                                overlay={
                                                                    <Tooltip id={`tooltip-'goBack'`}>
                                                                    Show <strong>Floor</strong>
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <Button className={'mb-1 btn-sm btnBgNone '} variant={'light'} onClick={() => navigate('/map/'+selectedTicket.floorId)}> 
                                                                    <TbBorderCorners size={22}/>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <RecenterAutomatically position={selectedTicket.position} />
                                                        </Control>
                                                    </MapContainer>
                                                    :
                                                    null
                                                }
                                                {/* Addresse:  */}
                                                </Tab>
                                                <Tab eventKey="details" title={<TbListDetails size={'22'}/>}>
                                                    
                                                    
                                                {building && building.addressId?
                                                    <>
                                                        <iframe
                                                            width="100%"
                                                            height="350"
                                                            style={{borderRadius:'10px'}}
                                                            loading="lazy"
                                                            allowFullScreen
                                                            referrerPolicy="no-referrer-when-downgrade"
                                                            // src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&query_place_id='+building.addressId}
                                                            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&q=place_id:"+building.addressId}
                                                        >
                                                        </iframe>
                                                    
                                                    </>
                                            :
                                            null
                                            }
                                            <br/>
                                                    <br/>
                                            {floors.length > 0 && selectedTicket?
                                                floors.find(element=> element.id == selectedTicket.floorId).name
                                                :null
                                            }
                                                </Tab>
                                                <Tab eventKey="chat" title={<TbMessages size={'22'}/>}>
                                                    <ChatTicket selectedType={selectedType2} ticketId={selectedTicket.id} users={users}/>
                                                </Tab>
                                                {
                                                selectedTicket.createdUid == auth.currentUser.uid?

                                                <Tab eventKey="einstellung" title={<TbSettings size={'22'}/>} unmountOnExit={true}>
                                                    <Row className="d-flex align-items-end">
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Verantwortlich:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <Typeahead
                                                                        size="sm"
                                                                        labelKey="displayName"
                                                                        defaultSelected={users.slice(users.map(e => e.id).indexOf(selectedTicket.responsible), users.map(e => e.id).indexOf(selectedTicket.responsible)+1)}
                                                                        id="public-methods-example"
                                                                        onChange={(e)=>{try{setResponsible(e)}catch{}
                                                                        }}
                                                                        options={users}
                                                                        placeholder="Choose a state..."
                                                                    />
                                                                </Col>
                                                            
                                                            
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label >Fertigstellung von:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="datetime-local"
                                                                        min={new Date().toISOString().slice(0, -8)}
                                                                        placeholder="Enter date"
                                                                        defaultValue={selectedTicket.date}
                                                                        onChange={(e) => {
                                                                        setDate(e.target.value);
                                                                        console.log(e.target.value)
                                                                        }} 
                                                                    />
                                                                </Col>
                                                            
                                                            
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label >Fertigstellung bis:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <Form.Control
                                                                        size="sm"
                                                                        type="datetime-local"
                                                                        min={new Date().toISOString().slice(0, -8)}
                                                                        placeholder="Enter date"
                                                                        defaultValue={selectedTicket.dateStart}
                                                                        onChange={(e) => {
                                                                        setDateStart(e.target.value);
                                                                        console.log(e.target.value)
                                                                        }} 
                                                                    />
                                                                </Col>
                                                            
                                                            
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Teilnehmer:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <InputGroup>
                                                                    <Typeahead
                                                                        size="sm"
                                                                        labelKey="displayName"
                                                                        defaultSelected={users.filter(user=>selectedTicket.visible.find(el=>el == user.id)&&user.id!=selectedTicket.responsible)}
                                                                        id="public-methods-example"
                                                                        multiple
                                                                        options={users.filter(user=>user.id!=selectedTicket.responsible)}
                                                                        placeholder="Choose a user..."
                                                                        onChange={(e)=> setVisible(e)}
                                                                    />
                                                                    <Button size="sm"
                                                                    variant='outline-primary'
                                                                    onClick={()=>{
                                                                        showAddUser()
                                                                    }}
                                                                    >
                                                                        <AiOutlineUserAdd/>
                                                                    </Button>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Vorige Tickets:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <Typeahead
                                                                        size="sm"
                                                                        labelKey={option => `${option.name} (${new Date(option.date).toLocaleDateString('de-DE')})`}
                                                                        id="public-methods-example"
                                                                        defaultSelected={selectedTicket.before?tickets.filter(ticket=>selectedTicket.before.find(el=>el == ticket.id)&&ticket.id!=selectedTicket.id):[]}
                                                                        multiple
                                                                        options={tickets.filter(ticket=>ticket.id!=selectTicket.id && (!selectedTicket.after ||!selectedTicket.after.find(el=>el==ticket.id)) && (!after ||!after.find(el=>el==ticket.id)))}
                                                                        filterBy={['responsible', 'note', 'name']}
                                                                        placeholder="Choose a ticket..."
                                                                        onChange={(e)=> setBefore(e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        {skills?
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Kategorie:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <Typeahead
                                                                        size="sm"
                                                                        labelKey={'name'}
                                                                        id="public-methods-example"
                                                                        defaultSelected={selectedTicket.skills?skills.filter(skill=>selectedTicket.skills.find(el=>el == skill.id)):[]}
                                                                        multiple
                                                                        // options={tickets.filter(ticket=>ticket.id!=selectTicket.id && (!selectedTicket.after ||!selectedTicket.after.find(el=>el==ticket.id)) && (!after ||!after.find(el=>el==ticket.id)))}
                                                                        options={skills.filter(skill=>skill.id != selectTicket.skills)}
                                                                        filterBy={['responsible', 'note', 'name']}
                                                                        placeholder="Choose a ticket..."
                                                                        onChange={(e)=> setTicketSkills(e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        :null
                                                        }
                                                        
                                                        {/* <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Vollgende Tickets:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <Typeahead
                                                                        size="sm"
                                                                        labelKey={option => `${option.name} (${new Date(option.date).toLocaleDateString('de-DE')})`}
                                                                        
                                                                        id="public-methods-example"
                                                                        defaultSelected={selectedTicket.after?tickets.filter(ticket=>selectedTicket.after.find(el=>el == ticket.id)&&ticket.id!=selectedTicket.id):[]}
                                                                        multiple
                                                                        options={tickets.filter(ticket=>ticket.id!=selectTicket.id && (!selectedTicket.before ||!selectedTicket.before.find(el=>el==ticket.id)) && (!before ||!before.find(el=>el==ticket.id)))}
                                                                        filterBy={['responsible', 'note', 'name']}
                                                                        placeholder="Choose a ticket..."
                                                                        onChange={(e)=> setAfter(e)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Form.Group> */}
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Status:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                <Form.Select size="sm" onChange={(e)=>{
                                                                    setStatus(e.target.value)
                                                                }}
                                                                defaultValue={statusArray.find(el => el.id==selectedTicket.status).name}
                                                                >
                                                                    {
                                                                        statusArray.map((title, idx)=>
                                                                            // title.id==selectedTicket.status?
                                                                            // <option selected id={title.id}>{title.name}</option>
                                                                            // :
                                                                            <option id={title.id}>{title.name}</option>
                                                                        )
                                                                    }
                                                                </Form.Select>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Prio:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                <Form.Select size="sm" onChange={(e)=>{
                                                                    setPrio(e.target.value)
                                                                    
                                                                }}
                                                                defaultValue={titelArray[selectedTicket.prio]}
                                                                >
                                                                    {
                                                                        titelArray.map((title, idx)=>
                                                                            // idx==selectedTicket.prio?
                                                                            // <option selected id={idx}>{title}</option>
                                                                            // :
                                                                            <option id={idx}>{title}</option>
                                                                        )
                                                                    }
                                                                </Form.Select>
                                                                
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Information:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                
                                                                    <Form.Control
                                                                        // autofocus={false}
                                                                        size="sm"
                                                                        as="textarea"
                                                                        
                                                                        rows={4}
                                                                        
                                                                        defaultValue={selectedTicket.note}
                                                                        onChange={(e) => {
                                                                            setNote(e.target.value);
                                                                        }} 
                                                                        />
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        
                                                            <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Ausschreiben:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                {selectedTicket.skills?
                                                                    selectedTicket.announcement?
                                                                        <a href='#' onClick={() =>setDetails(true)}>Details</a>
                                                                        :
                                                                        <Form.Check 
                                                                            defaultChecked={selectedTicket.announcement}
                                                                            onChange={(e)=>{setAnnouncement(e.target.checked)}}
                                                                        />
                                                                    :
                                                                    'Vorher Kategorien anlegen'
                                                                }
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Button size='sm' onClick={safeSettings}>Speichern</Button>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>

                                                        
                                                    </Row>
                                                </Tab>
                                                
                                                :null
                                                }
                                                {
                                                
                                                    selectedTicket.announcement?
                                                    <Tab eventKey="announcements" title={<TbTruck size={'22'}/>} href='#'>
                                                        
                                                        <Accordion onChange={(e)=>{console.log('accordion')}}>
                                                            
                                                            {
                                                                announcementArray?
                                                                    announcementArray.map((el, idx)=>
                                                                        el.createdUid?
                                                                        // '{el.price}'
                                                                        <Accordion.Item id={el.id} eventKey={el.id+'announcement'}>
                                                                            <Accordion.Header>
                                                                                {/* <Row style={{width: '100px'}}> */}
                                                                                    <Col lg={'8'}>
                                                                                        {users.find(user=>user.id == el.createdUid)?
                                                                                            <>
                                                                                                <Link className={'linkStyleNone'} to={'/profil/'+users.find(user=>user.id == el.createdUid).id}>
                                                                                                    {users.find(user=>user.id == el.createdUid).displayName}
                                                                                                </Link>
                                                                                                {' '}<span style={{color: 'gray'}}>({<Moment locale="de" calendar={calendarStrings}>{el.created.toDate()}</Moment>})</span>
                                                                                            </>
                                                                                            
                                                                                        :null}
                                                                                        
                                                                                    </Col>
                                                                                    <Col lg={'3'}>
                                                                                        <span className="float-end">
                                                                                            {String(el.price).replace('.', ',')}{' €'}
                                                                                        </span>
                                                                                    </Col>
                                                                                {/* </Row> */}
                                                                            </Accordion.Header>
                                                                            <Accordion.Body>
                                                                                <Button variant='outline-success' size={'sm'} onClick={(e)=>{acceptJob(el.id)}}>annehmen</Button>
                                                                                {' '}
                                                                                <Button variant='outline-warning' size={'sm'}>ablehnen</Button>
                                                                                {' '}
                                                                                <Button variant='outline-secondary' size={'sm'} onClick={(e)=>{ticketJobPrice(el.id)}} >verhandeln</Button>
                                                                                <br/>
                                                                            <Form.Label style={{fontSize: 14}}>Mitteilung:</Form.Label> <br/>{el.note}
                                                                            {el.file?
                                                                                <>
                                                                                <br/>
                                                                                <br/>
                                                                                
                                                                                <Form.Label style={{fontSize: 14}}>Dokument:</Form.Label>
                                                                                <br/>
                                                                                
                                                                                    
                                                                                        {/* <object width="100%" height="400" data={el.file} type="application/pdf">   </object> */}
                                                                                        {/* <embed width="100%" height="400" src={el.file+'#toolbar=0&navpanes=0&scrollbar=0'}/> */}
                                                                                        <iframe style={{background: 'white!important'}} src={`${el.file}#toolbar=0`} width="100%" height={500} />
                                                                                        {/* <Row>
                                                                                        
                                                                                            <Document file={el.file} onLoadSuccess={onDocumentLoadSuccess}>
                                                                                                <Page
                                                                                                size="A4"
                                                                                                pageNumber={pageNumber} />
                                                                                            </Document>
                                                                                            <p>
                                                                                                Page {pageNumber} of {numPages}
                                                                                            </p>
                                                                                        
                                                                                        </Row> */}
                                                                                    </>
                                                                                :null
                                                                                }
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                        :
                                                                        null
                                                                    )
                                                            :
                                                            null}
                                                        </Accordion>
                                                        {/* {announcementArray && announcementArray.filter((el) => el.createdUid == false)?
                                                            <p>Anzahl an beendeten Angeboten: {announcementArray.filter((el) => el.createdUid == false).length}</p>
                                                            :
                                                            null                                                        
                                                        } */}
                                                    </Tab>
                                                    :
                                                    null
                                                
                                                }
                                                { selectedTicket.before?
                                                    <Tab eventKey="verknüpfungen" title={<TbPlugConnected size={'22'}/>} unmountOnExit={true}>
                                                        <Gantt selectedTicket={selectedTicket}/>
                                                    </Tab>
                                                :
                                                null
                                                }
                                            </Tabs>
                                            </Col>
                                        </Row>
                                        </Card.Body>
                                    </Card>
                                :
                                    null
                        }
                    </Col>
                    {/* <Col lg={'4'} md={{ order: 3 }} xs={{ order: 3 }}>
                        <Card className='mt-3'>
                            <Card.Header>
                                Ticket Kontingent Kaufen
                            </Card.Header>
                            <Card.Body>
                                Die ersten 10 Tickets sind kostenlos
                                <ListGroup defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}>
                                    <ListGroup.Item key='1' action onClick={()=>{stripTest({amount: 1, price: 2})}}>
                                        <Row>
                                            <Col lg={9}>
                                                Einzelnes Ticket
                                            </Col>
                                            <Col lg={3}>
                                                2 €
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item key='2' action onClick={()=>{stripTest({amount: 10, price: 1})}}>
                                        <Row>
                                            <Col lg={9}>
                                                10 Tickets
                                            </Col>
                                            <Col lg={3}>
                                                10 €
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item key='3' action onClick={()=>{stripTest({amount: 50, price: 0.7})}}>
                                        <Row>
                                            <Col lg={9}>
                                                50 Tickets
                                            </Col>
                                            <Col lg={3}>
                                                35 €
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
                <ModalCreateUser ticket={selectedTicket} closeAddUser={closeAddUser} addUser={addUser}/>
                {activeAnnouncement && users?
                <PricingAnnouncement user={user} ticket={selectedTicket}  announcementUser={users} handleClose={closePricingA} show={pricingA} announcement={activeAnnouncement}/>
                :
                null}
                <AnnouncementDetails user={user} ticket={selectedTicket}  users={users} handleClose={closeDetails} show={details}/>
            </Container>
            
        )
    // }
    // else {
    //     return (
    //         <h4 className="mt-5">Bisher sind keine Tickets verfügbar</h4>
    //     )
    // }
}

