import React from "react";
import { useState, useEffect, useRef } from 'react';
import { messaging, auth, db  } from "../firebase";
import {arrayUnion, increment, doc, addDoc, getDoc, updateDoc, query, onSnapshot, collection, getDocs, where, and, or, serverTimestamp, orderBy} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Moment from 'react-moment';
import { Accordion, OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Modal, Badge } from "react-bootstrap";

export default function PricingAnnouncement({user, show, handleClose, announcement, announcementUser, ticket}){

    const [procent, setProcent] = useState(0)
    const [note, setNote] = useState('')
    const [newPrice, setNewPrice] = useState(announcement.price)
    // console.log(announcementUser)
    useEffect(()=>{
        console.log(newPrice)
        console.log(newPrice/announcement.price)
        setProcent(Math.round(100-(newPrice/announcement.price)*100)*(-1))
    }, [newPrice])

    const setNewPriceAnnouncement = async() => {
        const docRef = doc(db, 'announcement', announcement.id)
        // const date = serverTimestamp()
        updateDoc(docRef, {
            newPrice: arrayUnion({price: newPrice, note: note, userId: user.uid})
        })
        handleClose()
    }

    return (
        <Modal show={show}  onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Preisverhandlung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Angebot von {announcementUser.find((el)=> el.id == announcement.createdUid)?announcementUser.find((el)=> el.id == announcement.createdUid).displayName:null} zum Ticket {ticket.name}
        {/* {announcement.id} */}
        <Row className="mt-3">
            <Col lg='4'>
                Aktueller Preis:
            </Col>
            <Col lg='3'>
                {/* {announcement.price} € */}
                <span className="float-end">
                    {String(announcement.price).replace('.', ',')}{' €'}
                </span>
            </Col>
            <Col lg='5'>
                
            </Col>

        </Row>
        <Row className="mt-3">
            <Col lg='4'>
                Gegenangebot:{' '}
            </Col>
            <Col lg='3'>
                <Form.Control 
                style={{textAlign: 'right'}}
                type="number"
                value={newPrice}
                size="sm"
                onChange={(e)=>{
                    setNewPrice(e.target.value.replace(',', '.'))
                }}
                />
            </Col>
            <Col lg='5'>
                <span style={{fontSize: 14}}>{procent}{' '}%</span>
            
            </Col>
            
        </Row>
        <Row className="mt-3">
            <Col lg='4'>
                Notiz:{' '}
            </Col>
            <Col lg='8'>
                <Form.Control 
                as="textarea"
                                                                        
                rows={4}
                size="sm"
                onChange={(e)=>{
                    setNote(e.target.value)
                }}
                />
            </Col>
            {/* <Col lg='3'>
                <span style={{fontSize: 14}}>{procent}{' '}%</span>
            
            </Col> */}
            
        </Row>
        <Row className="mt-3">
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Historie{announcement && announcement.newPrice?<Badge style={{marginLeft: '10px'}}>{announcement.newPrice.length}</Badge>:null}</Accordion.Header>
                <Accordion.Body>
                    {
                        announcement.newPrice?
                        announcement.newPrice.reverse().map((price, idx)=>
                    <Row className='mt-3' style={{fontSize: '12px'}}>
                        {/* <Col lg='1'>
                            {idx+1}
                        </Col> */}
                        <Col lg='4'>
                            Preis: {price.price.replace('.', ',')} €<br/>
                            User: {price.userId == user.uid?user.displayName:announcementUser.find((el)=>el.id==price.userId).displayName}
                        </Col>
                        <Col lg='8'>
                            Notiz: {price.note}
                        </Col>
                    </Row>
                    )
                    :
                    null
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </Row>
        <Row className="mt-3">
            <Col lg='12'>
                <Button onClick={setNewPriceAnnouncement}>Abschicken</Button>
            </Col>
        </Row>

        </Modal.Body>
      </Modal>
    )
}