import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, getDocs, query, where, onSnapshot, updateDoc, doc, arrayRemove } from "firebase/firestore"; 
import { db } from "../firebase";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { auth } from "../firebase";
import {RxDropdownMenu, RxDotsHorizontal} from "react-icons/rx";
import Dropdown from 'react-bootstrap/Dropdown';
import { Col, Button, Row, Container, Card, Form, InputGroup, Image, Badge } from "react-bootstrap";

// function GetUsers({dataMain, setDataMain, setUser }){
    
//     const [dataArray, setDataArray] = useState(false)
//     useEffect(()=>{
//         const dataArrayPuffer = []
//         const unsubscribe = onSnapshot(collection(db, "unit"), { includeMetadataChanges: true }, (snapshot) => {
            
//             snapshot.docChanges().forEach((change) => {
//                 if (change.type === "added") {
//                     console.log("New city: ", change.doc.data());
                    
//                 }
//                 if (change.type === "modified") {
//                     console.log("Modified city: ", change.doc.data());
//                 }
//                 if (change.type === "removed") {
//                     console.log("Removed city: ", change.doc.data());
//                 }
//                 dataArrayPuffer.push({...change.doc.data(), id: change.doc.id})
//           }); 
//         //   console.log(dataArrayPuffer)   
//           // setDataMain(dataArrayPuffer)  
//         })
           
            
//     }, [])
// }

async function removeToken({setUser}){
  const docRef = doc(db, "user", auth.currentUser.uid);
  console.log(localStorage.getItem("token"))
  await updateDoc(docRef, {
    fcmToken: arrayRemove(localStorage.getItem("tokenP"))
  });
  setUser(false); 
  auth.signOut();
}



function NavBar({dataMain, setDataMain, setUser, managers, userData}) {
    // useEffect(()=>{
    //     // console.log('test123123123')
        
    // }, [])
    
    // GetUsers({dataMain: dataMain, setDataMain: setDataMain})

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="black">
      <Container>
        <Navbar.Brand href="#" as={Link} to="/">Hexakt</Navbar.Brand>
        
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          
            {/* <Nav.Link href="/1">Profil</Nav.Link> */}
            {/* {auth.currentUser? */}
            {auth.currentUser?
            <>
              <Nav.Link href="#" as={Link} to={"/messages"}>Mitteilungen 
              {userData.messageCount?
                // <Badge pill className='AlertBadge'>{userData.messageCount}</Badge>
                <Badge pill bg={'light'} text="dark" className='AlertBadge'>{userData.messageCount}</Badge>
                :null
              }
              </Nav.Link>
              <Nav.Link href="#" as={Link} to={"/tickets"}>Tickets</Nav.Link>
              {userData.handyman?
                <Nav.Link href="#" as={Link} to={"/jobs"}>Jobs</Nav.Link>
              :
              null
              }
              {/* <Nav.Link as={Link} href="#" to={"/"}>Management</Nav.Link> */}
              <Nav.Link as={Link} href="#" to={"/profil/"+auth.currentUser.uid}>Profil</Nav.Link>
            </>
            :null
            }
            {/* <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            
            {/* <NavDropdown title={'Manager'} id="collasible-nav-dropdown"> <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <Dropdown className=''>
              <Row>
                <Col lg={8} xs={7}>
                <Button as={Link} className="buttonFatFont" variant="link" to={'/'}>Management</Button>
                </Col>
                <Col lg={4} xs={5} style={{ display: "flex" , paddingLeft: 0}}>
                <Dropdown.Toggle key={'test'} className="dropdown-toggle2 btn-linkSpecial" split  variant="link"><RxDropdownMenu size={28}/></Dropdown.Toggle>
                <Dropdown.Menu  > 
                {managers?
                managers.map((manager, idx)=>
                  // <>
                  <Dropdown>
                  <Row>
                    <Col lg={9} xs={7}>
                    <Button className="buttonFatFont" variant="link">{manager.name}</Button>
                    </Col>
                    <Col lg={3} xs={5} style={{ display: "flex" , paddingLeft: 0}}>
                    <Dropdown.Toggle key={'test'} className="dropdown-toggle2 btn-linkSpecial" split  variant="link"><RxDropdownMenu size={28}/></Dropdown.Toggle>
                    <Dropdown.Menu  >
                    {managers?
                    managers.map((manager, idx)=>
                      // <>
                        <Row key={'test'+idx}>
                          <Col lg={8} xs={7} key={'test2'+idx}>
                            {manager.name} 
                          </Col>
                        </Row>
                      
                      // </>
                      )
                    :
                    null
                    }
                    </Dropdown.Menu>
                    </Col>
                  </Row>
                </Dropdown>
                  
                  // </>
                  )
                :
                null
                }
                {/* </Dropdown.Menu>
                </Col>
              </Row>
            </Dropdown> 
            </NavDropdown>
          */}
          </Nav>
          <Nav>
            {/* <Nav.Link href="#deets">More deets</Nav.Link> */}
            {
                auth.currentUser?
                    <Nav.Link href="#" as={Link} to={'/'} onClick={() => {removeToken({setUser: setUser})}}><h5>Logout</h5></Nav.Link>
                :
                    <Nav.Link href="#" as={Link} to={'/regist'}><h5>Registrieren</h5></Nav.Link>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;