import React from 'react';
import { useState, useEffect } from 'react';
import { messaging, auth, db, functions } from "../firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, setDoc, updateDoc, query, onSnapshot, collection, arrayUnion, arrayRemove, increment, where, serverTimestamp, addDoc, getDocs } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Moment from 'react-moment';
import { useParams, Link, useNavigate } from "react-router-dom";
import { Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Badge, Placeholder, Accordion } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { divIcon, latLng } from 'leaflet';
import L from 'leaflet';
import Ticket from './Ticket';
import Control from 'react-leaflet-custom-control'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import ChatTicket from '../tools/ChatTicket';
import { Gantt } from '../tools/Gantt';
import { ModalCreateUser } from '../modals/ModalCreateUser';
import { MapContainer, TileLayer,ImageOverlay, SVGOverlay, useMap, Marker, Popup, useMapEvents, LayersControl, LayerGroup, useMapEvent, Rectangle} from 'react-leaflet';
import { useEventHandlers } from '@react-leaflet/core';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {MdArrowBackIosNew, MdKeyboardBackspace, MdNotifications, MdNotificationsOff, MdPushPin} from "react-icons/md";
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { notification } from 'antd';
import { TbBorderCorners, TbMap2, TbPlugConnected, TbListDetails, TbTruck } from "react-icons/tb";
import { AiOutlineUserAdd } from "react-icons/ai";
import uuid from 'react-uuid';
import JobDetails from '../tools/jobDetails';

// import Stripe from 'stripe';

export default function Tickets({ floors, users, user, notifications, selectedType, setSelectedType, skills}){
    const [visTickets, setVisTickets] = useState(false)
    const [selectedTicket, setSelectedTicket] = useState(false)
    
    const [addUser, setAddUser] = useState(false)
    const showAddUser = () => {setAddUser(true)}
    const closeAddUser = () => {setAddUser(false)}
    const [details, setDetails] = useState(false)
    const showDetails = () => {setDetails(true)}
    const closeDetails = () => {setDetails(false)}

    const [tickets, setTickets] = useState(false)
    const [announcement, setAnnouncement] = useState(false)
    const [announcementArray, setAnnouncementArray] = useState(false)
    const [announcement2Array, setAnnouncement2Array] = useState(false)
    const [floor, setFloor] = useState(false)
    const [note, setNote] = useState(false)
    const [visible, setVisible] = useState(false)
    const [responsible, setResponsible] = useState(false)
    const [date, setDate] = useState(false)
    const [dateStart, setDateStart] = useState(false)
    const [before, setBefore] = useState(false)
    const [after, setAfter] = useState(false)
    const [selectedType2, setSelectedType2] = useState(selectedType)
    const [prio, setPrio] = useState(false)
    const [mapCenter, setMapCenter] = useState([[10,150], [10000,400]])
    const [status, setStatus] = useState(false)
    const [planUrl, setPlanUrl] = useState(false)
    const [file, setFile] = useState(false)
    const [invoice, setInvoice] = useState(false)
    const [price, setPrice] = useState(false)
    const [building, setBuilding] = useState(false)
    const [defaultVisibleUsers, setDefaultVisibleUsers] = useState(false)
    let { managerId, buildingId, floorId, id, type } = useParams();
    let navigate = useNavigate();

    useEffect(()=>{
        // setVisTickets(tickets)
        if(tickets && users && floors){
            ticketFilter()
            // console.log('test2')
        }
        // ticketFilter()
        if(selectedTicket){
            setSelectedTicket(tickets.find(el => el.id == selectedTicket.id))
        }
    },[tickets])
    // useEffect(()=>{
    //     if(floors && selectedTicket){
    //         getImage()
    //     }
    // },[floors, selectedTicket])
    useEffect(()=>{
        if(!tickets){
            getTickets()
            getAnnouncements()
        }
        else {
            setVisTickets(tickets)
        }
    },[tickets])
    useEffect(()=>{
        if(selectedTicket){
            getFloor()
        }
    }, [selectedTicket])

    const getAnnouncements = () => {
        const q = query(collection(db, "announcement"), where("createdUid", "==", auth.currentUser.uid));
        
        // const querySnapshot = await getDocs(q);
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
            const announcementArray3 = []
            querySnapshot.forEach((doc) => {
                // console.log(doc.id, " => ", doc.data());
                announcementArray3.push({...doc.data(), id: doc.id})
            });
            
                // cons
            setAnnouncement2Array(announcementArray3)
                // setPrice(announcementArray[0].note)
                
            
        })
    }

    const getFloor = async () => {
        const docRef = doc(db, "floor", selectedTicket.floorId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setFloor({...docSnap.data(), id: docSnap.id})
            setPlanUrl(floor.planUrl)
        } 
        // const q = query(collection(db, "announcement"), where("ticketId", "==", selectedTicket.id), where("createdUid", "==", auth.currentUser.uid));
        
        // const querySnapshot = await getDocs(q);
        // const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
        //     const announcementArray2 = []
        //     querySnapshot.forEach((doc) => {
        //         // console.log(doc.id, " => ", doc.data());
        //         announcementArray2.push({...doc.data(), id: doc.id})
        //     });
            if (announcement2Array.length > 0 && announcement2Array.find((el)=>el.ticketId == selectedTicket.id)){
                // cons
                setAnnouncementArray([announcement2Array.find((el)=>el.ticketId == selectedTicket.id)])
                // setPrice(announcementArray[0].note)
                setPrice(announcement2Array.find((el)=>el.ticketId == selectedTicket.id).price)
                setNote(announcement2Array.find((el)=>el.ticketId == selectedTicket.id).note)
            }
            else {
                setAnnouncementArray(false)
                setPrice(false)
                setNote(false)
            }
        // })
          
    }
    const getTickets = async () => {
        const q = query(collection(db, "ticket"), where('announcement', '==', true), where('jobId', '==', false));
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
            const array = [];
            querySnapshot.forEach((doc) => {
                array.push({...doc.data(), id: doc.id});
                // console.log(doc)
                
            });
            // console.log(array)
            if(array.length>0 && array != tickets){
                // console.log('ticketArray',array[0].created)
                console.log(array)
                setTickets(array)
                // console.log(array)
            }
        
        });
        // setVisTickets()
    }
    // useEffect(()=>{
    //     if(tickets && users && floors && !selectedTicket){
    //         ticketFilter()
    //         console.log('test1')
    //     }
        
    // },[tickets, users, floors])

    // const getImage =  () => {
        
    //     const indexddb =  window.indexedDB.open("ImageData");
    //     indexddb.onsuccess = async function (e) {
    //         // console.log("success...");
    //         // const db12= e.target.result;
    //         const request = await e.target.result.transaction('friends')
    //                .objectStore('friends')
    //                .getAll();

    //         request.onsuccess = function(){
                
    //             const floorThis = floors.find(element=> element.id == selectedTicket.floorId)
    //             // console.log(floorThis)
    //             if(floorThis && request.result.find(el => el.name == floorThis.planUrl)){
    //                 const blob = new Blob( [ request.result.find(el => el.name == floorThis.planUrl).array ], {type: 'image/svg+xml'} );
    //                 const url = URL.createObjectURL( blob );
    //                 setPlanUrl(url)
    //                 // console.log(request.result.find(el => el.name == floorThis.planUrl))
    //                 // console.log(url)
    //             }
    //             else {
    //                 console.log('planFehlt')
    //                 setPlanUrl(floor.planUrl)
    //             }
    //         }

    //     };
        
    // }

    const resetCounter = async ({ticket}) => {
        const docRef = doc(db, "user/"+user.uid+'/messages', 'ticket_'+ticket)
        const docSnap = await getDoc(docRef)
        // console.log('test')
        if(docSnap.exists()){
            const oldCountValue = docSnap.data().count
            updateDoc(docRef, {
                count: 0,
            })
            updateDoc(doc(db, "user", user.uid), {messageCount: increment(-oldCountValue)})
        }
    }
    const resetCounter2 = async ({ticket}) => {
        const docRef = doc(db, "user/"+user.uid+'/messages', 'tUpdate_'+ticket)
        const docSnap = await getDoc(docRef)
        // console.log('test')
        if(docSnap.exists()){
            const oldCountValue = docSnap.data().count
            updateDoc(docRef, {
                count: 0,
            })
            updateDoc(doc(db, "user", user.uid), {messageCount: increment(-oldCountValue)})
        }
    }

    const ticketFilter = () => {
        if(id){
            const ticket = tickets.find(ticket=>ticket.id==id)
            console.log(floors)
            setSelectedTicket(ticket)
            resetCounter2({ticket: id})
            if(type && !selectedTicket){
                setSelectedType2(type)
                
                if(type=='chat'){
                    resetCounter({ticket: id})
                }
            }
            var array = []
            ticket.visible.forEach(user=>{
                var visU = users.find(u => u.id == user)
                if(visU && visU.id != ticket.responsible){
                    array.push(visU)
                }
                
            })
            console.log(array)
            setDefaultVisibleUsers(array)

        }
        if(floorId && floorId != 'none'){
            setVisTickets(tickets.filter(ticket=>ticket.floorId==floorId))
        }
        else if(buildingId && buildingId != 'none'){
            setVisTickets(tickets.filter(ticket=>ticket.buildingId==buildingId))
        }
        else {
            setVisTickets(tickets)
        }
        // console.log('testfilter')
    }

    // const createStripCheckout = httpsCallable(functions, 'createStripCheckout2');
    // const stripe = window.Stripe('pk_test_51MzbGJI93xQXIeQzplDYa29WmDKu6bFjeBJ4bSsigcVFVKvVC4s32gUVXtx09YRuXYMSsjto79Kme0E1i2kIVIjR00uQOlSrfL');
    // const stripTest = ({amount, price}) => {
    //     console.log(amount)
    //     createStripCheckout({amount_pice: price, quantity: amount}).then(response => {
    //         const sessionId = response.data.id
    //         // console.log(response.data)
    //         stripe.redirectToCheckout({sessionId: sessionId})
    //     })
    // }
    

    const colorArray = ['none', 'secondary', 'info', 'warning', 'danger']
    const titelArray = ['none', 'Niedrig', 'Mittel', 'Hoch', 'Kritisch']
    const statusArray = [{id: 'created', name: 'Erstellt', color: 'primary'}, {id: 'In Progress', name: 'In Bearbeitung', color: 'warning'}, {id: 'finished', name: 'Fertig', color: 'success'}]
    const calendarStrings = {
        lastDay : '[Gestern ] HH:mm',
        sameDay : '[Heute ] HH:mm',
        nextDay : '[Morgen um] HH:mm',
        lastWeek : '[Letzten] dddd [um] HH:mm',
        nextWeek : 'dddd [um] HH:mm',
        sameElse : 'DD.MM.YY [um] HH:mm'
      };
    
    const searchTicket = (e) => {
        // console.log('searchContacts')
        // console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredContacts = tickets.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.name.toLowerCase().includes(lowerCase)
            }
        })
        setVisTickets(filteredContacts)

    }

    const selectTicket = (thisTicket) => {
        setDefaultVisibleUsers(false)
        setSelectedTicket(thisTicket)
        // console.log(thisTicket)
        // console.log(floors)
        
        var array = []
        thisTicket.visible.forEach(user=>{
            var visU = users.find(u => u.id == user)
            if(visU && visU.id != thisTicket.responsible){
                array.push(visU)
            }
            
        })
        console.log(array)
        setDefaultVisibleUsers(array)
        // const floor = floors.find((floor)=>{floor.id === thisTicket.floorId})
        // setFloor(floor)
    }
    
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
        iconRetinaUrl: '',
        iconUrl: require('../roomklein.png'),
        shadowUrl: '',
        iconSize:     [20, 25],
        iconAnchor:   [11.4, 17],
        popupAnchor:  [0, -9.5],
    });

    const notifyMe = async () => {
        // console.log(user)
        await updateDoc(doc(db, "ticket", selectedTicket.id), {
            notifyMe: arrayUnion(user.uid)
        })
    }
    const notNotifyMe = async () => {
        await updateDoc(doc(db, "ticket", selectedTicket.id), {
            notifyMe: arrayRemove(user.uid)
        })
    }

    const safeInvoice = async () => {
        if(invoice){
            const storage = getStorage();
            const image = ref(storage, 'invoice/'+uuid())
            uploadBytes(image, file)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                .then(async(url) => {
                    updateDoc(doc(db, "announcement", announcementArray[0].id), {
                        invoice: url,
                    })
                })
            })
        }
    }

    const safeSettings = async () => {
        

        var newData = {}
        if(file){
            const storage = getStorage();
            const image = ref(storage, 'announcement/'+uuid())
            uploadBytes(image, file)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                .then(async(url) => {
                    newData['file'] = url
                    if(price){
                        newData['price'] = price
                    }
                    if(note){
                        newData['note'] = note
                    }
                    newData['createdUid'] = auth.currentUser.uid
                    newData['created'] = serverTimestamp()
                    newData['ticketId'] = selectedTicket.id
                    newData['newPrice'] = arrayUnion({price: price, note: note, userId: auth.currentUser.uid})
                    
                    // await setDoc(doc(db, "announcement",), newData)
                    if(announcementArray.length > 0){
                        updateDoc(doc(db, "announcement", announcementArray[0].id), newData)
                        // setAnnouncementArray([{note: note, price: price, file: url, id: announcementArray[0].id}])
                    }
                    else {
                        const test = await addDoc(collection(db, "announcement"), newData)
                        console(test)
                        setAnnouncementArray([{note: note, price: price, file: url}])
                    }
                })
            })
        }
        else {
            if(price){
                newData['price'] = price
            }
            if(note){
                newData['note'] = note
            }
            newData['createdUid'] = auth.currentUser.uid
            newData['created'] = serverTimestamp()
            newData['ticketId'] = selectedTicket.id
            newData['newPrice'] = arrayUnion({price: price, note: note, userId: auth.currentUser.uid})

            if(announcementArray.length > 0){
                updateDoc(doc(db, "announcement", announcementArray[0].id), newData)
                // setAnnouncementArray([{note: note, price: price, id: announcementArray[0].id}])    
            }
            else {
            // await setDoc(doc(db, "announcement", announcementArray[0].id), newData)
            const test = await addDoc(collection(db, "announcement"), newData)
            setAnnouncementArray([{note: note, price: price, id:'none'}])
            console.log(test)
            }
            
        }
        // console.log(responsible[0].id)
        // await updateDoc(doc(db, "ticket", selectedTicket.id), {
            
        // })
    }
    const RecenterAutomatically = ({position}) => {
        const map = useMap();
         useEffect(() => {
           map.flyTo(position, 3)
        //    setView(position);
         }, [position]);
         return null;
       }
    // if(tickets){
        return (
            <Container>
                <Row>
                    <Col lg={'4'} md={{ order: 2 }} xs={{ order: 2 }}>
                        {
                            tickets?
                            <Card className='mt-3'>
                                <ListGroup defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}>
                                    
                                        <ListGroup.Item key='search' style={{height: '50px', padding: 0}}>
                                            {/* <Row>     */}
                                                <Col lg={6}>
                                                    <Form.Control onChange={searchTicket} placeholder='Job suchen' style={{border: '0px'}} />
                                                </Col>
                                                <Col lg={6}>
                                                    {/* <Form.Control onChange={searchTicket} placeholder='Ticket suchen' style={{border: '0px'}} /> */}
                                                    {/* <p>Filter</p> */}
                                                    
                                                </Col>
                                            {/* </Row> */}
                                        </ListGroup.Item>
                                    
                                    
                                    {
                                        visTickets?
                                        visTickets.map((ticket, idx)=>
                                        <ListGroup.Item  variant={notifications?notifications.find(notification => notification.id=='ticket_'+ticket.id) && notifications.find(notification => notification.id=='ticket_'+ticket.id).count > 0?'primary':null:null} as={Link} to={'/jobs/none/none/none/'+ticket.id} key={'#ticket_id='+idx} action onClick={()=>{setMapCenter([[10,150], [10000,400]]); selectTicket(ticket); setSelectedType2('map'); setPlanUrl(false); resetCounter2({ticket: ticket.id})}}>
                                            <Row>
                                                <Col lg={7}>
                                                    {ticket.name} <Badge pill bg={colorArray[ticket.prio]}>
                                                        {titelArray[ticket.prio]}
                                                    </Badge>
                                                    {notifications?
                                                    notifications.find(notification => notification.id=='ticket_'+ticket.id) && notifications.find(notification => notification.id=='ticket_'+ticket.id).count > 0?
                                                        <Badge pill>{notifications.find(notification => notification.id=='ticket_'+ticket.id).count}</Badge>
                                                        :
                                                        null
                                                        :null
                                                    }
                                                    <p style={{fontSize: '12px', marginBottom: 0}}>Bis <Moment locale="de" calendar={calendarStrings}>{ticket.date}</Moment> zu erledigen</p>
                                                </Col>
                                                <Col lg={5}>
                                                    {/* {ticket.skills} */}
                                                    {
                                                        // ticket.skills?
                                                        skills?
                                                        ticket.skills.map((skill)=>
                                                        <><Badge pill>
                                                            {/* {skill} */}
                                                            {skills.find(el=>el.id == skill).name}
                                                        </Badge>{' '}</>
                                                        )
                                                        :
                                                        null
                                                    }
                                                    {/* <Badge pill bg={colorArray[ticket.prio]}>
                                                        {titelArray[ticket.prio]}
                                                    </Badge>
                                                    <Badge pill bg={colorArray[ticket.prio]}>
                                                        {titelArray[ticket.prio]}
                                                    </Badge>
                                                    <Badge pill bg={colorArray[ticket.prio]}>
                                                        {titelArray[ticket.prio]}
                                                    </Badge> */}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        )   
                                     :null   
                                    }
                                </ListGroup>
                            </Card>
                            :
                            <h4 className="mt-5">Bisher sind keine Jobs verfügbar</h4>
                        }
                        
                    </Col>
                    <Col lg={'8'} md={{ order: 2 }} xs={{ order: 1 }}>
                        {
                            selectedTicket?
                                    <Card className='mt-3' style={{minHeight: '80vh'}} bg="light">
                                        <Card.Body>
                                        <Row>
                                            <Col lg={'5'}>
                                                <button className='btn d-lg-none' style={{paddingLeft:0, paddingTop: 0}}><MdKeyboardBackspace size={24} onClick={()=>{setSelectedTicket(false); announcementArray(false)}}/></button>
                                                <h4>
                                                    {selectedTicket.name}{' '}
                                                    {
                                                        selectedTicket.notifyMe && selectedTicket.notifyMe.includes(user.uid)?
                                                        <button onClick={notNotifyMe} style={{padding: 0, color:'#0d6efd'}} className='btn'><MdPushPin  size={24}/></button>
                                                        :
                                                        <button onClick={notifyMe} style={{padding: 0}} className='btn'><MdPushPin  size={24}/></button>
                                                    }
                                                </h4>
                                                <Form.Group key={'formGridDisplayName'} className="mb-2 mt-3" controlId="formGridDisplayName">
                                                    <Form.Label style={{fontSize: 14}}>Status:</Form.Label>{' '}
                                                    <Badge pill variant={statusArray.find(el=>el.id==selectedTicket.status).color}>{statusArray.find(el=>el.id==selectedTicket.status).name}</Badge>{' '}
                                                    <Form.Label style={{fontSize: 14}}>Priorität:</Form.Label>{' '}
                                                    <Badge pill bg={colorArray[selectedTicket.prio]}>{titelArray[selectedTicket.prio]}</Badge>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName2'} className="mb-2" controlId="formGridDisplayName2">
                                                    <Form.Label style={{fontSize: 14}}>Erstellt von:</Form.Label><br/>
                                                    <Form.Label>{selectedTicket.createdUid==user.uid?user.displayName:users.find(element=>element.id==selectedTicket.createdUid).displayName}</Form.Label><br/>
                                                    <Form.Label style={{fontSize: 14}}><Moment locale="de" calendar={calendarStrings}>{selectedTicket.created.toDate()}</Moment></Form.Label>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName3'} className="mb-2" controlId="formGridDisplayName3">
                                                    <Form.Label style={{fontSize: 14}}>Notiz:</Form.Label><br/>
                                                    <Form.Label>{selectedTicket.note}</Form.Label>
                                                </Form.Group>
                                                <Form.Group key={'formGridDisplayName4'} className="mb-2" controlId="formGridDisplayName4">
                                                    <Form.Label style={{fontSize: 14}}>Fertigstellung {selectedTicket.dateStart?'von - ':null}bis:</Form.Label><br/>
                                                    <Form.Label>
                                                    {selectedTicket.dateStart?<Moment locale="de" calendar={calendarStrings}>{selectedTicket.dateStart}</Moment>:null}
                                                        {selectedTicket.dateStart?' - ':null}
                                                        <Moment locale="de" calendar={calendarStrings}>{selectedTicket.date}</Moment>
                                                    </Form.Label>
                                                </Form.Group>
                                                {/* <Form.Group key={'formGridDisplayName5'} className="mb-2" controlId="formGridDisplayName5">
                                                    <Form.Label style={{fontSize: 14}}>Fertigstellung von:</Form.Label><br/>
                                                    <Form.Label>{selectedTicket.responsible==user.uid?user.displayName:users.find(element=>element.id==selectedTicket.responsible).displayName}</Form.Label>
                                                </Form.Group> */}
                                                {/* <Form.Group key={'formGridDisplayName6'} className="mb-2" controlId="formGridDisplayName6">
                                                    <Form.Label style={{fontSize: 14}}>Teilnehmer:</Form.Label><br/>
                                                    <Form.Label>
                                                        {
                                                            selectedTicket.visible.map((userVis, idx) => 
                                                            
                                                                <><Badge key={idx} pill bg={userVis==user.uid?'secondary':'primary'}>{userVis==user.uid?user.displayName:users.find(element=>element.id==userVis).displayName}</Badge>{' '}</>
                                                            
                                                            )
                                                        }
                                                        
                                                    </Form.Label>
                                                </Form.Group> */}
                                            </Col>
                                            <Col lg={'7'}>
                                            <Tabs
                                                defaultActiveKey={selectedType2}
                                                id="justify-tab-example"
                                                className="mb-3"
                                                // unmountOnExit={true}
                                                activeKey={selectedType2}
                                                justify
                                                onSelect={async (e) => {
                                                    // setMapCenter()
                                                    setSelectedType2(e)
                                                    if(e==='chat'){
                                                        resetCounter({ticket: selectedTicket.id}) 
                                                    }
                                                    if(e==='details'){
                                                        // const docRef = ;
                                                        const docSnap = await getDoc(doc(db, "building", selectedTicket.buildingId));
                                                        
                                                        if (docSnap.exists()) {
                                                        setBuilding({...docSnap.data(), id: docSnap.id})
                                                        }
                                                    }
                                                }}
                                                >
                                                <Tab eventKey="map" title={<TbMap2 size={'22'}/>} href='#'>
                                                {
                                                planUrl?
                                                    <MapContainer
                                                        center={{ lat: 61.505, lng: 275 }}
                                                        zoom={2.3}
                                                        scrollWheelZoom={false}
                                                        keyboard={false}
                                                        className="leaflet-containerInner"
                                                    >
                                                        <ImageOverlay
                                                            url={planUrl?planUrl:floors.find(element=> element.id == selectedTicket.floorId).planUrl}
                                                            bounds={mapCenter}
                                                        />
                                                        <Marker position={selectedTicket.position} />
                                                        
                                                        <Control prepend position='topright'>
                                                            <OverlayTrigger
                                                                className='mb-3'
                                                                key='goBack'
                                                                placement='left'
                                                                overlay={
                                                                    <Tooltip id={`tooltip-'goBack'`}>
                                                                    Show <strong>Floor</strong>
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <Button className={'mb-1 btn-sm btnBgNone '} variant={'light'} onClick={() => navigate('/map/'+selectedTicket.floorId)}> 
                                                                    <TbBorderCorners size={22}/>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <RecenterAutomatically position={selectedTicket.position} />
                                                        </Control>
                                                    </MapContainer>
                                                    :
                                                    floors.find(element=> element.id == selectedTicket.floorId)?
                                                    <MapContainer
                                                        center={{ lat: 61.505, lng: 275 }}
                                                        zoom={2.3}
                                                        scrollWheelZoom={false}
                                                        keyboard={false}
                                                        className="leaflet-containerInner"
                                                    >
                                                        <ImageOverlay
                                                            url={floors.find(element=> element.id == selectedTicket.floorId).planUrl}
                                                            bounds={mapCenter}
                                                        />
                                                        <Marker position={selectedTicket.position} />
                                                        
                                                        <Control prepend position='topright'>
                                                            <OverlayTrigger
                                                                className='mb-3'
                                                                key='goBack'
                                                                placement='left'
                                                                overlay={
                                                                    <Tooltip id={`tooltip-'goBack'`}>
                                                                    Show <strong>Floor</strong>
                                                                    </Tooltip>
                                                                }
                                                                >
                                                                <Button className={'mb-1 btn-sm btnBgNone '} variant={'light'} onClick={() => navigate('/map/'+selectedTicket.floorId)}> 
                                                                    <TbBorderCorners size={22}/>
                                                                </Button>
                                                            </OverlayTrigger>
                                                            <RecenterAutomatically position={selectedTicket.position} />
                                                        </Control>
                                                    </MapContainer>
                                                    :
                                                    null
                                                }
                                                {/* Addresse:  */}
                                                </Tab>
                                                <Tab eventKey="details" title={<TbListDetails size={'22'}/>}>
                                                    
                                                    
                                                {building && building.addressId?
                                                    <>
                                                        <iframe
                                                            width="100%"
                                                            height="350"
                                                            style={{borderRadius:'10px'}}
                                                            loading="lazy"
                                                            allowFullScreen
                                                            referrerPolicy="no-referrer-when-downgrade"
                                                            // src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&query_place_id='+building.addressId}
                                                            src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&q=place_id:"+building.addressId}
                                                        >
                                                        </iframe>
                                                    
                                                    </>
                                            :
                                            null
                                            }
                                            <br/>
                                                    <br/>
                                            {floors.find(element=> element.id == selectedTicket.floorId).name}
                                                </Tab>
                                                <Tab eventKey="announcement" title={<TbTruck size={'22'}/>} href='#'>
                                                    <Row className="d-flex align-items-end">
                                                        {
                                                            announcementArray[0]?
                                                            <>
                                                                <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                    <Row>
                                                                        <Col lg={'4'}>
                                                                            <Form.Label>Job:</Form.Label>
                                                                        </Col>
                                                                        <Col lg={'8'}>
                                                                            <a href='#' onClick={showDetails}>Details</a>
                                                                            
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                                <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                    <Row>
                                                                        <Col lg={'4'}>
                                                                            <Form.Label>Aktueller Preis:</Form.Label>
                                                                        </Col>
                                                                        <Col lg={'8'}>
                                                                            {announcementArray[0].price?String(announcementArray[0].price).replace('.', ','):''} €
                                                                            
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </>
                                                            :
                                                            <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                <Row>
                                                                    <Col lg={'4'}>
                                                                        <Form.Label>Job:</Form.Label>
                                                                    </Col>
                                                                    <Col lg={'8'}>
                                                                        <a href='#' onClick={showDetails}>Details</a>
                                                                        
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        }
                                                        {
                                                            announcementArray[0] && announcementArray[0].accepted?
                                                            null
                                                            :
                                                            <>
                                                            {/* <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                <Row>
                                                                    <Col lg={'4'}>
                                                                        <Form.Label>Job:</Form.Label>
                                                                    </Col>
                                                                    <Col lg={'8'}>
                                                                        <a href='#' onClick={showDetails}>Details</a>
                                                                        
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group> */}
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Angebots Preis:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    <InputGroup>
                                                                    <Form.Control
                                                                    size='sm'
                                                                    value={price?String(price).replace('.', ','):''}
                                                                    onChange={(e) => {
                                                                        // console.log(price)
                                                                        setPrice(e.target.value.replace(',', '.'));
                                                                    }} 
                                                                    />
                                                                    <Button size="sm"
                                                                    // disabled='true'
                                                                    
                                                                    variant='outline-primary'
                                                                    // onClick={()=>{
                                                                    //     setDate()
                                                                    // }}
                                                                    >
                                                                        €
                                                                    </Button>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Form.Label>Notiz:</Form.Label>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                <Form.Control
                                                                        // autofocus={false}
                                                                        size="sm"
                                                                        as="textarea"
                                                                        defaultValue={note?note:''}
                                                                        rows={4}
                                                                        
                                                                        // defaultValue={}
                                                                        onChange={(e) => {
                                                                            setNote(e.target.value);
                                                                        }} 
                                                                        />
                                                                </Col>
                                                                
                                                            
                                                            </Row>
                                                        </Form.Group>
                                                        </>
                                                        }
                                                            <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                <Row>
                                                                    <Col lg={'4'}>
                                                                        <Form.Label>Unterlage:</Form.Label>
                                                                    </Col>
                                                                    <Col lg={'8'}>
                                                                    {!announcementArray[0] || !announcementArray[0].file?
                                                                        <Form.Control
                                                                        size='sm'
                                                                        type='file'
                                                                        accept="application/pdf"
                                                                        onChange={(e)=>{
                                                                            setFile(e.target.files[0])
                                                                        }}
                                                                        />
                                                                        :
                                                                        <a className="" target='_blank' href={announcementArray[0].file} download={announcementArray[0].file}>Mein Dokument</a>
                                                                        
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                        <Form.Group>
                                                        {announcementArray[0]?
                                                            <Row className="mb-3">
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>Historie{announcementArray[0] && announcementArray[0].newPrice?<Badge style={{marginLeft: '10px'}}>{announcementArray[0].newPrice.length}</Badge>:null}</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            {
                                                                                announcementArray[0] && announcementArray[0].newPrice?
                                                                                announcementArray[0].newPrice.reverse().map((price, idx)=>
                                                                            <Row className='mt-3' style={{fontSize: '12px'}}>
                                                                                {/* <Col lg='1'>
                                                                                    {idx+1}
                                                                                </Col> */}
                                                                                <Col lg='4'>
                                                                                    Preis: {String(price.price).replace('.', ',')} €<br/>
                                                                                    User: {price.userId == user.uid?user.displayName:users.find((el)=>el.id==price.userId).displayName}
                                                                                </Col>
                                                                                <Col lg='8'>
                                                                                    Notiz: {price.note}
                                                                                </Col>
                                                                            </Row>
                                                                            )
                                                                            :
                                                                            null
                                                                            }
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </Row>
                                                        :
                                                        null
                                                        }
                                                        </Form.Group>
                                                        {!announcementArray[0] || !announcementArray[0].accepted?
                                                        <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                            <Row>
                                                                <Col lg={'4'}>
                                                                    <Button size="sm" onClick={safeSettings}>Anbieten</Button>
                                                                </Col>
                                                                <Col lg={'8'}>
                                                                    
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        :
                                                        <>
                                                            <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                <Row>
                                                                    <Col lg={'12'} style={{color: 'green'}}>
                                                                        Der Auftrag wurde bestätigt
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                <Link to={'/tickets/none/none/none/'+selectedTicket.id}>Zum Ticket</Link>
                                                            </Form.Group>
                                                            <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                <Row>
                                                                    <Col lg={'4'}>
                                                                        <Form.Label>Rechnung:</Form.Label>
                                                                    </Col>
                                                                    <Col lg={'8'}>
                                                                    {!announcementArray[0] || !announcementArray[0].invoice?
                                                                        <Form.Control
                                                                        size='sm'
                                                                        type='file'
                                                                        accept="application/pdf"
                                                                        onChange={(e)=>{
                                                                            setInvoice(e.target.files[0])
                                                                        }}
                                                                        />
                                                                        :
                                                                        <a className="" target='_blank' href={announcementArray[0].invoice} download={announcementArray[0].invoice}>Mein Rechnung</a>
                                                                        
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            {announcementArray[0] && !announcementArray[0].invoice && invoice?
                                                                <Form.Group  className="mb-2" controlId="formGridDisplayName">
                                                                    <Row>
                                                                        <Col lg={'4'}>
                                                                            <Button size="sm" onClick={safeInvoice}>Abschliessen</Button>
                                                                        </Col>
                                                                        <Col lg={'8'}>
                                                                            
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            :
                                                            null
                                                            }
                                                        </>
                                                        }
                                                    </Row>
                                                </Tab>
                                            </Tabs>
                                            </Col>
                                        </Row>
                                        </Card.Body>
                                    </Card>
                                :
                                    null
                        }
                    </Col>
                    {/* <Col lg={'4'} md={{ order: 3 }} xs={{ order: 3 }}>
                        <Card className='mt-3'>
                            <Card.Header>
                                Ticket Kontingent Kaufen
                            </Card.Header>
                            <Card.Body>
                                Die ersten 10 Tickets sind kostenlos
                                <ListGroup defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}>
                                    <ListGroup.Item key='1' action onClick={()=>{stripTest({amount: 1, price: 2})}}>
                                        <Row>
                                            <Col lg={9}>
                                                Einzelnes Ticket
                                            </Col>
                                            <Col lg={3}>
                                                2 €
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item key='2' action onClick={()=>{stripTest({amount: 10, price: 1})}}>
                                        <Row>
                                            <Col lg={9}>
                                                10 Tickets
                                            </Col>
                                            <Col lg={3}>
                                                10 €
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item key='3' action onClick={()=>{stripTest({amount: 50, price: 0.7})}}>
                                        <Row>
                                            <Col lg={9}>
                                                50 Tickets
                                            </Col>
                                            <Col lg={3}>
                                                35 €
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
                <ModalCreateUser ticket={selectedTicket} closeAddUser={closeAddUser} addUser={addUser}/>
                <JobDetails ticket={selectedTicket} handleClose={closeDetails} show={details} announcement={announcementArray[0]}/>
            </Container>
            
        )
    // }
    // else {
    //     return (
    //         <h4 className="mt-5">Bisher sind keine Tickets verfügbar</h4>
    //     )
    // }
}

