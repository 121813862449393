import { useState, useEffect } from "react";
import { Input, List } from "antd";
import {Form} from "react-bootstrap";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const GoogleAutoComplete = ({ setAddress, setAddressId, setAddressLatLng }) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = useGoogle({
    apiKey: 'AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY',
    debounce: 1500,
    onPlaceSelected: (place) => {
        console.log(place);
        console.log(place.geometry.location);
      },
    
    options:{
    // types: ["(address)"],
    fields: ['ALL'],
    componentRestrictions: { country: "de" },
    }
    
  });
  const [value, setValue] = useState("");
  const [value2, setValue2] = useState(true);
  const [value3, setValue3] = useState("");

  useEffect(()=>{
    if (placePredictions.length)
    placesService?.getDetails(
      {
        placeId: placePredictions[0].place_id,
      },
      (placeDetails) => {
        setAddress(placeDetails.address_components); 
        setAddressLatLng({lat:  placeDetails.geometry.location.lat(), lng: placeDetails.geometry.location.lng()})
        console.log(placeDetails)
        // console.log(placeDetails.geometry.location.lat())

      }
    );
  }, [placePredictions])

  return (
    <div>
      {/* <span>Debounced</span> */}
      <Input.Search
        // style={{ color: "black" }}
        value={value}
        className="ant2-input"
        // as={Form.Control}
        // className='form-control'
        placeholder="Adresse suchen"
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
          setValue2(true)
          // console.log(evt)
        }}
        
        
        loading={isPlacePredictionsLoading}
      />
      <div
        style={{
          marginTop: "10px",
          width: "200px",
          height: "10px",
          display: "flex",
          flex: "1",
          flexDirection: "column",
        //   marginBottom: "100px",
          overflow: 'visible!important',
        }}
      >
        {!isPlacePredictionsLoading && value2 && (

          <List
            
            style={{overflow: 'visible', width: '250px'}}
            dataSource={placePredictions}
            renderItem={(item) => (
              <List.Item onClick={() => {setValue(item.description); setAddressId(item.place_id); setValue2(false); setValue3(item); console.log(item)}}>
                <List.Item.Meta title={item.description} />
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
};
// export default React.memo(MyComponent)