import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {GoogleAutoComplete} from "../tools/GoogleMaps"
import { db } from "../firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import { getAuth } from "firebase/auth";
import uuid from 'react-uuid';

export function ModalCreateBuilding({ show, handleClose, managerId}) {
  const [name, setName] = useState('');
  const [address, setAddress] = useState(false);
  const [addressLatLng, setAddressLatLng] = useState(false);
  const [addressId, setAddressId] = useState(false);
  const [plan, setPlan] = useState('');
  const [planUrl, setPlanUrl] = useState(false);


  async function formVal(e) {
    e.preventDefault();
    console.log(e);
    const auth = getAuth();
    const user = auth.currentUser;
    const storage = getStorage();

    // console.log(user.uid);
    if (name) {
      try {
        const image = ref(storage, 'floor/'+uuid())
        uploadBytes(image, plan)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              setPlanUrl(url);
        addDoc(collection(db, "building"), {
          name: name,
          managerId: managerId,
          address: address,
          addressId: addressId,
          addressLatLng: addressLatLng,
          rules: { [user.uid]: "owner" },
          created: serverTimestamp(),
          ifcUrl: url
        })
        // setManagerId(docRef.id);
        handleClose();
        setName('')
        
      })
      .catch((error) => {
        console.log(error.message);
      });
  })
  .catch((error) => {
    console.log(error.message);
  });
} catch (e) {
  console.error("Error adding document: ", e);
}
    }
  }
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Gebäude erstellen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mb-3 mt-3">
            {/* <Col lg={'6'}> */}
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }} />
            </Form.Group>
            {/* </Col>
            <Col lg={'6'}> */}
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Adresse</Form.Label>
              <GoogleAutoComplete setAddress={setAddress} setAddressId={setAddressId} setAddressLatLng={setAddressLatLng}/>
            </Form.Group>
            </Row>
            <Row className="mb-3">  
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Label>Ifc-Modell optional</Form.Label>
              <Form.Control type='file'
              onChange={(e) => {
                setPlan(e.target.files[0]);
              }}
              />
            </Form.Group>
            
            {/* </Col> */}
          </Row>
          <Button onClick={formVal} variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
