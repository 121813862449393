import React from "react";
import { useState, useEffect, useRef } from 'react';
import { messaging, auth, db  } from "../firebase";
import { doc, addDoc, getDoc, updateDoc, query, onSnapshot, collection, getDocs, where, and, or, serverTimestamp, orderBy} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";
import Moment from 'react-moment';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardFooter,
  MDBIcon,
  MDBBtn,
//   MDBScrollbar,
} from "mdb-react-ui-kit";
import '../chat.css'
import { FaPaperPlane, FaPaperclip, FaFileAlt } from 'react-icons/fa';
import uuid from 'react-uuid';

import { OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Modal } from "react-bootstrap";

export default function Chat({chatPartner, ticketId, users, selectedType}){

  const scrollRef = useRef(null)
  const messageEl = useRef(null);
  const messagesEndRef = useRef(null)
  const messagesEndRef2 = useRef(null)

  const [chatId, setChatId] = useState(false)
  const [messages, setMessages] = useState(false)
  // console.log(users)

  const [inputMessage, setinputMessage] = useState('')
  const [newMessage, setNewMessage] = useState([])
  const [fileUploadState, setFileUploadState] = useState([])
  // const [scrolled, setScrolled] = useState(null)
  const inputReference = React.createRef()

  const calendarStrings = {
    lastDay : '[Gestern ] HH:MM',
    sameDay : '[Heute ] HH:mm',
    nextDay : '[Morgen um] LT',
    lastWeek : '[Letzten] dddd [um] HH:MM',
    nextWeek : 'dddd [um] LT',
    sameElse : 'L [um] LT'
  };
  useEffect(() => {  
    setTimeout(()=>{
      scrollToBottom()
    }, 380)
    setTimeout(()=>{
      scrollToBottom2()
    }, 150)
  },[chatPartner]);
  useEffect(() => {  
    if(selectedType == 'chat'){
      setTimeout(()=>{
        scrollToBottom()
      }, 380)
      setTimeout(()=>{
        scrollToBottom2()
      }, 150)
    }
  },[selectedType]);
  useEffect(() => {  
    setTimeout(()=>{
      scrollToBottom()
    }, 380)
    setTimeout(()=>{
      scrollToBottom2()
    }, 150)
  },[messages]);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
    // messagesEndRef2.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  }
  const scrollToBottom2 = () => {
    messagesEndRef2.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
    // messagesEndRef2.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
  }
  // console.log(chatPartner)
  async function getMessages(){
    const q = query(collection(db, "ticket/"+ticketId+"/messages"), orderBy("created", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({...doc.data(), id: doc.id});
        // if(doc.data().userId == chatPartner.id){
        //   messages.push({text: doc.data().body, id: doc.id, position: "left", type: 'text'});
        // }
        // else {
        //   messages.push({text: doc.data().body, id: doc.id, position: "right", type: 'text'});
        // }
        
      });
      console.log('MessageCall')
      setMessages(messages.reverse())
      // console.log("Current cities in CA: ", messages.join(", "));
    });
    // setTimeout(()=>{
    //   scrollToBottom()
    // }, 180)
  }

  useEffect(()=>{
    if(ticketId){
      getMessages()
    }  
  }, [ticketId])

  function showTime({id}) {
    
    var target = document.getElementById('moment'+this.id)
    if (target.classList.contains('messageWrapper')){
      target.classList.remove('messageWrapper')
    }
    else {
      target.classList.add('messageWrapper')
    }
    

  }
  async function sendMessage(){
    if(fileUploadState.length > 0){
      const storage = getStorage();
      const name = inputReference.current.files[0].name;
      try {
        const image = ref(storage, 'messages/'+uuid())
        uploadBytes(image, inputReference.current.files[0])
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then(async (url) => {
              const docRef = await addDoc(collection(db, 'ticket/'+ticketId+'/messages'), {
                body: inputMessage,
                userId: auth.currentUser.uid,
                created: serverTimestamp(),
                url: url,   
                urlName: name,
              });
              
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
        
      } catch (e) {
        console.error("Error adding document: ", e);
      }
      setFileUploadState([])
      inputReference.current.value = ''
    }
    else {

      const docRef = await addDoc(collection(db, 'ticket/'+ticketId+'/messages'), {
        body: inputMessage,
        userId: auth.currentUser.uid,
        created: serverTimestamp(),      
      });
    }
    scrollToBottom()
    setinputMessage('')
  }
  const fileUploadAction = () => inputReference.current.click();
  const fileUploadInputChange = (e) => {setFileUploadState(e.target.value)};


    return(
      // <div stlye={{height: '20vh'}}>
      <>
        <MDBContainer fluid className="" style={{ padding: 0}}>
            <div className="ChatBox2" ref={messageEl}  style={{ backgroundColor: "#fff", borderRadius: '5px' }}>
              <MDBCardBody>
                {messages?
                    messages.map((element, idx)=>
                    element.userId != auth.currentUser.uid?
                    <div key={idx} className="d-flex flex-row justify-content-start">
                    <div>
                      {
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] != 'pdf' ?
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3 mouseFinger"
                        style={{ backgroundColor: "#f5f6f7", width: "90%"}}
                        onClick={showTime.bind({id: idx})}
                      >
                        <img src={element.url}
                        style={{ width: "100%", height: "100%" }}
                        />
                      </p>
                      :null
                      }
                      { 
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] == 'pdf'?
                      <p
                        className="small p-2 ms-3 mb-1 rounded-3 mouseFinger"
                        style={{ backgroundColor: "#f5f6f7", width: "90%"}}
                      >
                      
                      <a className="btn btn-secondary" target='_blank' href={element.url} download={element.urlName}>{element.urlName}</a>
                    </p>
                      :null
                      }

                      <div
                        className="small p-2 ms-3 mb-1 rounded-3 mouseFinger"
                        style={{backgroundColor: '#f5f6f7', width: "90%"}}
                        onClick={showTime.bind({id: idx})}
                      >
                      {
                        // activeContact?
                        //   <div style={{color: activeContact.color}} className="divWrappMessage">{activeContact.displayName}</div>
                        //   :
                          <div style={{color: users.find(el=>el.id==element.userId).color}} className="divWrappMessage">{users.find(el=>el.id==element.userId).displayName}</div>
                    
                      }
                        {element.body}
                        
                      </div>
                      <p id={'moment'+idx} className="mb-7 small ms-3 mb-1 rounded-3 text-muted messageWrapper">
                        <Moment calendar={calendarStrings}>{element.created.toDate()}</Moment>
                      </p>
                    </div>
                  </div>
                  :                
                  <div  key={idx} className="d-flex flex-row justify-content-end pt-1"
                    >
                    <div>
                      {
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] != 'pdf'?
                        <p
                          className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary mouseFinger"
                          onClick={showTime.bind({id: idx})}
                        >
                          <img src={element.url}
                          style={{ width: "100%", height: "100%" }}
                          />
                        </p>
                      :null
                      }
                      { 
                      element.url && element.urlName && element.urlName.split('.')[element.urlName.split('.').length-1] == 'pdf'?
                      <p
                      className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary mouseFinger"
                    >
                      
                      <a className="btn btn-secondary" target='_blank' href={element.url} download={element.urlName}>{element.urlName}</a>
                    </p>
                      :null
                      }
                      <p className="small p-2 me-3 mb-1 text-white rounded-3 bg-primary mouseFinger"
                      onClick={showTime.bind({id: idx})}
                      
                      >
                      {element.body}
                      </p>
                      
                      <p id={'moment'+idx} className="small me-3 mb-1 rounded-3 text-muted d-flex justify-content-end messageWrapper">
                      {element.created?
                      <Moment calendar={calendarStrings}>{element.created.toDate()}</Moment>
                      :null
                      }
                      </p>
                    </div>
                  </div>
                    )
                  :null
                }
                <div ref={messagesEndRef}/>
              </MDBCardBody>
              </div>
            {/* <hr/> */}
            <MDBCardFooter style={{background: '#f8f9fa', border: 'none'}} className="text-muted d-flex justify-content-start align-items-center">
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="Type message"
                value={inputMessage}
                onChange={(e) => {
                  const value = e.target.value
                  setinputMessage(value)
                }}
                onKeyDown={(e)=>{
                  
                  if(e.key === 'Enter'){
                    sendMessage()
                    e.preventDefault();
                  }
                }}
              ></input>
              <a className="ms-1 text-muted" href="#!">
                
              </a>
              <a className="ms-3 text-muted" onClick={fileUploadAction}>
                
                {
                fileUploadState.length > 0?
                <FaFileAlt/>:<FaPaperclip/>
                
                }
              </a>
              <input type="file" hidden ref={inputReference} onChange={fileUploadInputChange} accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
              <a className="ms-3" onClick={sendMessage}>
                <FaPaperPlane />
              </a>
            </MDBCardFooter>
          </MDBContainer>
          <div  ref={messagesEndRef2}/>
          </>
          // </div>
    )
}