import React, {useEffect, useState} from "react";
import { collection, addDoc, serverTimestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase";
import { getAuth, updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, loadBundle} from "firebase/storage";
import Dexie from 'dexie';
import { useLiveQuery } from "dexie-react-hooks";
import { useParams } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
import { Col, Button, Row, Container, Card, Form, InputGroup, Image } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import '../css/Profil.css';
import { MdVisibility, MdVisibilityOff, MdCheck, MdDelete } from "react-icons/md";
import { Typeahead } from 'react-bootstrap-typeahead'; 
import {GoogleAutoComplete} from "../tools/GoogleMaps"
import { Input } from "antd";

export default function UserPage({dataMain, userData, setUserData, skills}) {
    let { id } = useParams();
    // console.log(id)
    // const auth = getAuth();
    const user = auth.currentUser;
    const inputReference = React.createRef()
    const [fileUploadState, setFileUploadState] = useState([])
    const [urlNewImage, setUrlNewImage] = useState(false);
    const [externUser, setExternUser] = useState(true);
    const [userById, setUserById] = useState(false)
    const [docRef, setDocRef] = useState(false)
    const [displayName, setDisplayName] = useState(false)
    const [email, setEmail] = useState(false)
    const [firstName, setFirstName] = useState(false)
    const [lastName, setLastName] = useState(false)
    const [publicA, setPublicA] = useState(false)
    const [updateDone, setUpdateDone] = useState(false)
    const [update2Done, setUpdate2Done] = useState(false)
    const [emailNotification, setEmailNotification] = useState(false)
    const [emailChat, setEmailChat] = useState(false)
    const [emailMerge, setEmailMerge] = useState(false)
    const [emailJobs, setEmailJobs] = useState(false)
    const [pushNotification, setPushNotification] = useState(false)
    const [pushChat, setPushChat] = useState(false)
    const [pushMerge, setPushMerge] = useState(false)
    const [pushJobs, setPushJobs] = useState(false)
    const [handyman, setHandyman] = useState(false)
    const [offlineImage, setOfflineImage] = useState(false)
    const [address, setAddress] = useState(false)
    const [addressLatLng, setAddressLatLng] = useState(false);
    const [addressId, setAddressId] = useState(false)
    const [mySkills, setMySkills] = useState(false)
    const [range, setRange] = useState(false)


    const [weakPas, setWeakPas] = useState(false);
    const [password, setPassword] = useState('')
    const [passwordVisible, setPasswordVisible] = useState("password")

    const handleVis = () => {
        if(passwordVisible == "password"){
            setPasswordVisible('text')
        }
        else {
            setPasswordVisible('password')
        }
    }
    const handleHandyman = () => {
        if(handyman){
            setHandyman(false)
        }
        else {
            setHandyman(true)
        }
    }
    const handlepublicA = () => {
        if(publicA){
            setPublicA(false)
        }
        else {
            setPublicA(true)
        }
    }
    const [password2, setPassword2] = useState('')
    const [passwordDone, setPasswordDone] = useState(false)
    const [passwordWrong, setPasswordWrong] = useState(false)
    const [passwordWeak, setPasswordWeak] = useState(false)
    const [passwordVisible2, setPasswordVisible2] = useState("password")

    const handleVis2 = () => {
        if(passwordVisible2 == "password"){
            setPasswordVisible2('text')
        }
        else {
            setPasswordVisible2('password')
        }
    }
    
    const getImage =  () => {
        const indexddb =  window.indexedDB.open("ImageData");
        indexddb.onsuccess = async function (e) {
            console.log("success...");
            // const db12= e.target.result;
            const request = await e.target.result.transaction('friends')
                   .objectStore('friends')
                   .getAll();

            request.onsuccess = function(){
                console.log(request.result)
                if(request.result.find(el => el.name == userData.photoURL)){
                    const blob = new Blob( [ request.result.find(el => el.name == userData.photoURL).array ] );
                    const url = URL.createObjectURL( blob );
                    setOfflineImage(url)
                    console.log(url)
                    console.log(request.result.find(el => el.name == userData.photoURL))
                }
                else {
                    setOfflineImage(false)
                }
            }

        };
        
    }

    const getUserData = async () => {

        

        // const docRef = doc(db, "user", auth.currentUser.uid);
        // const docSnap = await getDoc(docRef);
        
        // if (docSnap.exists()) {
        //     await setUserData({...docSnap.data(), id: docSnap.id})
        // }
        console.log(userData)
        // getUserData().then((response)=>{
        if(user.uid === id && userData){
            getImage()
            setUserById(userData)
            setDisplayName(userData.displayName)
            setEmail(userData.email)
            setFirstName(userData.firstName)
            setLastName(userData.lastName)
            // setPublicA(userData.publicA)
            setExternUser(false)
            setDocRef(doc(db, "user", id))
            setEmailNotification(userData.emailNotification)
            setEmailChat(userData.emailChat)
            setEmailMerge(userData.emailMerge)
            setPushNotification(userData.pushNotification)
            setPushChat(userData.pushChat)
            setPushMerge(userData.pushMerge)
            setHandyman(userData.handyman)
            setPublicA(userData.public)
            setAddressId(userData.addressId)

            if(userData.range){
                setRange(userData.range)
            }
            else {
                setRange(10)
            }
        }
        else {
            
            const docRef = doc(db, "user", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("Document data:", docSnap.data());
                const data = docSnap.data()
                setUserById({...data, id: docSnap.id})
                setDisplayName(data.displayName)
                setEmail(data.email)
                setFirstName(data.firstName)
                setLastName(data.lastName)
                setPublicA(data.public)
                setOfflineImage(data.photoURL)
            } else {
            // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
    }
    useEffect(()=>{
        getUserData()
        console.log('fetch')
    }, [])
    useEffect(()=>{
        getUserData()
        console.log('fetch')
    }, [userData])
    
    



    
    const addData = async () => {
        const docRef = await addDoc(collection(db, "management"), {
            name: 'tets',
            rules: { ['sadfsadf']: "owner" },
            created: serverTimestamp()
        });
        console.log("Document written with ID: ", docRef.id);
    }
    
    const fileUploadAction = () => inputReference.current.click();
    const fileUploadInputChange = (e) => {
        setFileUploadState(e.target.value)
        setUrlNewImage(URL.createObjectURL(inputReference.current.files[0]))
    };

    const ChangeUserPassword = async (e) => {
        e.preventDefault()
        const credential = EmailAuthProvider.credential(
            user.email,
            password
        );
        await reauthenticateWithCredential(user, credential).then(()=>{
            updatePassword(user, password2).then(() => {
                setPasswordWrong(false)
                setPasswordWeak(false)
                setPassword('')
                setPassword2('')
                setPasswordDone(true)
                
    
            }).catch((error) => {
                console.log(error.code)
                if(error.code === 'auth/weak-password'){
                    setPasswordWeak(true)            
                }
                
                
            });
        }).catch((error)=>{
            if(error.code === 'auth/wrong-password'){
                setPasswordWrong(true)            
            }
        })

    }

    const ChangeUserData = async (e) => {
        e.preventDefault()
        if(fileUploadState.length > 0){
            const storage = getStorage();
            const name = inputReference.current.files[0].name;
            try {
                const image = ref(storage, 'userProfil/'+auth.currentUser.uid)
                uploadBytes(image, inputReference.current.files[0])
                .then((snapshot) => {
                  getDownloadURL(snapshot.ref)
                    .then(async (url) => {
                      updateProfile(auth.currentUser, {
                          displayName: displayName,
                          email: email,
                          photoURL: url,
                        }).then(() => {
                          console.log('Profile updated with Image!')
                          updateDoc(docRef, {
                            displayName: displayName,
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            photoURL: url,
                            public: publicA,
                            handyman: handyman,
                          }).then(async (res)=>{
                            // const docRef = doc(db, "user", auth.currentUser.uid);
                            // const docSnap = await getDoc(docRef);
                            
                            // if (docSnap.exists()) {
                            //   setUserData({...docSnap.data(), id: docSnap.id})
                            // }
                            
                            console.log(res);setUpdateDone(true)
                        }).catch((error) => {
                          console.log('An error occurred')
                        });
                        })
                      
                    })
                    .catch((error) => {
                      console.log(error.message);
                    });
                })
                .catch((error) => {
                  console.log(error.message);
                });
                
              } catch (e) {
                console.error("Error adding document: ", e);
              }
            }
            else {
              updateProfile(auth.currentUser, {
                  displayName: displayName,
                  email: email,
                }).then(async () => {
                  console.log('Profile updated!')
                  console.log(displayName)
                  console.log(email)
                  console.log(firstName)
                  console.log(lastName)
                  console.log(publicA)
                  console.log(handyman)
                  
                  await updateDoc(docRef, {
                      displayName: displayName,
                      email: email,
                      firstName: firstName,
                      lastName: lastName,
                      public: publicA,
                      handyman: handyman,
                  }).then(async (res)=>{
                    // const docRef = doc(db, "user", auth.currentUser.uid);
                    // const docSnap = await getDoc(docRef);
                    
                    // if (docSnap.exists()) {
                    //   setUserData({...docSnap.data(), id: docSnap.id})
                    // }
                    
                    console.log(res);setUpdateDone(true)
                }).catch((error) => {
                  console.log('An error occurred')
                });
                })
            }
        // }
    }
    const handyManSetting = async () => {
        console.log('handyManSetting')
        console.log(mySkills)
        console.log(address)
        console.log(addressId)
        console.log(emailJobs)
        console.log(pushJobs)
        var newData = {
            emailJobs: emailJobs,
            pushJobs: pushJobs,
            range: range,
        }
        if(mySkills){
            newData['mySkills'] = mySkills
        }
        if(address){
            newData['address'] = address
        }
        if(addressId){
            newData['addressId'] = addressId
        }
        if(emailJobs){
            newData['emailJobs'] = emailJobs
        }
        if(pushJobs){
            newData['pushJobs'] = pushJobs
        }

        await updateDoc(doc(db, "user", auth.currentUser.uid), newData).then((res)=>{
            setUpdate2Done(true)
        })
    }

    // console.log(dataMain)

    // if (!dataMain){
    //     return (
            
    //         <>
    //             {
    //                 dataMain.map((element, idx)=>
    //                     <>
    //                         <h3>Hello there user {id}</h3>
    //                         <p>{element.id}</p>
    //                     </>
    //                 )
                
    //             }
    //             <button onClick={addData}>test</button>
    //         </>

    //     );
    // }
    // else {
        return(
            <>
                <Container className="mt-3">
                    <Row>
                        <Col lg={'4'} className="">
                            {/* <GetImage/> */}
                        <Card className="align-items-center mb-3" style={{border: 'none'}}>
                            <Card.Body >
                                
                                <div>
                                    <Image rounded src={urlNewImage?urlNewImage:offlineImage?offlineImage:userById.photoURL?userById.photoURL:'/defaultProfil.png'} style={{ width: '180px', borderRadius: '10px'}}/>
                                    {/* <Image rounded src={offlineImage} style={{ width: '180px', borderRadius: '10px'}}/> */}
                                </div>
                                <div>
                                    <button style={{border: 'none'}} disabled={externUser} className="btn" onClick={fileUploadAction}><FiUpload size={'22'}/> Bild Hochladen</button>
                                    <input type="file" hidden ref={inputReference} onChange={fileUploadInputChange} accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"/>
                                </div>
                            </Card.Body>
                            
                        </Card>
                        {/* <Card className="shadow align-items-center mb-3" bg="">
                            <Card.Body >
                                
                            </Card.Body>
                            
                        </Card>
                         */}
                        </Col>
                        <Col lg={'8'}>
                            <Card className="shadow mb-3">
                                <Card.Body>
                                    <h4>Profil</h4>
                                    <Form onSubmit={ChangeUserData}>
                                        <Row>
                                            <Form.Group className="mb-3" as={Col} lg={'6'} controlId="formGridDisplayName">
                                                <Form.Label>Benutzername</Form.Label>
                                                <Form.Control type="text" value={displayName} disabled={externUser} onChange={(e)=>{setDisplayName(e.target.value)}} placeholder="Benutzername" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" as={Col} lg={'6'} controlId="formGridEmail">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" value={email} disabled={externUser} onChange={(e)=>{setEmail(e.target.value)}} placeholder="Enter email" />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group className="mb-3" as={Col} lg={'6'} controlId="formGridFirstName">
                                                <Form.Label>Vorname</Form.Label>
                                                <Form.Control type="text" value={firstName} disabled={externUser} onChange={(e)=>{setFirstName(e.target.value)}} placeholder="Vorname" />
                                            </Form.Group>
                                            <Form.Group className="mb-3" as={Col} lg={'6'} controlId="formGridLastName">
                                                <Form.Label>Nachname</Form.Label>
                                                <Form.Control type="text" value={lastName} disabled={externUser} onChange={(e)=>{setLastName(e.target.value)}} placeholder="Nachname" />
                                            </Form.Group>
                                        </Row>
                                        {
                                            user.uid === id?

                                        <>
                                        <Row>
                                            <Form.Group as={Col} lg={'6'}  className="mb-3" id="formGridCheckbox">
                                                <Form.Check type="checkbox" label="Öffentlich" checked={publicA} onClick={handlepublicA} />
                                            </Form.Group>
                                            <Form.Group as={Col} lg={'6'}  className="mb-3" id="formGridCheckbox">
                                                <Form.Check type="checkbox" label="Handwerker" checked={handyman} onClick={handleHandyman} />
                                            </Form.Group>
                                        </Row>
                                        <Button disabled={externUser} variant="primary" type="submit">
                                            Aktualisieren
                                        </Button>{updateDone?
                                                <>{' '}<MdCheck size="28" color="green"/></>
                                            :null
                                            }
                                            </>
                                        :
                                        null
                                        }
                                    </Form>
                                </Card.Body>
                            </Card>
                            {handyman?
                             <Card className="shadow mb-3">
                             <Card.Body>
                                 <h4>Tätigkeiten</h4>
                                 {/* <Form onSubmit={ChangeUserPassword}> */}
                                     <Row>
                                         
                                         <Col lg="6" className="mb-3">
                                         <Form.Group controlId="address">
                                            <Form.Label>Adresse {addressId?<MdDelete onClick={()=>setAddressId(false)}/>:null}</Form.Label>
                                            {addressId?
                                                <>
                                                    <iframe
                                                        width="100%"
                                                        height="350"
                                                        style={{borderRadius:'10px'}}
                                                        loading="lazy"
                                                        allowFullScreen
                                                        referrerPolicy="no-referrer-when-downgrade"
                                                        // src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&query_place_id='+building.addressId}
                                                        src={"https://www.google.com/maps/embed/v1/place?key=AIzaSyCLibxXHB4Rfd0d3FE3PkFL6u3LlieW7RY&q=place_id:"+userData.addressId}
                                                    >
                                                    </iframe>
                                                    
                                                </>
                                            :
                                            <GoogleAutoComplete  setAddressLatLng={setAddressLatLng} setAddress={setAddress} setAddressId={setAddressId}/>
                                            }
                                            
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Umkreis</Form.Label>
                                            <Row>
                                            <Col lg={'8'}>
                                            <Form.Range value={String(Math.sqrt(range)).split('.')[0]}
                                            onChange={(e)=>{setRange(e.target.value*e.target.value)}}
                                            />
                                            </Col>
                                            <Col lg={'4'}>
                                                {range} km
                                            </Col>
                                            </Row>
                                            
                                        </Form.Group>
                                         {/* <Form.Check // prettier-ignore
                                             className="mt-3"
                                             type="switch"
                                             id="email-switch"
                                             label="Chat-Nachrichten per Email"
                                             checked={emailChat}
                                             onChange={(e)=> {setEmailChat(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{emailChat:e.target.checked})}}
                                         />
                                         <Form.Check // prettier-ignore
                                             className="mt-3"
                                             type="switch"
                                             id="email-switch"
                                             label="News zur Plattform per Email"
                                             checked={emailMerge}
                                             onChange={(e)=> {setEmailMerge(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{emailMerge:e.target.checked})}}
                                         /> */}
                                         </Col>
                                         <Col lg="6" className="mb-3">
                                         {/* <Form.Label><h5>Aufgaben</h5></Form.Label>
                                         <Form.Group  className="mb-2" controlId="formGridDisplayName"> */}
                                            <Row>
                                                {/* <Col lg={'4'}>
                                                    <Form.Label>Teilnehmer:</Form.Label>
                                                </Col>
                                                <Col lg={'8'}> */}
                                                {skills?
                                                <Form.Group controlId="skills">
                                                    <Form.Label>Tätigkeiten</Form.Label>
                                                    {/* <InputGroup> */}
                                                    <Typeahead
                                                        // size="sm"
                                                        labelKey="name"
                                                        defaultSelected={userData.mySkills && skills?skills.filter(skill => userData.mySkills.find(el => el.id == skill.id)):[]}
                                                        // defaultSelected={users.filter(user=>selectedTicket.visible.find(el=>el == user.id)&&user.id!=selectedTicket.responsible)}
                                                        id="public-methods-example"
                                                        multiple
                                                        options={skills}
                                                        placeholder="Choose skills..."
                                                        onChange={(e)=> setMySkills(e)}
                                                    />

                                                    {/* <Button size="sm"
                                                    variant='outline-primary'
                                                    // onClick={()=>{
                                                    //     showAddUser()
                                                    // }}
                                                    >
                                                    test
                                                        
                                                    </Button> */}
                                                    {/* </InputGroup> */}
                                                    </Form.Group>
                                                    :null}
                                            </Row>
                                            
                                        {/* </Form.Group> */}
                                         <Form.Check // prettier-ignore
                                             className="mt-3"
                                             type="switch"
                                             id="email-switch"
                                             label="Aufträge Schicken per Email"
                                            //  checked={pushChat}
                                             defaultChecked={userData.emailJobs}
                                             onChange={(e)=> {setEmailJobs(e.target.checked)}}
                                         />
                                         <Form.Check // prettier-ignore
                                             className="mt-3"
                                             type="switch"
                                             id="email-switch"
                                             label="Aufträge Schicken per Push-Nachrichten"
                                             defaultChecked={userData.pushJobs}
                                             onChange={(e)=> {setPushJobs(e.target.checked)}}
                                         />
                                         </Col>
                                     </Row>
                                     <Button  variant="primary" onClick={handyManSetting}>
                                        Aktualisieren
                                    </Button>{update2Done?
                                                <>{' '}<MdCheck size="28" color="green"/></>
                                            :null
                                            }
                                 {/* </Form> */}
                             </Card.Body>
                         </Card>
                            :
                            null
                            }
                            {
                            !externUser?
                                <>
                                <Card className="shadow mb-3">
                                    <Card.Body>
                                        <h4>Mitteilungen</h4>
                                        {/* <Form onSubmit={ChangeUserPassword}> */}
                                            <Row>
                                                
                                                <Col lg="6" className="mb-3">
                                                <Form.Label><h5>Emails</h5></Form.Label>
                                                <Form.Check // prettier-ignore
                                                    className="mt-3"
                                                    type="switch"
                                                    id="email-switch"
                                                    label="Benachrichtigung per Email"
                                                    checked={emailNotification}
                                                    onChange={(e)=> {setEmailNotification(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{emailNotification:e.target.checked})}}
                                                />
                                                <Form.Check // prettier-ignore
                                                    className="mt-3"
                                                    type="switch"
                                                    id="email-switch"
                                                    label="Chat-Nachrichten per Email"
                                                    checked={emailChat}
                                                    onChange={(e)=> {setEmailChat(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{emailChat:e.target.checked})}}
                                                />
                                                <Form.Check // prettier-ignore
                                                    className="mt-3"
                                                    type="switch"
                                                    id="email-switch"
                                                    label="News zur Plattform per Email"
                                                    checked={emailMerge}
                                                    onChange={(e)=> {setEmailMerge(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{emailMerge:e.target.checked})}}
                                                />
                                                </Col>
                                                <Col lg="6" className="mb-3">
                                                <Form.Label><h5>Push-Nachrichten</h5></Form.Label>
                                                <Form.Check // prettier-ignore
                                                    className="mt-3"
                                                    type="switch"
                                                    id="email-switch"
                                                    label="Benachrichtigung per Push-Nachrichten"
                                                    checked={pushNotification}
                                                    onChange={(e)=> {setPushNotification(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{pushNotification:e.target.checked})}}
                                                />
                                                <Form.Check // prettier-ignore
                                                    className="mt-3"
                                                    type="switch"
                                                    id="email-switch"
                                                    label="Chat-Nachrichten per Push-Nachrichten"
                                                    checked={pushChat}
                                                    onChange={(e)=> {setPushChat(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{pushChat:e.target.checked})}}
                                                />
                                                <Form.Check // prettier-ignore
                                                    className="mt-3"
                                                    type="switch"
                                                    id="email-switch"
                                                    label="News zur Plattform per Push-Nachrichten"
                                                    checked={pushMerge}
                                                    onChange={(e)=> {setPushMerge(e.target.checked);updateDoc(doc(db, "user", auth.currentUser.uid),{pushMerge:e.target.checked})}}
                                                />
                                                </Col>
                                            </Row>
                                        {/* </Form> */}
                                    </Card.Body>
                                </Card>
                                <Card className="shadow mb-3">
                                    <Card.Body>
                                        <h4>Authentifizierung</h4>
                                        <Form onSubmit={ChangeUserPassword}>
                                            <Row>
                                                <Col lg="6" className="mb-3">
                                                <Form.Label>Altes Passwort{' '}<span style={{color: "red"}}>{passwordWrong?'Falsches Passwort':null}</span></Form.Label>
                                                    <InputGroup className="">
                                                    
                                                        <Form.Control required type={passwordVisible} placeholder="Old Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                                                        <Button variant="outline-secondary" id="basic-addon1" onClick={handleVis}>
                                                            {passwordVisible === 'password'?
                                                                <MdVisibility/>
                                                                :
                                                                <MdVisibilityOff/>
                                                            }
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                                <Col lg="6" className="mb-3">
                                                <Form.Label>Neues Passwort{' '}<span style={{color: "red"}}>{passwordWeak?'Schwaches Passwort':null}</span></Form.Label>
                                                    <InputGroup className="">
                                                    
                                                        <Form.Control required type={passwordVisible2} placeholder="New Password" value={password2} onChange={(e)=>{setPassword2(e.target.value)}}/>
                                                        <Button variant="outline-secondary" id="basic-addon2" onClick={handleVis2}>
                                                            {passwordVisible2 === 'password'?
                                                                <MdVisibility/>
                                                                :
                                                                <MdVisibilityOff/>
                                                            }
                                                        </Button>
                                                        {/* {
                                                        passwordWeak?
                                                            <Form.Control.Feedback style={{color: 'red'}}>
                                                                Schwaches Passwort
                                                            </Form.Control.Feedback>
                                                        :
                                                            null
                                                        } */}
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Button disabled={externUser} variant="primary" type="submit">
                                                Aktualisieren
                                            </Button>
                                            {passwordDone?
                                                <>{' '}<MdCheck size="28" color="green"/></>
                                            :null
                                            }
                                        </Form>
                                    </Card.Body>
                                </Card>
                                <Card className="shadow mb-5">
                                    <Card.Body>
                                        <h4>Account</h4>
                                        <p>Wenn sie den Account löschen ist dies nicht rückgäng zu machen.</p>
                                        {/* <Form onSubmit={ChangeUserPassword}> */}
                                            <Row>
                                                <Col lg="6" className="mb-3">
                                                    <Button variant="warning">Pausieren</Button>
                                                </Col>
                                                <Col lg="6" className="mb-3">
                                                    <Button variant="danger">Löschen</Button>
                                                </Col>
                                            </Row>
                                        {/* </Form> */}
                                    </Card.Body>
                                </Card>
                            </>
                            :
                            null
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        )
    // }
}