import React from 'react';
import { useState, useEffect } from 'react';
import { Route, Routes } from "react-router-dom"
import { messaging, auth, db  } from "./firebase";
import {  getToken, onMessage } from "firebase/messaging";
import { doc, updateDoc, query, onSnapshot, collection, arrayUnion, where, documentId } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import HomePage from "./pages/HomePage"
import UserPage from "./pages/UserPage"
import Map from "./Map"
import Tickets from "./pages/Tickets"
import Jobs from "./pages/Jobs"
import ToastAlert from "./tools/ToastAlert"
import Login from "./pages/Login"
import Regist from "./pages/Regist"
import FinishSignUp from "./pages/FinishSignUp"
import PasswordReset from "./pages/PasswordReset"
import NavBar from "./pages/NavBar"
import Messages from "./Messages"
import GetIfc from "./pages/Ifc"

import SafeNewImages from "./indexeddb"
import './App.css';

function App() {
  const [dataMain, setDataMain] = useState(false)
  const [user, setUser] = useState(false)
  const [userData, setUserData] = useState(false)
  const [managers, setManagers] = useState(false)
  const [buildings, setBuildings] = useState(false)
  const [floors, setFloors] = useState(false)
  const [floors1, setFloors1] = useState(false)
  const [floors2, setFloors2] = useState(false)
  const [tickets, setTickets] = useState(false)
  const [skills, setSkills] = useState(false)
  const [users, setUsers] = useState(false)
  const [notifications, setNotifications] = useState(false)
  const [checkPermission, setCheckPermission] = useState(false)
  const [selectedType, setSelectedType] = useState('map')
  useEffect(()=>{
    try{navigator.clearAppBadge()
    localStorage.setItem('badge', 0)}
    catch{}
  },[notifications])
  async function requestPermission({userData, docRef2}){
    
    const permission = await Notification.requestPermission()
    if (permission === 'granted'){
      setCheckPermission(true)
      if(localStorage.getItem("tokenP")){
        if(!userData.fcmToken.includes(localStorage.getItem("tokenP"))){
          const token = await getToken(messaging, {vapidKey: 'BMfWCBP2-Ak-aEpaV69s91FGVAM9T15rLg4tCGGY1nehvpXD0BCMacfUuu3cLdGNVBWJ-kjcV9Ku1_R0MX1zVeQ'})
          localStorage.setItem("tokenP", token);
          if(auth.currentUser.uid){
            await updateDoc(docRef2, {
              fcmToken: arrayUnion(token)
            });
          }
        }
      }
      else {
        const token = await getToken(messaging, {vapidKey: 'BMfWCBP2-Ak-aEpaV69s91FGVAM9T15rLg4tCGGY1nehvpXD0BCMacfUuu3cLdGNVBWJ-kjcV9Ku1_R0MX1zVeQ'})
        localStorage.setItem("tokenP", token);
        if(auth.currentUser.uid){
          await updateDoc(docRef2, {
            fcmToken: arrayUnion(token)
          });
        }
      }
    } else if (permission === 'denied'){
      console.log('denied')
    } else {
      console.log(permission)
    }
    
  }
  // onMessage(messaging, (payload) => {
  //   console.log('Message received. ', payload);
  //   // ...
  // });

  // useEffect(()=>{
  //   console.log('this Auth', auth)
  // }, [auth.currentUser])

  useEffect(()=>{
    if (!managers && user){
      managerSnap()
    }
    if(!users && user){
        userSnap()
    }
  },[user])
  useEffect(()=>{
    getAllFloors()
  },[floors1, floors2])

  const getAllFloors = () => {
    var array = []
    if(floors1.length >= 1 && floors2.length >= 1){
      floors1.forEach((floor)=>{
        array.push(floor)
      })
      floors2.forEach((floor)=>{
        if (array.find((el)=> el.id == floor.id)){
          // console.log('da')
        }
        else {
          array.push(floor)
        }
        
      })
    }
    else if (floors1.length >= 1){
      array = floors1
    }
    else if (floors2.length >= 1){
      array = floors2
    }
    console.log(array)
    setFloors(array)
  }
  // useEffect(()=>{
  //   if (!managers && auth.currentUser && auth.currentUser.uid){
  //     managerSnap()
  //   }
    
  // },[auth.currentUser])

  useEffect(()=>{
    if (!buildings && managers && managers.length >0){
      buildingSnap()
    }
  }, [managers])
  useEffect(()=>{
    if (buildings){
      floorSnap()
    }
  }, [buildings])
  useEffect(()=>{
    if (tickets){
      floorSnapTickets()
      // console.log('floorTicketSnap')
      // console.log(tickets)
    }
  }, [tickets])

  const managerSnap = async () => {

// console.log()
    // if(auth.currentUser.uid){
      // console.log('user: ', user)
    const q = query(collection(db, "management"), where('rules.'+auth.currentUser.uid, '!=', false));
    const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
      const managersrray = [];
      querySnapshot.forEach((doc) => {
        // if()
        managersrray.push({...doc.data(), id: doc.id});
        // console.log(doc)
        
      });
      
      if(managersrray.length > 0 && managers != managersrray){
        
        setManagers(managersrray)
      }
      
    });
  // }
  }
  const buildingSnap = async () => {
    const q = query(collection(db, "building"), where('managerId', 'in', managers.map(a => a.id)));
    const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
      const array = [];
      querySnapshot.forEach((doc) => {
        array.push({...doc.data(), id: doc.id});
        // console.log(doc)
        
      });
      // console.log(array)
      // console.log(managers.map(a => a.id))
      if(array.length>0 && array != buildings){
        
        setBuildings(array)
      }
      
    });

  }
  const floorSnap = async () => {
      const q = query(collection(db, "floor"), where('buildingId', 'in', buildings.map(a => a.id)));
      const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
        const array = [];
        querySnapshot.forEach((doc) => {
          array.push({...doc.data(), id: doc.id});
          // console.log(doc)
          
        });
        if(array.length > 0 && array != floors1){
          // console.log('fRow', array)
          setFloors1(array)
        }
        
      });
  }
  const floorSnapTickets = async () => {
      const q = query(collection(db, "floor"), where(documentId(), 'in', tickets.map(a => a.floorId)));
      const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
        const array = [];
        querySnapshot.forEach((doc) => {
          array.push({...doc.data(), id: doc.id});
          // console.log(doc)
          
        });
        if(array.length > 0 && array != floors2){
          // console.log('ffRow', array)
          setFloors2(array)
        }
        
      });
  }
  const ticketSnap = async () => {
    const q = query(collection(db, "ticket"), where('visible', 'array-contains', user.uid));
    const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
      const array = [];
      querySnapshot.forEach((doc) => {
        array.push({...doc.data(), id: doc.id});
        // console.log(doc)
        
      });
      // console.log(array)
      if(array.length>0 && array != tickets){
        // console.log('ticketArray',array[0].created)

        setTickets(array)
        // console.log(array)
      }
      
    });

  }
  const userSnap = async () => {
    const q = query(collection(db, "user"));
    const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
      const array = [];
      if(auth.currentUser){
        querySnapshot.forEach((doc) => {
          if(doc.id != auth.currentUser.uid){
            array.push({...doc.data(), id: doc.id});
          }
          // console.log(doc)
          
        });
      }
      // console.log(array)
      setUsers(array)
      
    });

  }


  const getUser = async () =>{
    try{
      const unsub = onSnapshot(doc(db, "user", auth.currentUser.uid), (docSnap) => {
          // console.log("Current data: ", doc.data());
          setUserData({...docSnap.data(), id: docSnap.id})
          
      });
      const q = query(collection(db, "user/"+auth.currentUser.uid+'/messages'));
      onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
        const array = [];
        if(auth.currentUser){
          querySnapshot.forEach((doc) => {
            if(doc.id != auth.currentUser.uid){
              array.push({...doc.data(), id: doc.id});
            }
            // console.log(doc)
            
          });
        }
        // console.log(array)
        setNotifications(array)
        
      });
    // const docRef = doc(db, "user", auth.currentUser.uid);
    // const docSnap = await getDoc(docRef);
    
    // if (docSnap.exists()) {
    //   setUserData({...docSnap.data(), id: docSnap.id})
    //   requestPermission({userData2: docSnap.data(), docRef2: docRef})
    // }
    } catch {}
  }
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      setUser(user);
      // ...
    } else {
      setUser(false)
    }
  });
  useEffect(()=>{
    if (!userData){
      // setUser(auth)
      // setUser(auth)
      // requestPermission()
      getUser()
      
      
      
      
      // console.log('hellogeetUser')
    }
    
    
    

    // else{
    //   console.log(tickets)
    // }
  })
  useEffect(()=>{
    if(userData && !checkPermission){
      requestPermission({userData: userData, docRef2: doc(db, "user", auth.currentUser.uid)})
    }
    
  }, [userData])

  useEffect(()=>{
    if(!tickets && user){
      ticketSnap()
      // console.log('ticketSnap')
    }
    if(!skills && user){
      skillsSnap()
    }
    
  },[user])

  const skillsSnap = async () => {
    const q = query(collection(db, "skills"));
    const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
      const array = [];
      querySnapshot.forEach((doc) => {
        array.push({...doc.data(), id: doc.id});
        // console.log(doc)
        
      });
      // console.log(array)
      if(array.length>0 && array != tickets){
        // console.log('ticketArray',array[0].created)
        console.log(array)
        setSkills(array)
        // console.log(array)
      }
      
    });
    // const bundle = await fetch("/bundles/skills");
    // const bundle = await fetch("/bundles/skills");
    // await loadBundle(bundle);
    // console.log('testBundle2')
    
  }


  return (
    <>
    <NavBar dataMain={dataMain} auth={auth}  userData={userData} setUser={setUser} setDataMain={setDataMain} managers={managers}/>
    {/* {user && user.currentUser? */}
    {auth.currentUser?
      <>
      <Routes>
        <Route path="/" element={<HomePage user={user} users={users} managers={managers} tickets={tickets} buildings={buildings} floors={floors} dataMain={dataMain}/>} />
        <Route path="/map/:id?/:type?/:idType?" element={<Map tickets={tickets} user={user} users={users} managers={managers} buildings={buildings} floors={floors} dataMain={dataMain}/>} />
        <Route path="/profil/:id" element={<UserPage skills={skills} dataMain={dataMain} userData={userData} setUserData={setUserData}/>} />
        <Route path="/tickets/:managerId?/:buildingId?/:floorId?/:id?/:type?" element={<Tickets skills={skills} selectedType={selectedType} setSelectedType={setSelectedType} notifications={notifications} tickets={tickets} user={user} users={users} managers={managers} buildings={buildings} floors={floors} dataMain={dataMain}/>} />
        <Route path="/jobs/:managerId?/:buildingId?/:floorId?/:id?/:type?" element={<Jobs  skills={skills} selectedType={selectedType} setSelectedType={setSelectedType} notifications={notifications} tickets={tickets} user={user} users={users} managers={managers} buildings={buildings} floors={floors} dataMain={dataMain}/>} />
        <Route path="/login" element={<Login dataMain={dataMain}/>} />
        <Route path="/finishSignUp" element={<FinishSignUp setUser={setUser}/>} />
        <Route path="/messages" element={<Messages user={userData} notifications={notifications}/>} />
        <Route path="/GetIfc/:buildingId?" element={<GetIfc buildings={buildings}/>}/>
        {/* <Route path="/GetIfc2" element={<GetIfcThree/>}/> */}
      </Routes>
      <SafeNewImages userData={userData} floors={floors} buildings={buildings}/>
      <ToastAlert onMessage={onMessage} messaging={messaging} selectedType={selectedType}  users={users} user={userData}/>
      </>
      :
      <Routes>
        <Route path="/" element={<Login user={user} setUser={setUser}/>} />
        <Route path="/regist/:id?/:emailSendTO?/:displayName?" element={<Regist user={user} setUser={setUser}/>} />
        <Route  path="/passwordReset" element={<PasswordReset setUser={setUser}/>} />
        <Route  path="/finishSignUp" element={<FinishSignUp setUser={setUser}/>} />
      </Routes>
    }

    </>
  );
}

export default App;
