import React from "react";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { collection, doc, addDoc, serverTimestamp, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { db } from "../firebase";
import { divIcon, latLng } from 'leaflet';
import L from 'leaflet';
import { MapContainer, TileLayer,ImageOverlay, SVGOverlay, useMap, Marker, Popup, useMapEvents, LayersControl, LayerGroup, useMapEvent, Rectangle} from 'react-leaflet'
import { useEventHandlers } from '@react-leaflet/core'
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Chat from "../tools/Chat"
// import { Typeahead } from 'react-bootstrap-typeahead';
import Moment from 'react-moment';
import { TbUserPlus, TbUserX, TbBrandMessenger, TbBorderCorners } from "react-icons/tb";
import { OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab } from "react-bootstrap";


export function Floor({users, floor, tickets}){
    
    const [singleSelections, setSingleSelections] = useState([]);
    const [notContacts, setNotContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState(users);
    const [usersDefault, setUsersDefault] = useState([]);
    const [contactsDefault, setContactsDefault] = useState([]);
    const [contacts, setContacts] = useState(floor.contacts);
    const [inputText, setInputText] = useState("");
    const [showChat, setShowChat] = useState(false)
    const [chatId, setChatId] = useState(false)
    const [planUrl, setPlanUrl] = useState(false)
    
    const getImage =  () => {
        const indexddb =  window.indexedDB.open("ImageData");
        indexddb.onsuccess = async function (e) {
            console.log("success...");
            // const db12= e.target.result;
            const request = await e.target.result.transaction('friends')
                   .objectStore('friends')
                   .getAll();

            request.onsuccess = function(){
                console.log(request.result)
                if(request.result.find(el => el.name == floor.planUrl)){
                    const blob = new Blob( [ request.result.find(el => el.name == floor.planUrl).array ], {type: 'image/svg+xml'} );
                    const url = URL.createObjectURL( blob );
                    setPlanUrl(url)
                    console.log(request.result.find(el => el.name == floor.planUrl))
                    console.log(url)
                }
                else {
                    console.log('planFehlt')
                    setPlanUrl(floor.planUrl)
                }
            }

        };
        
    }
    
    const handleCloseChat = () => {
        setShowChat(false)
        setChatId(false)
    }
    const handleShowChat = (id) => {
        console.log(id)
        setChatId(id)
        setShowChat(true)
    }


    const addNewContact = (id) => {
        const docRef = doc(db, "floor", floor.id)
        updateDoc(docRef, {
            contacts: arrayUnion(id)
        })
        console.log(id)
    }
    const deleteNewContact = (id) => {
        const docRef = doc(db, "floor", floor.id)
        updateDoc(docRef, {
            contacts: arrayRemove(id)
        })
        console.log(id)
    }
    const searchContacts = (e) => {
        console.log('searchContacts')
        console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredContacts = contactsDefault.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.displayName.toLowerCase().includes(lowerCase)
            }
        })
        setFilteredContacts(filteredContacts)

    }
    const searchUsers = (e) => {
        console.log('searchContacts')
        console.log(e.target.value)
        var lowerCase = e.target.value.toLowerCase();
        const filteredUsers = usersDefault.filter((el)=>{
            if (lowerCase === ''){
                return el;
            }
            else {
                return el.displayName.toLowerCase().includes(lowerCase)
            }
        })
        setFilteredUsers(filteredUsers)

    }

    const setContactsFunc = () => {
        const notContactsArray = []
        const contactsArray = []
        users.forEach(user => {
            if(floor.contacts && floor.contacts.find(e => e == user.id) !== undefined){
                contactsArray.push(user)
            }
            else {
                notContactsArray.push(user)
            }
            
        }); 
        setUsersDefault(notContactsArray) 
        setFilteredUsers(notContactsArray)
        setContactsDefault(contactsArray)    
        setFilteredContacts(contactsArray)    
    }

    useEffect(()=>{
        setContactsFunc()
        getImage()
    }, [floor])
    useEffect(()=>{
        
        getImage()
    }, [])

    const calendarStrings = {
        lastDay : '[Gestern ] HH:MM',
        sameDay : '[Heute ] HH:mm',
        nextDay : '[Morgen um] LT',
        lastWeek : '[Letzten] dddd [um] HH:MM',
        nextWeek : 'dddd [um] LT',
        sameElse : 'L [um] LT'
      };

    const changeSize = () => {
        console.log('testMapSize')
    }

    return(
        <>
        <Form>
            <Row>
                <Col lg={'5'} className={'mb-3'}>
                    {
                        
                        floor.name?
                        <>
                            Name
                            <h5>{floor.name}</h5>
                        </>
                        :
                        <>
                            key
                            <h5>{floor.id}</h5>
                        </>
                    }
                </Col>
                <Col lg={'6'} className={'mb-3'}>
                    Tickets<br/><Link to={"tickets/none/"+floor.buildingId+'/'+floor.id}>{tickets.filter(ticket=>ticket.floorId==floor.id).length}</Link>
                </Col>
                <Col lg={'1'} className={'mb-3'}>
                    <Button variant="light" style={{paddingLeft: 5, paddingRight: 5, paddingBottom: 3, paddingTop: 1}} as={Link} to={'/map/'+floor.id}><TbBorderCorners size={22}/></Button>
                    {/* Key<br/>{floor.id} */}
                </Col>
                {/* <Col lg={'6'} className={'mb-3'}>
                    <Row>
                        <Col lg={'5'}>Erstellt am:</Col>
                        <Col lg={'7'}><Moment locale="de" calendar={calendarStrings}>{floor.created.toDate()}</Moment></Col>
                    </Row>
                </Col> */}
                <Col lg={'12'} className={'mb-3'}>
                    {/* <img style={{height: '350px', width: '100%'}} src={floor.planUrl}/> */}
                    {/* <div className="leaflet-containerInner"> */}
                        <MapContainer
                            center={{ lat: 61.505, lng: 275 }}
                            zoom={2.3}
                            scrollWheelZoom={true}
                            keyboard={false}
                            className="leaflet-containerInner"
                        >
                            <ImageOverlay
                                url={planUrl?planUrl:floor.planUrl}
                                // url={'https://firebasestorage.googleapis.com/v0/b/hexakt-92a98.appspot.com/o/floor%2F0.EG.gpkg?alt=media&token=53377c12-ce7b-423c-8cfb-12517b4c5253'}
                                
                                bounds={[[10,150], [10000,400]]}
                            />    
                        </MapContainer>
                    {/* </div> */}
                    {/* <Tabs
                    defaultActiveKey="contacts"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3"
                    >
                    <Tab eventKey="contacts" title="Kontakte">
                    
                        {floor.contacts?
                            <>
                                <Form.Label>Kontakte</Form.Label>
                                <Form.Control type="text" onChange={searchContacts} placeholder="Suchen..."></Form.Control>
                                <ListGroup className="mt-3">
                                    <div className="listMaxStyle">
                                        {
                                            filteredContacts.map((contact)=>
                                                <ListGroup.Item className="borderListMax" >
                                                    <Row>
                                                        <Col xs={8}>
                                                            <Link className={'linkStyleNone'} to={'/profil/'+contact.id}>
                                                                {contact.displayName}
                                                            </Link>
                                                        </Col>
                                                        <Col xs={4}>
                                                            <Button size="sm" variant="light" id={contact.id} onClick={()=>{handleShowChat(contact)}}>
                                                                <TbBrandMessenger/>
                                                                </Button>
                                                                {' '}
                                                            <Button size="sm" variant="danger" id={contact.id} onClick={()=>{deleteNewContact(contact.id)}}>
                                                                <TbUserX/>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        }
                                    </div>
                                </ListGroup>
                            </>
                            :
                            <Form.Text className="text-muted">
                                Bisher sind keine Kontakt angelegt
                            </Form.Text>
                        }
                    
                    </Tab>
                    <Tab eventKey="users" title="User">
                        <>
                            <Form.Label>Kontakte</Form.Label>
                            <Form.Control type="text" onChange={searchUsers} placeholder="Suchen..."></Form.Control>
                            <ListGroup className="mt-3">
                                <div className="listMaxStyle">
                                {
                                    filteredUsers.map((contact)=>
                                        <>
                                        <ListGroup.Item className="borderListMax" ><Row><Col xs={9}><Link className={'linkStyleNone'} to={'/profil/'+contact.id}>{contact.displayName}</Link></Col><Col xs={3}><Button size="sm" variant="light" id={contact.id} onClick={()=>{addNewContact(contact.id)}}><TbUserPlus/></Button></Col></Row></ListGroup.Item>
                                        
                                        </>
                                    )
                                }
                                </div>
                            </ListGroup>
                        </>
                    </Tab>
                    </Tabs> */}
                        
                </Col>
            </Row>
        </Form>
        <Chat show={showChat} chatPartner={chatId} handleClose={handleCloseChat}/>
        </>
    )
}