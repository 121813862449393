import React from "react";
import { useState, useEffect } from 'react';
import { db } from "../firebase";
import {ModalCreateManager} from "../modals/ModalCreateManager"
import {ModalCreateBuilding} from "../modals/ModalCreateBuilding"
import {ModalCreateFloor} from "../modals/ModalCreateFloor"
import {Manager} from "../details/Manager"
import {Building} from "../details/Building"
import {Floor} from "../details/Floor"
import { collection, addDoc } from "firebase/firestore";

import {RxPlusCircled} from "react-icons/rx";
import { Col,  Row, Container, Card, ListGroup } from "react-bootstrap";

export default function HomePage({user, users, managers, setManagers, buildings, floors, setBuildings, managerSnap, dataMain, tickets}) {

  const [focusManager, setFocusManager] = useState(false);
  const [focusBuilding, setFocusBuilding] = useState(false);
  const [focusFloor, setFocusFloor] = useState(false);
  const [showM, setShowM] = useState(false);
  const handleCloseM = () => {
    setShowM(false);
  }
  const handleShowM = () => {
    setFocusManager(false)
    setShowM(true);
  }
  const [showB, setShowB] = useState(false);
  const handleCloseB = () => {
    setShowB(false);
  }
  const handleShowB = () => {
    setFocusBuilding(false)
    setShowB(true);
  }
  const [showF, setShowF] = useState(false);
  const handleCloseF = () => {
    setShowF(false);
  }
  const handleShowF = () => {
    setFocusFloor(false)
    setShowF(true);
  }

  useEffect(()=>{
    console.log(focusManager)
    console.log(managers)
  },[focusManager])

  useEffect(()=>{
    if(focusManager){
      setFocusManager(managers.find((el)=> el.id === focusManager.id))
    }
    
  }, [managers])
  
  // const buildingSnap = async () => {

  //   // console.log(focusManager)
  //   const q = query(collection(db, "management/"+focusManager+'/building'));
  //   const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
  //     const managersrray = [];
  //     querySnapshot.forEach((doc) => {
  //       managersrray.push({...doc.data(), id: doc.id});
  //       // console.log(doc)
  //     });
  //     setManagers(managersrray)
      
  //   });

  // }

  // useEffect(()=>{
  //   if(focusManager){
  //     buildingSnap()
  //   }
  // }, [focusManager])
  const addUser = async () => {
    // const key = uid()
    // console.log(key)
    await addDoc(collection(db, "management"), {
      name: "Los Angeles",
      state: "CA",
      country: "USA"
    });
  }




  return (
    <>
      <Container className="mt-3">
        <Row>
          <Col lg={'6'} className="mb-3">
            {/* <h1>Hey from HomePagee</h1>
            <p>This is your awesome HomePage subtitle</p> */}
            <Row>
              <Col log={'4'}>
                <h4 className="mb-3">Manager</h4>
                <ListGroup defaultActiveKey={'#'+focusManager.id}>
              { managers?
                managers.map((manager, idx)=>
                  // manager.id === focusManager.id?
                  // <ListGroup.Item key={'manager'+idx} href={"#"+manager.id} action onClick={()=>setFocusManager(manager)}>
                  //   {manager.name}
                  // </ListGroup.Item>
                  // :
                  <ListGroup.Item key={'manager'+idx} href={"#"+manager.id} action onClick={()=>{setFocusManager(manager); setFocusBuilding(false); setFocusFloor(false)}}>
                    {manager.name}
                  </ListGroup.Item>
                )
                : 'Lege einen Manager an'
              }
              <ListGroup.Item key={'managerNew'} action onClick={handleShowM}>
                <RxPlusCircled/> Add New
              </ListGroup.Item>
              </ListGroup>
              
                  

              
              {/* <button onClick={addUser}>test</button>
              <button onClick={handleShow}>test2</button> */}
              </Col>
              <Col log={'4'} className="mb-3">
              { focusManager?
              <>
                <h4 className="mb-3">Gebäude</h4>
                <ListGroup defaultActiveKey={'#'+focusBuilding.id}>
                { buildings && buildings.length > 0?
                  buildings.filter(function(el){return el.managerId === focusManager.id}).map((building, idx)=>
                    // building.id === focusManager.id?
                    // <ListGroup.Item key={'building'+idx} href={"#"+building.id} action onClick={()=>setFocusBuilding({building})}>
                    //   {building.name}
                    // </ListGroup.Item>
                    // :
                    <ListGroup.Item key={'building'+idx} href={"#"+building.id} action onClick={()=>{setFocusFloor(false); setFocusBuilding(building)}}>
                      {building.name}
                    </ListGroup.Item>
                  )
                  : 'Lege ein Gebäude an'
                }
                
                <ListGroup.Item key={'buildingNew'} action onClick={handleShowB}>
                  <RxPlusCircled/> Add New
                </ListGroup.Item>
                
                
                </ListGroup>
                </>
              :
              null
            }
            </Col>
            <Col lg={'4'} className="mb-3">
              { focusBuilding?
              <>
                <h4 className="mb-3">Etagen</h4>
                <ListGroup defaultActiveKey={'#'+focusFloor.id}>
                { floors?
                  floors.filter(function(el){return el.buildingId === focusBuilding.id}).map((floor, idx)=>
                    // building.id === focusManager.id?
                    // <ListGroup.Item key={'building'+idx} href={"#"+building.id} action onClick={()=>setFocusBuilding({building})}>
                    //   {building.name}
                    // </ListGroup.Item>
                    // :
                    <ListGroup.Item key={'floor'+idx} href={"#"+floor.id} action onClick={()=>setFocusFloor(floor)}>
                      {floor.name}
                    </ListGroup.Item>
                  )
                  : 'Lege eine Etage an'
                }
                
                <ListGroup.Item key={'floorNew'} action onClick={handleShowF}>
                  <RxPlusCircled/> Add New
                </ListGroup.Item>
                
                
                </ListGroup>
              </>
              :
              null
            }
            </Col>
            </Row>
          </Col>
          
                {/* <h3> */}
                  {
                    focusManager && !focusBuilding && !focusFloor?
                    <Col lg={'6'} className="mb-5">
                      <Card>
                        <Card.Body>
                          <Manager buildings={buildings?buildings.filter(building=>building.managerId == focusManager.id):null} tickets={tickets} users={users} manager={focusManager}/>
                        </Card.Body>
                      </Card>
                    </Col>
                    :
                      null
                  }
                  {
                    focusBuilding && !focusFloor?
                    <Col lg={'6'} className="mb-5" >
                      <Card>
                        <Card.Body>
                          <Building floors={floors.filter(floor=>floor.buildingId == focusBuilding.id)} tickets={tickets} users={users} building={focusBuilding}/>
                        </Card.Body>
                      </Card>
                    </Col>
                    :
                      null
                  }
                  {
                    focusFloor?
                      <Col lg={'6'} className="mb-5" >
                        <Card>
                          <Card.Body>
                            <Floor tickets={tickets} users={users} floor={focusFloor}/>
                          </Card.Body>
                        </Card>
                      </Col>
                    :
                    null
                  }
                {/* </h3> */}
              
        </Row>
      </Container>
      <ModalCreateManager handleClose={handleCloseM} show={showM}/>
      <ModalCreateBuilding handleClose={handleCloseB} show={showB} managerId={focusManager.id}/>
      <ModalCreateFloor handleClose={handleCloseF} show={showF} managerId={focusManager.id} buildingId={focusBuilding.id}/>
    </>
  );
}