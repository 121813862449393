import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";

import { Link } from "react-router-dom";


const FinishSignUp  = () => {
    return(
        <div>
            <Container>
                <Row className=" mt-3 d-flex justify-content-center align-items-center">
                <Col md={8} lg={6} xs={12}>
                    {/* <div className="border border-3 border-primary"></div> */}
                    <Card className="shadow">
                    <Card.Body>
                        <div className="mb-3 mt-md-4">
                        <h2 className="fw-bold mb-2 text-uppercase ">Hexakt</h2>
                        <p className=" mb-5">Email Adresse wurde bestätigt</p>
                        <p className="small">
                            <Link to={'/'}>
                                Login
                            </Link>
                        
                        </p>
                        </div>
                    </Card.Body>
                    </Card>
                </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FinishSignUp