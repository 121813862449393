import React from 'react';
import { useState, useEffect } from 'react';
import { Route, Routes, Link } from "react-router-dom"
// We will create these two pages in a moment
import { messaging, auth, db  } from "./firebase";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { doc, getDoc, updateDoc, query, onSnapshot, collection, arrayUnion } from "firebase/firestore";
import { Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Badge } from "react-bootstrap";
import Moment from 'react-moment';
import Chat from "./tools/Chat"
export default function Messages({notifications, users}){

    const [showChat, setShowChat] = useState(false)
    const [chatId, setChatId] = useState(false)
    const handleCloseChat = () => {
        setShowChat(false)
        setChatId(false)
    }
    const handleShowChat = ({id}) => {
        console.log(id)
        setChatId(id)
        setShowChat(true)
    }

    const calendarStrings = {
        lastDay : '[Gestern ] HH:mm',
        sameDay : '[Heute ] HH:mm',
        nextDay : '[Morgen um] HH:mm',
        lastWeek : '[Letzten] dddd [um] HH:mm',
        nextWeek : 'dddd [um] HH:mm',
        sameElse : 'DD.MM.YY [um] HH:mm'
      };

    return(
        <>
        <Container>
            <Row>
                <Col lg={'4'} md={{ order: 2 }} xs={{ order: 2 }}>
                <h3>
                                Ticket Chats
                            </h3>
                    {
                        notifications?
                        
                        <Card className='mt-3'>
                            
                            <ListGroup defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}>
                                {
                                    notifications.map((ticket, idx)=>
                                    ticket.id.slice(0,6) == 'ticket'?
                                    <ListGroup.Item as={Link} key={'ticket_'+idx} to={'/tickets/none/none/none/'+ticket.id.slice(7)+'/chat'} action 
                                    // onClick={()=>selectTicket(ticket)}
                                    >
                                        <Row>
                                            <Col lg={9}>
                                                {ticket.name}<p style={{fontSize: '12px', marginBottom: 0}}><Moment locale="de" calendar={calendarStrings}>{ticket.updated.toDate()}</Moment></p>
                                            </Col>
                                            <Col lg={3}>
                                                <Badge pill>
                                                    {ticket.count}
                                                </Badge>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    :
                                    null
                                    )   
                                    
                                }
                            </ListGroup>
                        </Card>
                        :
                        <h4 className="mt-5">Bisher gibt es keine Mitteilungen für Tickets</h4>
                    }
                    
                </Col>
                <Col lg={'4'} md={{ order: 2 }} xs={{ order: 2 }}>
                <h3>
                    Ticket Update
                </h3>
                    {
                        notifications?
                        
                        <Card className='mt-3'>
                            
                            <ListGroup defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}>
                                {
                                    notifications.map((ticket, idx)=>
                                    ticket.id.slice(0,7) == 'tUpdate'?
                                    <ListGroup.Item as={Link} key={'tUpdate_'+idx} to={'/tickets/none/none/none/'+ticket.id.slice(8)} action 
                                    // onClick={()=>selectTicket(ticket)}
                                    >
                                        <Row>
                                            <Col lg={9}>
                                                {ticket.name}<p style={{fontSize: '12px', marginBottom: 0}}><Moment locale="de" calendar={calendarStrings}>{ticket.updated.toDate()}</Moment></p>
                                            </Col>
                                            <Col lg={3}>
                                                <Badge pill>
                                                    {ticket.count}
                                                </Badge>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    :
                                    null
                                    )   
                                    
                                }
                            </ListGroup>
                        </Card>
                        :
                        <h4 className="mt-5">Bisher gibt es keine Ticket Updates</h4>
                    }
                    
                </Col>
                <Col lg={'4'} md={{ order: 2 }} xs={{ order: 2 }}>
                <h3>
                                Direkt-Chats
                            </h3>
                    {
                        notifications?
                        
                        <Card className='mt-3'>
                            
                            <ListGroup  defaultActiveKey={''} style={{overflow: 'scroll', maxHeight: '80vh', border: 'none!important'}}  >
                                {
                                    notifications.map((chat, idx)=>
                                    chat.id.slice(0,4) == 'chat'?
                                    <ListGroup.Item key={'chat_'+idx}  
                                    // onClick={()=>selectchat(chat)}
                                    action
                                    onClick={()=>handleShowChat({id: {id: chat.id.slice(5), displayName: chat.name}})}
                                    >
                                        <Row>
                                            <Col lg={9}>
                                                {chat.name}<p style={{fontSize: '12px', marginBottom: 0}}><Moment locale="de" calendar={calendarStrings}>{chat.updated.toDate()}</Moment></p>
                                            </Col>
                                            <Col lg={3}>
                                                <Badge pill>
                                                    {chat.count}
                                                </Badge>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    :
                                    null
                                    )   
                                    
                                }
                            </ListGroup>
                        </Card>
                        :
                        <h4 className="mt-5">Bisher gibt es keine Direkt-Chats</h4>
                    }
                    
                </Col>
            </Row>
        </Container>
        <Chat show={showChat} chatPartner={chatId} handleClose={handleCloseChat}/>
        </>
    )
}

