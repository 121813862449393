import React from "react";
import { useState, useEffect, useRef } from 'react';
import { messaging, auth, db  } from "../firebase";
import {arrayUnion, increment, doc, addDoc, getDoc, updateDoc, query, onSnapshot, collection, getDocs, where, and, or, serverTimestamp, orderBy} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import Moment from 'react-moment';
import { Accordion, OverlayTrigger, Col, Button, Row, Container, Card, Form, InputGroup, Image, ListGroup, Tabs, Tab, Modal, Badge, Table } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead'; 
import 'react-bootstrap-typeahead/css/Typeahead.css';
import uuid from 'react-uuid';

export default function AnnouncementDetails({show, handleClose, ticket}){

    const [fristTable, setFristTable] = useState([])
    const [artTable, setArtTable] = useState([])
    const [umfangTable, setUmfangTable] = useState([])
    const [elseTable, setElseTable] = useState([])
    const [detailListItems, setDetailListItems] = useState(false)
    const [detailItems, setDetailItems] = useState([])
    const [idxx, setIdxx] = useState(false)
    const [showFrist, setShowFrist] = useState(false)
    const openShowFrist = () => setShowFrist(true)
    const closeShowFrist = () => setShowFrist(false)
    const [showArt, setShowArt] = useState(false)
    const openShowArt = () => setShowArt(true)
    const closeShowArt = () => setShowArt(false)
    const [showUmfang, setShowUmfang] = useState(false)
    const openShowUmfang = () => setShowUmfang(true)
    const closeShowUmfang = () => setShowUmfang(false)
    const [showElse, setShowElse] = useState(false)
    const openShowElse = () => setShowElse(true)
    const closeShowElse = () => setShowElse(false)

    useEffect(()=>{
        if(!detailListItems){
            getDetailListItems()
        }
    },[detailListItems])

    useEffect(()=>{
        if (ticket){
            setOldDetailItems()
        }
    }, [ticket])

    const setOldDetailItems = () => {
        if (ticket.annoucementDetails){
            if(ticket.annoucementDetails.filter((el)=> el.category == 'frist')){
                setFristTable(ticket.annoucementDetails.filter((el)=> el.category == 'frist'))
            }
            if(ticket.annoucementDetails.filter((el)=> el.category == 'art')){
                setArtTable(ticket.annoucementDetails.filter((el)=> el.category == 'art'))
            }
            if(ticket.annoucementDetails.filter((el)=> el.category == 'umfang')){
                setUmfangTable(ticket.annoucementDetails.filter((el)=> el.category == 'umfang'))
            }
            if(ticket.annoucementDetails.filter((el)=> el.category == 'else')){
                setElseTable(ticket.annoucementDetails.filter((el)=> el.category == 'else'))
            }
        }
    }

    const getDetailListItems = async () => {
        const q = query(collection(db, "announcementDetails"), or(where('active', '==', true), where('userId', '==', auth.currentUser.uid)));
        const unsubscribe = onSnapshot(q, { includeMetadataChanges: true }, (querySnapshot) => {
        const announcementDetails = [];
        querySnapshot.forEach((doc) => {
            // if()
            announcementDetails.push({...doc.data(), id: doc.id});
            // console.log(doc)
            
        });
        // console.log(announcementDetails)
        setDetailListItems(announcementDetails)

        });
    }

    const changeFirstTable = ({idx, type}) => {
        console.log(idx)
        console.log(type)
        if(type){
            const tableItem = fristTable
            tableItem[idx]['name'] = type[0].name
            tableItem[idx]['type'] = type[0].type
            tableItem[idx]['category'] = 'frist'
            console.log(tableItem)
            setFristTable([...tableItem])
        }
    }
    const changeFirstTableValue = ({idx, value, file}) => {
        console.log(idx)
        console.log(value)
        if(value){
            const tableItem = fristTable
            tableItem[idx]['value'] = value
            if(file){
                const storage = getStorage();
                const id = 'announcementDetail/'+uuid()
                const image = ref(storage, id)
                uploadBytes(image, file)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then(async(url) => {
                        tableItem[idx]['value'] = url
                        tableItem[idx]['fileId'] = id
                    })
                })
                

            }
            else{
                tableItem[idx]['value'] = value
            }
            setFristTable([...tableItem])
        }
    }
    const changeArtTable = ({idx, type}) => {
        console.log(idx)
        console.log(type)
        if(type){
            const tableItem = artTable
            tableItem[idx]['name'] = type[0].name
            tableItem[idx]['type'] = type[0].type
            tableItem[idx]['category'] = 'art'
            console.log(tableItem)
            setArtTable([...tableItem])
        }
    }
    const changeArtTableValue = ({idx, value, file}) => {
        console.log(idx)
        console.log(value)
        if(value){
            const tableItem = artTable
            
            if(file){
                const storage = getStorage();
                const id = 'announcementDetail/'+uuid()
                const image = ref(storage, id)
                uploadBytes(image, file)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then(async(url) => {
                        tableItem[idx]['value'] = url
                        tableItem[idx]['fileId'] = id
                    })
                })
                

            }
            else{
                tableItem[idx]['value'] = value
            }
            setArtTable([...tableItem])
        }
    }
    const changeUmfangTable = ({idx, type}) => {
        console.log(idx)
        console.log(type)
        if(type){
            const tableItem = umfangTable
            tableItem[idx]['name'] = type[0].name
            tableItem[idx]['type'] = type[0].type
            tableItem[idx]['category'] = 'umfang'
            console.log(tableItem)
            setUmfangTable([...tableItem])
        }
    }
    const changeUmfangTableValue = ({idx, value, file}) => {
        console.log(idx)
        console.log(value)
        if(value){
            const tableItem = umfangTable
            tableItem[idx]['value'] = value
            if(file){
                const storage = getStorage();
                const id = 'announcementDetail/'+uuid()
                const image = ref(storage, id)
                uploadBytes(image, file)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then(async(url) => {
                        tableItem[idx]['value'] = url
                        tableItem[idx]['fileId'] = id
                    })
                })
                

            }
            else{
                tableItem[idx]['value'] = value
            }
            setUmfangTable([...tableItem])
        }
    }
    const changeElseTable = ({idx, type}) => {
        console.log(idx)
        console.log(type)
        if(type){
            const tableItem = elseTable
            tableItem[idx]['name'] = type[0].name
            tableItem[idx]['type'] = type[0].type
            tableItem[idx]['category'] = 'else'

            console.log(tableItem)
            setElseTable([...tableItem])
        }
    }
    const changeElseTableValue = ({idx, value, file}) => {
        console.log(idx)
        console.log(value)
        if(value){
            const tableItem = elseTable
            tableItem[idx]['value'] = value
            if(file){
                const storage = getStorage();
                const id = 'announcementDetail/'+uuid()
                const image = ref(storage, id)
                uploadBytes(image, file)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                    .then(async(url) => {
                        tableItem[idx]['value'] = url
                        tableItem[idx]['fileId'] = id
                    })
                })
                

            }
            else{
                tableItem[idx]['value'] = value
            }
            setElseTable([...tableItem])
        }
    }
    const saveJobDetails = async() => {
        const data = [...fristTable, ...artTable, ...umfangTable, ...elseTable]
        console.log(data.filter((el)=> el.value != ''))
        updateDoc(doc(db, 'ticket', ticket.id), {annoucementDetails: data.filter((el)=> el.value != '')})
        handleClose()
    }
    const stopAnnouncement = async()=>{
        updateDoc(doc(db, 'ticket', ticket.id), {announcement: false})
        handleClose()
    }
    const deleteDocument = ({fileId, idx, data, setData}) => {
        deleteObject(ref(getStorage(), fileId))
        data[idx]['value'] = false
        setData([...data])
    }

    return (
        <Modal show={show}  onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
            <Modal.Title>Auftragsdetails</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{padding: 0}}>
                <Accordion>
                    <Accordion.Item className="borderRadius0" id='frist' eventKey='frist'>
                        <Accordion.Header>
                            Fristen<Badge style={{marginLeft: 10}} >{fristTable.length}</Badge>
                        </Accordion.Header>
                        <Accordion.Body style={{padding: 20}}>
                            {fristTable.length > 0?
                                        fristTable.map((el, idx)=>
                                            <Row className="mb-1">
                                                <Col lg={6}>
                                                {el.name?
                                                    el.name
                                                    :
                                                    <Typeahead
    
                                                        size="sm"
                                                        labelKey="name"
                                                        
                                                        id={"type"+idx}
                                                        onChange={(e)=>{
                                                            changeFirstTable({idx: idx, type: e})
                                                            console.log(e)  
                                                            console.log(fristTable[idx])  
                                                        }}
                                                        options={detailListItems.filter((el)=> el.category == 'frist')}
                                                        placeholder="Choose a detail..."
                                                    />
                                                }

                                                </Col>
                                                <Col lg={6}>
                                                    {el.type?
                                                        el.value?
                                                        el.type != 'file'?
                                                            <Form.Control as={el.type == 'textarea'?'textarea': 'input'} defaultValue={el.value} type={el.type} size="sm"
                                                            onChange={
                                                                (e)=>{
                                                                    changeFirstTableValue({idx: idx, value: e.target.value})
                                                                }
                                                            }
                                                            />
                                                            :
                                                            <><a href={el.value}>Dokument</a> <a href='#' onClick={(e)=>{
                                                                deleteDocument({fileId: el.fileId, idx: idx, data: fristTable, setData: setFristTable})
                                                            }} style={{textDecoration: 'none'}}>x</a></>
                                                            :
                                                            <Form.Control as={el.type == 'textarea'?'textarea': 'input'} type={el.type} size="sm"
                                                            onChange={
                                                                (e)=>{
                                                                    changeFirstTableValue({idx: idx, value: e.target.value})
                                                                }
                                                            }
                                                            />
                                                        
                                                        :
                                                        <Button size="sm" onClick={openShowFrist} >Neues Detail</Button>

                                                    }
                                                </Col>
                                            </Row>
                                        )
                                :null
                            }
                            {
                                showFrist?
                                <AddDetail category={'frist'}  handleClose={closeShowFrist}/>
                                :
                                <Button size='sm' variant="outline-secondary" onClick={(e)=>{
                                    setFristTable([...fristTable, {name: '', value: '', type: false}])
                                }}
                                >Detail erstellen</Button>
                            }
                            
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="borderRadius0" id='art' eventKey='art'>
                        <Accordion.Header>
                            Auftragsart <Badge style={{marginLeft: 10}} >{artTable.length}</Badge>
                        </Accordion.Header>
                        <Accordion.Body style={{padding: 20}}>
                            {artTable.length > 0?
                                        artTable.map((el, idx)=>
                                            <Row className="mb-1">
                                                <Col lg={6}>
                                                {el.name?
                                                    el.name
                                                    :
                                                    <Typeahead
    
                                                        size="sm"
                                                        labelKey="name"
                                                        
                                                        id={"type"+idx}
                                                        onChange={(e)=>{
                                                            changeArtTable({idx: idx, type: e})
                                                            console.log(e)  
                                                            console.log(artTable[idx])  
                                                        }}
                                                        options={detailListItems.filter((el)=> el.category == 'art')}
                                                        placeholder="Choose a detail..."
                                                    />
                                                }

                                                </Col>
                                                <Col lg={6}>
                                                    {el.type?
                                                        el.value?
                                                        el.type != 'file'?
                                                        
                                                        <Form.Control as={el.type == 'textarea'?'textarea': 'input'} defaultValue={el.value} type={el.type} size="sm"
                                                        onChange={
                                                            (e)=>{
                                                                changeArtTableValue({idx: idx, value: e.target.value})
                                                            }
                                                        }
                                                        />
                                                        :
                                                        <><a href={el.value}>Dokument</a> <a href='#' onClick={(e)=>{
                                                            deleteDocument({fileId: el.fileId, idx: idx, data: artTable, setData: setArtTable})
                                                        }} style={{textDecoration: 'none'}}>x</a></>
                                                        :
                                                        <Form.Control as={el.type == 'textarea'?'textarea': 'input'} type={el.type} size="sm"
                                                        onChange={
                                                            (e)=>{
                                                                if(el.type == 'file'){
                                                                    changeArtTableValue({idx: idx, value: e.target.value, file: e.target.files[0]})
                                                                }
                                                                else {
                                                                    changeArtTableValue({idx: idx, value: e.target.value})
                                                                }
                                                            }
                                                        }
                                                        />

                                                        :
                                                        <Button size="sm" onClick={openShowArt} >Neues Detail</Button>

                                                    }
                                                </Col>
                                            </Row>
                                        )
                                :null
                            }
                            {
                                showArt?
                                <AddDetail category={'art'}  handleClose={closeShowArt}/>
                                :
                                <Button size='sm' variant="outline-secondary" onClick={(e)=>{
                                    setArtTable([...artTable, {name: '', value: '', type: false}])
                                }}
                                >Detail erstellen</Button>
                            }
                            
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="borderRadius0" id='umfang' eventKey='umfang'>
                        <Accordion.Header>
                            Umfang<Badge style={{marginLeft: 10}} >{umfangTable.length}</Badge>
                        </Accordion.Header>
                        <Accordion.Body style={{padding: 20}}>
                            {umfangTable.length > 0?
                                        umfangTable.map((el, idx)=>
                                            <Row className="mb-1">
                                                <Col lg={6}>
                                                {el.name?
                                                    el.name
                                                    :
                                                    <Typeahead
    
                                                        size="sm"
                                                        labelKey="name"
                                                        
                                                        id={"type"+idx}
                                                        onChange={(e)=>{
                                                            changeUmfangTable({idx: idx, type: e})
                                                            console.log(e)  
                                                            console.log(umfangTable[idx])  
                                                        }}
                                                        options={detailListItems.filter((el)=> el.category == 'umfang')}
                                                        placeholder="Choose a detail..."
                                                    />
                                                }

                                                </Col>
                                                <Col lg={6}>
                                                    {el.type?
                                                        el.value?
                                                        el.type != 'file'?
                                                        <Form.Control as={el.type == 'textarea'?'textarea': 'input'} defaultValue={el.value} type={el.type} size="sm"
                                                        onChange={
                                                            (e)=>{
                                                                changeUmfangTableValue({idx: idx, value: e.target.value})
                                                            }
                                                        }
                                                        />
                                                        :
                                                        <><a href={el.value}>Dokument</a> <a href='#' onClick={(e)=>{
                                                            deleteDocument({fileId: el.fileId, idx: idx, data: umfangTable, setData: setUmfangTable})
                                                        }} style={{textDecoration: 'none'}}>x</a></>
                                                        :
                                                        <Form.Control as={el.type == 'textarea'?'textarea': 'input'} type={el.type} size="sm"
                                                        onChange={
                                                            (e)=>{
                                                                changeUmfangTableValue({idx: idx, value: e.target.value})
                                                            }
                                                        }
                                                        />
                                                        :
                                                        <Button size="sm" onClick={openShowUmfang} >Neues Detail</Button>

                                                    }
                                                </Col>
                                            </Row>
                                        )
                                :null
                            }
                            {
                                showUmfang?
                                <AddDetail category={'umfang'}  handleClose={closeShowUmfang}/>
                                :
                                <Button size='sm' variant="outline-secondary" onClick={(e)=>{
                                    setUmfangTable([...umfangTable, {name: '', value: '', type: false}])
                                }}
                                >Detail erstellen</Button>
                            }
                            
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="borderRadius0" id='else' eventKey='else'>
                        <Accordion.Header>
                            Sonstige Details <Badge style={{marginLeft: 10}} >{elseTable.length}</Badge>
                        </Accordion.Header>
                        <Accordion.Body style={{padding: 20}}>
                            {elseTable.length > 0?
                                        elseTable.map((el, idx)=>
                                            <Row className="mb-1">
                                                <Col lg={6}>
                                                {el.name?
                                                    el.name
                                                    :
                                                    <Typeahead
    
                                                        size="sm"
                                                        labelKey="name"
                                                        
                                                        id={"type"+idx}
                                                        onChange={(e)=>{
                                                            changeElseTable({idx: idx, type: e})
                                                            console.log(e)  
                                                            console.log(elseTable[idx])  
                                                        }}
                                                        options={detailListItems.filter((el)=> el.category == 'else')}
                                                        placeholder="Choose a detail..."
                                                    />
                                                }

                                                </Col>
                                                <Col lg={6}>
                                                    {el.type?
                                                        el.value?
                                                        el.type != 'file'?
                                                        <Form.Control as={el.type == 'textarea'?'textarea': 'input'} defaultValue={el.value} type={el.type} size="sm"
                                                        onChange={
                                                            (e)=>{
                                                                changeElseTableValue({idx: idx, value: e.target.value})
                                                            }
                                                        }
                                                        />
                                                        :
                                                        <><a href={el.value}>Dokument</a> <a href='#' onClick={(e)=>{
                                                            deleteDocument({fileId: el.fileId, idx: idx, data: elseTable, setData: setElseTable})
                                                        }} style={{textDecoration: 'none'}}>x</a></>
                                                        :
                                                        <Form.Control as={el.type == 'textarea'?'textarea': 'input'} type={el.type} size="sm"
                                                        onChange={
                                                            (e)=>{
                                                                changeElseTableValue({idx: idx, value: e.target.value})
                                                            }
                                                        }
                                                        />
                                                        :
                                                        <Button size="sm" onClick={openShowElse} >Neues Detail</Button>

                                                    }
                                                </Col>
                                            </Row>
                                        )
                                :null
                            }
                            {
                                showElse?
                                <AddDetail category={'else'}  handleClose={closeShowElse}/>
                                :
                                <Button size='sm' variant="outline-secondary" onClick={(e)=>{
                                    setElseTable([...elseTable, {name: '', value: '', type: false}])
                                }}
                                >Detail erstellen</Button>
                            }
                            
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <Button size='sm' onClick={saveJobDetails} style={{margin: 5}}>Sichern</Button>
                <Button size='sm' variant="danger" onClick={stopAnnouncement} style={{margin: 5}}>Ausschreibung beenden</Button>
                {/* Hallo */}

            </Modal.Body>
            
        </Modal>
        )
}

function AddDetail({handleClose, category}){
    const [name, setName] = useState(false)
    const [type, setType] = useState(false)

    const createNewDetail = async () => {
        if (name && type){
            await addDoc(collection(db, "announcementDetails"), {
                name: name, 
                type: type,
                userId: auth.currentUser.uid,
                category: category
            })
            handleClose()
        }
    }

    return(
        <Card>
            <Card.Body>
                <Row>
                    <Col lg='6'>
                        <Form.Control size="sm" placeholder="Bezeichnung" onChange={(e)=>{
                            if(e.target.value != ''){
                                setName(e.target.value)
                            }
                            else {
                                setName(false)
                            }
                        }}/>
                    </Col>
                    <Col lg='6'>
                        <Form.Select size="sm" onChange={(e)=>{
                            setType(e.target.value)
                        }}>
                            <option value="" disabled selected>Typ Auswählen</option>
                            <option value='number'>Zahl</option>
                            <option value='text'>Text</option>
                            <option value='textarea'>Fließtext</option>
                            <option value='date'>Datum</option>
                            <option value='datetime-local'>Datum mit Uhrzeit</option>
                            <option value='file'>Dokument</option>
                        </Form.Select>
                    </Col>
                    
                </Row>
                {
                    name && type?
                    <Button className="mt-1" size='sm' variant="primary" onClick={createNewDetail}
                    >
                        Detail Speichern
                    </Button>
                    :
                    null
                }
            </Card.Body>
        </Card>
        
    )
}